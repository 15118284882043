import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';

// Define custom styles
const useStyles = makeStyles({
  success: {
    backgroundColor: 'green',
    color: 'white',
  },
  error: {
    backgroundColor: '#b3261e',
    color: 'white',
  },
  info: {
    backgroundColor: 'blue',
    color: 'white',
  },
  warning: {
    backgroundColor: 'orange',
    color: 'white',
  },
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CommonSnackbar({ open, onClose, message, type = 'info' }) {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      message={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={SlideTransition}
      // autoHideDuration={5000}
      ContentProps={{
        className: classes[type], // Dynamically apply the class based on `type`
      }}
      key="slide-snackbar"
    />
  );
}
