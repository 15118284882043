import React from "react";
import { Navigate } from "react-router-dom";

const WithoutPrivateRoute = ({ children }) => {
  let token = localStorage.getItem("token");
  // Check if a token is available
  // If there's no user or user role doesn't match allowed roles, redirect to login
  if (token) {
    return <Navigate to="/" />;
  }

  // If allowed role is found, render the Outlet (child routes)
  return children;
};

export default WithoutPrivateRoute;