import React, { createContext, useContext, useState, useEffect } from "react";
import { getUserNameAndId } from "../services/dashboard-services";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    () => localStorage.getItem("token") || null
  );
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([])

  const decodeJWT = (token) => {
    if (!token) {
      throw new Error("Token is required");
    }
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }
    const payload = parts[1];
    const decodedPayload = atob(payload.replace(/-/g, "+").replace(/_/g, "/"));
    return JSON.parse(decodedPayload);
  };

  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
    console.log("useeffectCalling");
    if (token) {
      console.log("useeffectCalling inside token");
      try {
        console.log("setting token in context and decoding it");

        const decoded = decodeJWT(token);
        console.log("decoded token = ", decoded);

        setDecodedToken(decoded);
        setRole(decoded?.user.role);
        // localStorage.setItem("user", JSON.stringify(decoded.user));
        setUser(decoded);

        const response = await getUserNameAndId()
        setUsers(response.data.data)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Failed to decode token:", error);
      }
    } else {
      console.log("useeffectCalling else");
      // Clear user if token is null
      setUser(null);
      setLoading(false);
    }
    }
    initializeAuth()
  }, [token]);

  const login = (userToken, user) => {
    setToken(userToken);
    localStorage.setItem("token", userToken);
  };

  const logout = () => {
    setLoading(true);
    setToken(null);
    setRole(null);
    localStorage.removeItem("token");
    setDecodedToken(null);
    setUser(null);
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ token, role, user, loading, login, logout, users }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);