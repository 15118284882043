import axios from "axios";

export async function getLeadDocumentsByLeadId(params){
    console.log('params for api lead docs = ',params);
    
    return axios.get('/api/lead-documents/get-lead-documents-by-lead-id',{params:params})
}

export async function deleteLeadDocument(payload){
    return axios.post('/api/lead-documents/remove-lead-document-by-lead-id', {...payload})
}

export async function uploadFile(formData, ){
    return axios.post('/api/uploads/upload-file', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}