import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Table, Modal } from "react-bootstrap"; // Import Modal
import axios from "./../../components/axios";
import "./../../App.css";
import DatePicker from "react-datepicker";
import ModalComponent from "./../../components/Modal";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import { getLeadsByAssignedUserId } from "../../services/employee/leads-services";
import { debounce, getUserDetails } from "../../utilities/helper-functions";
import TablePagination from "@mui/material/TablePagination";
import CallIcon from "@mui/icons-material/Call";
import AddActivityDialogue from "../../components/employee/leads/AddActivityDialogue";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import { useAuth } from "../../context/AuthContext";
import moment from "moment";
import { activityStatuses } from "../../constants/AppConstants";

const LeadList = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation()
  const [leads, setLeads] = useState([]);
  const [employees, setEmployees] = useState([]);
  // const [user, setUser] = useState({});
  const [pagination, setPagination] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [filters, setFilters] = useState({
    exclude_verification: true,
  });
  const [page, setPage] = useState(0);
  const [showActivityDialogue, setShowActivityDialogue] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  const [progress, setProgress] = useState(0);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  useEffect(() => {
    // let user = getUserDetails();
    // if (user) {
    // setUser(user);
    let areFiltersExists = location.state && Object.keys(location?.state).includes('filters')
    let arePaginationExists = location.state && Object.keys(location?.state).includes('pagination')
    if (user){
      if(areFiltersExists || arePaginationExists){
        if(location.state.filters){
          setFilters(location.state.filters);
        }
        if (location.state.pagination) {
          setPagination((prevPagination) => ({
            ...prevPagination,
            ...location.state.pagination,
            page: location.state.pagination.page + 1, // Increment page
          }));
        }
        fetchLeadsByAssignedUserId({ userId: user.user.id, ...location.state.filters, ...location.state.pagination, page: location.state.pagination.page + 1 }, true);
        navigate(location.pathname, { replace: true });
      }else{
        fetchLeadsByAssignedUserId({ userId: user.user.id, ...filters }, false);
      }
    }
    // }
  }, [user]);

  useEffect(() => {
    console.log("leads = ", leads);
  }, [leads]);

  async function fetchLeadsByAssignedUserId(params, withFilters) {
    console.log("params = ", params);

    try {
      setResponseType("");
      if (withFilters) {
        setBackdropMessage("Applying Filters...");
      } else {
        setBackdropMessage("Fetching Leads...");
      }
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
      const response = await getLeadsByAssignedUserId(params);
      setLeads(response.data.data);
      setPagination({
        ...response.data.pagination,
        page: response.data.pagination.page - 1,
      });
      setRowsPerPage(response.data.pagination.limit);
      setResponseType("SUCCESS");
      setBackdropMessage(response.data.message);
      setShouldBackdropCloseOnClickOfOutside(true);
      setOpenBackdrop(false);
    } catch (error) {
      console.log(error.message);
      setResponseType("FAILED");
      setBackdropMessage("Failed to fetch leads !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  function handleChangePage(event, newPage) {
    console.log("newPage = ", newPage);
    setPage(newPage);
    fetchLeadsByAssignedUserId(
      {
        userId: user.user.id,
        page: newPage + 1,
        limit: rowsPerPage,
        ...filters,
      },
      false
    );
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    // Fetch data for the first page with new rows per page
    fetchLeadsByAssignedUserId({
      userId: user.user.id,
      page: 1,
      limit: newRowsPerPage,
      ...filters,
    });
  };

  function handleFilterChange(e) {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    // setFilters((prevFilters)=>({...prevFilters, [e.target.name]:e.target.value}))
    console.log(filters);
    // Fetch leads with updated filters
    debouncedFetchLeads({
      userId: user.user.id,
      ...updatedFilters,
      page: 1,
      limit: rowsPerPage,
    });
  }

  const debouncedFetchLeads = useCallback(
    debounce((params) => fetchLeadsByAssignedUserId(params, true), 700),
    []
  );

  function openActivityDialogueHandler(e, lead) {
    e.stopPropagation();
    setSelectedLead(lead);
    setShowActivityDialogue(true);
  }

  function updateLeadStatus(id, newStatus) {
    try {
      setLeads((prevLeads) =>
        prevLeads
          .map((lead) => {
            if (lead.id === id) {
              let updatedActivities;

              if (lead.activities && lead.activities.length > 0) {
                // If activities exist, update the status of the first activity
                updatedActivities = lead.activities.map((activity, index) =>
                  index === 0
                    ? { ...activity, activity_status: newStatus }
                    : activity
                );
              } else {
                // If activities array is empty, create the first activity
                updatedActivities = [
                  {
                    activity_status: newStatus,
                    description: "",
                    docs_collected: false,
                    follow_up: null,
                    lead_status: null,
                    status: null,
                  },
                ];
              }

              return {
                ...lead,
                activities: updatedActivities,
                leadStatus: newStatus,
                updatedAt: new Date()
              };
            }
            return lead;
          })
          .filter(
            (lead) =>
              !(
                lead.activities &&
                lead.activities[0]?.activity_status === "Verification 1"
              )
          )
      );
    } catch (error) {
      alert("Failed to update lead status: " + error.message);
    }
  }

  function resetFiltersHandler(){
    let newFilters = {
      exclude_verification: true,
    }
    setFilters(newFilters)
    debouncedFetchLeads({
      userId: user.user.id,
      ...newFilters,
      page: 1,
      limit: rowsPerPage,
    });
  }

  return (
    <>
      <EmployeeSidebar />
      <div className="global-container">
        <div className="container">
          <h1 className="text-left">{employees?.name}</h1>
          <div>
            <div className="row">
              {/* filter by name */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Filter by Name"
                  value={filters.name || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by assigned date */}
              <div className="col-md-3" style={{marginTop:'-1.5rem'}}>
                <label htmlFor="date" style={{ fontSize: "small", fontWeight: "bold" }}>Assinged Date</label>
                <input
                  type="date"
                  name="date"
                  className="form-control"
                  placeholder="Filter by Imported Date"
                  value={filters.date || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by assigned date */}
              <div className="col-md-3" style={{marginTop:'-1.5rem'}}>
                <label htmlFor="date" style={{ fontSize: "small", fontWeight: "bold" }}>Last Updated</label>
                <input
                  type="date"
                  name="last_updated"
                  className="form-control"
                  placeholder="Filter by Imported Date"
                  value={filters.last_updated || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by phone */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Filter by Phone"
                  value={filters.phone || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              <p>&nbsp;</p>

              {/* filter by status ( lead status ) */}
              <div className="col-md-3">
                <Form.Control
                  as="select"
                  name="leadStatus"
                  className="form-control"
                  value={filters?.leadStatus || ""}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Filter by Status</option>
                  {activityStatuses.map((activity_status) => (
                    <option
                      value={activity_status.name}
                      id={activity_status.id}
                    >
                      {activity_status.name}
                    </option>
                  ))}
                </Form.Control>
              </div>

              {/* filter by lead source */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="leadSource"
                  className="form-control"
                  placeholder="Filter by Lead Source"
                  value={filters.leadSource}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by docs_collected */}
              <div className="col-md-3">
                <Form.Control
                  as="select"
                  name="docsCollected"
                  className="form-control"
                  value={filters.docsCollected || ""}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Filter by Docs Collected</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Form.Control>
              </div>

              {/* reset filters button */}
              <div className="col-md-3">
                  <Button style={{backgroundColor:'red', color:'white'}} onClick={()=>resetFiltersHandler()}>Reset Filters</Button>
              </div>
            </div>
          </div>
          <br />

          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th>Lead Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  {/* <th>Imported Date</th> */}
                  <th>Assigned Date</th>
                  <th>Lead Source</th>
                  <th>Status</th>
                  <th>Docs. Collected</th>
                  <th>Last Updated</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr
                    key={lead.id}
                    onDoubleClick={() =>
                      navigate(`/lead-details-emp/${lead.id}`,{state:{filters, pagination, fromPage:'emp-leads'}})
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>{lead.id || "NA"}</td>
                    <td>{lead.name || "NA"}</td>
                    <td>{lead.email || "NA"}</td>
                    <td>{lead.phone || "NA"}</td>
                    {/* assigned date column */}
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.assignedAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.assignedAt)
                          .utcOffset(330)
                          .format("hh:mm:ss A")}
                      </span>
                    </td>
                    {/* <td>{lead.assignedDate||"NA"}</td> */}
                    <td>{lead.leadSource || "Not Available"}</td>
                    <td>{lead?.leadStatus || "Not Contacted"}</td>

                    <td>
                      {lead.activities?.[0]?.docsCollected === true
                        ? "YES"
                        : "NO"}
                    </td>
                    {/* last updated column */}
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.updatedAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.updatedAt)
                          .utcOffset(330)
                          .format("hh:mm:ss A")}
                      </span>
                    </td>
                    <td style={{scale:'0.4'}}>
                      <CallIcon
                        fontSize="medium"
                        color="success"
                        style={{
                          cursor: "pointer",
                          border: "1px solid gray",
                          borderRadius: "50%",
                          padding: "0.2rem",
                          height:'fit-content',
                          width:'fit-content'
                        }}
                        onClick={(e) => openActivityDialogueHandler(e, lead)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={pagination.total || 0}
            page={pagination.page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        {showActivityDialogue && (
          <AddActivityDialogue
            open={showActivityDialogue}
            handleClose={() => setShowActivityDialogue(false)}
            lead={selectedLead}
            updateLeadStatus={(id, newStatus) =>
              updateLeadStatus(id, newStatus)
            }
          />
        )}
      </div>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
    </>
  );
};

export default LeadList;
