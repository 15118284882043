import { Grid2, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";

const useStyles = makeStyles((theme) => ({}));

function LeadListMUI() {
  const [currentRecords,setCurrentRecords] = useState([])
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedLeadIds, setSelectedLeadIds] = useState([])

  useEffect(() => {
    fetchLeads();
    fetchEmployees();
  }, []);

  useEffect(()=>{
    console.log('selected ids = ',selectedLeadIds);
    
  },[selectedLeadIds])

  const fetchLeads = async () => {
    try {
      const response = await axios.get("/lead");
      if (response.status === 200) {
        console.log(
          response.data.leads,
          "response.data.leadsresponse.data.leads"
        );
        setLeads(
          response.data.leads.map((lead) => ({
            ...lead,
            assignedTo: lead.assignedTo || "Not Assigned",
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching leads:", error.message);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get("/user");
      setEmployees(response.data);
    } catch (err) {
      setError("Failed to fetch employees.");
      console.error(err);
    }
  };

  const handleCheckboxChange = (id) => {
    let updatedIds;
    if (selectedLeadIds.includes(id)) {
      updatedIds = selectedLeadIds.filter((leadId) => leadId !== id);
    } else {
      updatedIds = [...selectedLeadIds, id];
    }
    setSelectedLeadIds(updatedIds);
  };

  async function assignLeadsToEmployee(){
    let payload = {}
    payload.leadIds = selectedLeadIds
    payload.assignedToUserId = 5
    payload.assignedBy = 1
    console.log('payload = ',payload);
    try {
      const response = await axios.post("/leadAssignment/assign", {
        ...payload
      })
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          selectedLeadIds.includes(lead.id)
            ? { ...lead, assignedTo: response.data.assignedTo.name }
            : lead
        )
      );
      setSelectedLeadIds([]);
    } catch (error) {
      
    }
  }

  function getAssigneeEmployeeName(employees, LeadAssignment) {
    // Assuming LeadAssignment contains a `assignedToUserId`
    let employee = employees.find(
      (employee) => employee.id === LeadAssignment.assignedToUserId
    );
    return employee ? employee.name : "Not Assigned"; // Return employee's name or default message
  }
  

  return (
    // root container
    <Grid2 style={{display:'flex', height:'100vh', backgroundColor:'pink',}}>
        <Grid2 style={{width:'12%'}}>
        <AdminSidebar/>
        </Grid2>
        {/* content container */}
        <Grid2 style={{height:'100vh', backgroundColor:'blue', width:'88%', paddingTop:'40px'}}>
            {/* main container */}
            <Grid2 style={{height:'100%', width:'100%', backgroundColor:'white', display:'flex', flexDirection:'column'}}>
              <Box><Button onClick={()=>assignLeadsToEmployee()}>Assgin to Rushikesh Goli</Button></Box>
            <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      // checked={selectAll}
                      // onChange={handleSelectAll}
                    />
                  </th>
                  <th>Lead Id.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Gender</th>
                  <th>Status</th>
                  <th>Lead Source</th>
                  {/* <th>Lead Owner</th> */}
                  <th>Imported On</th>
                  <th>Assigned To</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr key={lead.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedLeadIds.includes(lead.id)}
                        onChange={() => handleCheckboxChange(lead.id)}
                      />
                    </td>
                    <td>{lead.id}</td>
                    <td>{lead.name}</td>
                    <td>{lead.email}</td>
                    <td>{lead.phone}</td>
                    <td>{lead.gender}</td>
                    <td>{lead.status}</td>
                    <td>{lead.leadSource || lead.Source || "NA"}</td>
                    {/* <td>{lead.leadOwner}</td> */}
                    <td>
                      {moment(lead.dateImported).format(" DD MMM, YYYY    ")}
                    </td>
                    <td>
                    {lead.LeadAssignments.length === 0
                      ? lead.assignedTo === "N/A" ? "Not Assigned" : lead.assignedTo
                      : getAssigneeEmployeeName(
                          employees,
                          lead.LeadAssignments[0]
                        )}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        // onClick={() => handleViewLead(lead)}
                      >
                        <i className="fa fa-eye" />
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        // onClick={() => handleDeleteLead(lead.id)}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
            </Grid2>
        </Grid2>
    </Grid2>
  );
}

export default LeadListMUI;
