import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import AdminSidebar from "./components/Sidebar/AdminSidebar";
import ManagerSidebar from "./components/Sidebar/ManagerSidebar";
import EmployeeSidebar from "./components/Sidebar/EmployeeSidebar";
import Login from "./pages/Auth/AuthPage";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { jwtDecode } from "jwt-decode";

import Unauthorized from "./Unauthorized";

// Admin routes
import AdminDashboard from "./pages/Admin/Dashboard";
import LeadDetail from "./pages/Admin/LeadDetails";
import LeadList from "./pages/Admin/LeadList";
import Settings from "./pages/Admin/Settings";
import Reports from "./pages/Admin/Reports";
import Leads from "./pages/Admin/Leads";
import EmployeeAdd from "./pages/Admin/EmployeeAdd";
import EmployeeEdit from "./pages/Admin/EmployeeEdit";
import LeadManagement from "./pages/Admin/LeadManagement";
// Employee routes
import EmployeeDashboard from "./pages/Employee/Dashboard";
import EmpLeads from "./pages/Employee/Leads";
import EmployeeViewLeadDetails from "./pages/Employee/LeadDetails";
import TaskViewEmployee from "./pages/Employee/Task";
import ScheduledTasks from "./pages/Employee/ScheduledTasks";
import EmployeeSettings from "./pages/Employee/Settings";
import UpdateTaskForm from "./pages/Employee/UpdateTaskForm";
import WalkinsList from "./pages/Employee/Walkins";
import LeadDetailsPage from "./pages/Employee/leads/LeadDetailsPage";

// Manager routes
import ManagerDashboard from "./pages/Manager/Dashboard";
import ManagerLeadList from "./pages/Manager/LeadList";
import ManagerLeads from "./pages/Manager/Leads";
import Header from "./components/Header";
import Employee from "./pages/Admin/Employee";
import WalkinsListManager from "./pages/Manager/Walkins";
import SettingsManager from "./pages/Manager/Settings";
import TaskManager from "./pages/Manager/Task";
import LeadDetailsManager from "./pages/Manager/LeadDetails";
import LeadListMUI from "./pages/Admin/LeadListMUI";
import Verification1 from "./pages/Employee/Verificaiton1";
import WithoutPrivateRoute from "./components/common/WithoutPrivateRoute";
import PrivateRoute from '../src/PrivateRoute'
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_MANAGER } from "./constants/AppConstants";
import WalkInsPage from "./pages/WalkInsPage";
import InvalidLeadsPage from "./pages/InvalidLeadsPage";

const App = () => {
  const { role, user, token, loading, login } = useAuth();
  useEffect(() => {
    console.log("App.js Mounted ...");
    let token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const user = decodedToken.user;
      login(token, user);
    }
  }, []);
  if (loading) {
    console.log("loading");
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Loading...........</h3>
      </div>
    );
  }

  // let token = localStorage.getItem("token");
  // let user;

  // if (token) {
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     user = JSON.parse(localStorage.getItem("user"));
  //     localStorage.setItem("user", JSON.stringify(user));
  //   } catch (error) {
  //     console.error("Invalid token:", error);
  //     user = null; // Ensure user is null if token is invalid
  //   }
  // } else {
  //   user = null;
  // }

  const renderSidebar = () => {
    if (!user) return null;
    if (user.role === "ROLE_ADMIN") return <AdminSidebar />;
    if (user.role === "ROLE_MANAGER") return <ManagerSidebar />;
    if (user.role === "ROLE_EMPLOYEE") return <EmployeeSidebar />;
    return null;
  };

  const getDefaultRoute = () => {
    console.log("getDefaultRoute");
    if (!user) return "/login";
    switch (user.user.role) {
      case "ROLE_ADMIN":
        return "/admin-dashboard";
      case "ROLE_MANAGER":
        return "/manager-dashboard";
      case "ROLE_EMPLOYEE":
        return "/employee-dashboard";
      default:
        return "/login";
    }
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <WithoutPrivateRoute>
              <Login></Login>
            </WithoutPrivateRoute>
          }
        />
        <Route path="/" element={<Header></Header>}>
          <Route index element={<Navigate to={getDefaultRoute()} />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            index
            path="/admin-dashboard"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/lead-list"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <LeadList />
              </PrivateRoute>
            }
          />
          {/* <Route path="/lead-details" element={<LeadDetail />} /> */}
          <Route
            path="/employee"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <Employee />
              </PrivateRoute>
            }
          />
          <Route
            path="/employee-add"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <EmployeeAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/employee-edit/:id"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <EmployeeEdit />
              </PrivateRoute>
            }
          />
          {/* <Route path="/settings" element={<Settings />} /> */}
          <Route
            path="/reports"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <AdminSidebar />
              </PrivateRoute>
            }
          />
          <Route
            path="/Leads"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <Leads />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-leads"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <LeadManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/invalid-leads"
            element={
              <PrivateRoute allowedRoles={["ROLE_ADMIN"]}>
                <InvalidLeadsPage/>
              </PrivateRoute>
            }
          />
          <Route
            path="/manager-dashboard"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/leadList"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <LeadList />
              </PrivateRoute>
            }
          />
          <Route
            path="/manager-leads"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <ManagerLeads />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/walkins-list-manager"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <WalkinsListManager />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/manager-settings"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <SettingsManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <Reports />
              </PrivateRoute>
            }
          />
          {/* <Route path="/lead-details" element={<LeadDetail />} /> */}
          <Route
            path="/add-task-manager"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <TaskManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/manager-lead-details"
            element={
              <PrivateRoute allowedRoles={["ROLE_MANAGER"]}>
                <LeadDetailsManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/lead-details-emp/:leadId"
            element={
              <PrivateRoute allowedRoles={[ROLE_ADMIN,ROLE_MANAGER,ROLE_EMPLOYEE]}>
                <LeadDetailsPage />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/employee-dashboard"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <EmployeeDashboard />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/emp-leads"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <EmpLeads />
              </PrivateRoute>
            }
          />
          <Route
            path="/emp-lead-details"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <EmployeeViewLeadDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-task-employee"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <TaskViewEmployee />
              </PrivateRoute>
            }
          />
          <Route
            path="/scheduled-tasks"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <ScheduledTasks />
              </PrivateRoute>
            }
          />
          <Route
            path="/employee-settings"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <EmployeeSettings />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/walkins-list"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <WalkinsList />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/update-task-form"
            element={
              <PrivateRoute allowedRoles={["ROLE_EMPLOYEE"]}>
                <UpdateTaskForm />
              </PrivateRoute>
            }
          />
          <Route path="/verification-1" element={<Verification1 />} />
          <Route path="/lead-details" element={<LeadDetail />} />
          <Route path="/walk-ins" element={<WalkInsPage/>}/>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;