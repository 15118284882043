export const verificationStatuses = [
  { id: 1, name: "Under Review" },
  { id: 2, name: "On Hold" },
  { id: 3, name: "Manager 1 Approved" },
  { id: 4, name: "Manager 2 Approved" },
  { id: 5, name: "Approved for Walk-In" },
  { id: 6, name: "Rejected" },
  { id: 7, name: "Normal Login"}
];

export const activityStatuses = [
  {id: 1, name: 'Interested'},
  {id:2, name:'Follow Up'},
  {id:3, name: 'Call Back'},
  {id:4, name: 'RNR ( Ring No Response )'},
  {id:5, name:'Switched Off'},
  {id:6, name: 'Busy'},
  {id:7, name:'Not Interested'},
  {id:8, name:'Not Working / Not Reachable'},
  {id:9, name:'Message'},
  {id:10, name: 'Email'},
  {id:11, name:'Verification 1'},
  {id:12, name:"Not Possible"},
  {id:13, name:"Not Contacted"}
]

export const leadStatusesForWalkInsPage = [
  {id : 1, name:"Scheduled For Walk-In"},
  { id: 2, name: "Okay for Policy" },
  { id: 3, name: "Think and get back" },
  { id: 4, name: "12 documents collected" },
  { id: 5, name: "Not okay for Policy" },
]

export const applicationStatusesForWalkInsPage = [
  // {id : 1, name:"Approved for Walk-In"},
  { id:2, name: "Manager 1 Approved" },
  { id: 3, name: "Manager 2 Approved" },
  { id: 4, name: "Rejected" },
  { id: 4, name: "Closed" },
  { id: 6, name: "Login" },
  { id: 7, name: "Normal Login"}
]

export const applicationStatusesForWalkInsPageOptions = [
  // {id : 1, name:"Approved for Walk-In"},
  { value: "Manager 1 Approved", label: "Manager 1 Approved" },
  { value: "Manager 2 Approved", label: "Manager 2 Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Closed", label: "Closed" },
  { value: "Login", label: "Login" },
  { value: "Normal Login", label: "Normal Login"}
]

export const leadStatusesForVerificationPageOptions = [
  // { value: "Verification 1", label: "Verification 1" },
  { value: "Schedule For Walk-In", label: "Schedule For Walk-In" },
  { value: "Scheduled For Walk-In", label: "Scheduled For Walk-In" },
]

export const leadStatusesForWalkInPageOptions = [
  { value: "Schedule For Walk-In", label: "Schedule For Walk-In" },
  {value: "Scheduled For Walk-In", label : "Scheduled For Walk-In"},
  {value : "Okay for Policy", label: "Okay for Policy"},
  {value : "Think and get back", label: "Think and get back"},
  {value : "12 documents collected", label: "12 documents collected"},
  {value : "Not okay for Policy", label: "Not okay for Policy"}
]

export const verificationStatusesForVerificationPageOptions = [
  { value: "Under Review", label: "Under Review" },
  { value: "On Hold", label: "On Hold" },
  { value: "Manager 1 Approved", label: "Manager 1 Approved" },
  { value: "Manager 2 Approved", label: "Manager 2 Approved" },
  { value: "Approved for Walk-In", label: "Approved for Walk-In" },
  { value: "Rejected", label: "Rejected" },
  { value: "Normal Login", label: "Normal Login"}
]

export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_EMPLOYEE = "ROLE_EMPLOYEE"
export const ROLE_MANAGER = "ROLE_MANAGER"
