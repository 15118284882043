import axios from "axios";

export async function getAllLeads(params){
    console.log('params to be sent to server api = ',params);
    return axios.get('/api/leads/get-all-leads-with-pagination', {params: params})
}

export async function assignLeads(payload){
    return axios.post('/api/lead-assignments/assign',{...payload})
}

export async function getLeadById(leadId){
    return axios.get(`/api/leads/get-lead-by-id/${leadId}`)
}

export async function uploadLeadsInBulk(payload){
    return axios.post('/api/leads/create-bulk-leads',payload)
}

export async function updateVerificationStatus(payload){
    return axios.post('/api/leads/update-verification-status',{...payload})
}

export async function getTotalLeadsCount(params){
    return axios.get('/api/leads/get-total-leads-count',{params:params})
}

export async function updateApplicationStatus(payload){
    return axios.post('/api/leads/update-application-status', {...payload})
}

export async function updateLeadStatus(payload){
    return axios.post('/api/leads/update-lead-status', {...payload})
}

export async function getAllDistinctLeadSources(){
    return axios.get('/api/leads/get-all-lead-sources')
}

export async function updateLeadDetails(id,payload){
    return axios.put(`/api/leads/update-lead-details/${id}`, {...payload})
}