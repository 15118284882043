import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { makeStyles } from "@mui/styles";
import EmployeeSidebar from "../../../components/Sidebar/EmployeeSidebar";
import axios from "axios";
import { Form, Button, Row, Col, Card, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../App.css";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { getCompanyCategories } from "../../../services/company-categories/company-categories-services";
import {
  downloadReport,
  formatDateTime,
  getUserDetails,
  parseDateTime,
} from "../../../utilities/helper-functions";
import { getLoanReportsByLeadId } from "../../../services/loan-reports-services";
import { getCreditReportsByLeadId } from "../../../services/credit-reports-services";
import {
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CommonSelect from "../../../components/common/CommonSelect";
import { updateLeadAndAddReportsAndStatus } from "../../../services/employee/leads-services";
import { getTasksByLeadId } from "../../../services/employee/tasks-services";
import CommonBackdrop from "../../../components/common/CommonBackdrop";
import { getLeadById } from "../../../services/admin/leads-services";
import { useAuth } from "../../../context/AuthContext";
import AdminSidebar from "../../../components/Sidebar/AdminSidebar";
import ManagerSidebar from "../../../components/Sidebar/ManagerSidebar";
import {
  applicationStatusesForWalkInsPageOptions,
  leadStatusesForVerificationPageOptions,
  leadStatusesForWalkInPageOptions,
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  verificationStatusesForVerificationPageOptions,
} from "../../../constants/AppConstants";
import CommonSnackbar from "../../../components/common/CommonSnackbar";
import SummaryTable from "../../../components/SummaryTable";
import LeadDocuments from "../../../components/LeadDocuments";
import moment from "moment";
import ScheduleWalkInDialogue from "../../../components/ScheduleWalkInDialogue";
import ConfirmationDialogue from "../../../components/common/ConfirmationDialogue";

const LeadDetailsPage = React.memo(function LeadDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("you are from ", location);
  const [filters, setFilters] = useState(location.state?.filters);
  const [pagination, setPagination] = useState(location.state?.pagination);
  const [fromPage, setFromPage] = useState(location.state?.fromPage);
  const { user: userFromContext, users } = useAuth();
  const showApplicationStatusSection =
    location.state &&
    location.state.showApplicationStatusChangeSection &&
    userFromContext.user.role !== ROLE_EMPLOYEE;
  const showLeadStatusChangeSection =
    location.state &&
    location.state.showLeadStatusChangeSection &&
    userFromContext.user.role === ROLE_EMPLOYEE;
  const showVerificationStatusChangeSection =
    location.state &&
    location.state.showVerificationStatusChangeSection &&
    userFromContext.user.role !== ROLE_EMPLOYEE;
  const showStatusUpdateSection =
    !showLeadStatusChangeSection &&
    !showApplicationStatusSection &&
    !showVerificationStatusChangeSection;
  const { leadId } = useParams();
  const [lead, setLead] = useState({});
  const [initialLead, setInitialLead] = useState(null);
  const [reload, setReload] = useState(false);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [user, setUser] = useState(getUserDetails());
  const [activityPayload, setActivityPayload] = useState({
    leadId: lead.id,
    userId: user.id,
    name: lead.name || null,
    email: lead.email || null,
    phone: lead.phone || null,
    city: lead.city || null,
    salary: lead.salary || null,
    company: lead.company || null,
    company_category_id: lead.company_category_id || null,
    docsCollected: lead.Activities?.[0]?.docs_collected === true,
    is_rejected: lead.is_rejected || false,
    rejection_reason: lead.rejection_reason || null,
    application_status: lead.application_status || null,
  });
  const [initialActivityPayload, setInitialActivityPayload] = useState({
    leadId: lead.id,
    userId: user.id,
    name: lead.name || null,
    email: lead.email || null,
    phone: lead.phone || null,
    city: lead.city || null,
    salary: lead.salary || null,
    company: lead.company || null,
    company_category_id: lead.company_category_id || null,
    docsCollected: lead.Activities?.[0]?.docs_collected === true,
    is_rejected: lead.is_rejected || false,
    rejection_reason: lead.rejection_reason || null,
    application_status: lead.application_status || null,
  });

  const [loanReports, setLoanReports] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [creditReports, setCreditReports] = useState([]);
  const [loanReportsToBeSubmitted, setLoanReportsToBeSubmitted] = useState([]);
  const [creditReportsToBeSubmitted, setCreditReportsToBeSubmitted] = useState(
    []
  );
  const [otherLoanReportsToBeSubmitted, setOtherLoanReportsToBeSubmitted] =
    useState([]);

  const [loanReportPayload, setLoanReportPayload] = useState({
    lead_id: leadId,
    userId: user.id,
    loan_type: "",
    bank_name: "",
    loan_amount: "",
    emi: "",
    outstanding: "",
    created_by: user.id,
  });

  const [creditReportPayload, setCreditReportPayload] = useState({
    lead_id: leadId,
    userId: user.id,
    credit_card_name: "",
    total_outstanding: "",
    created_by: user.id,
  });

  const [otherLoanReportPayload, setOtherLoanReportPayload] = useState({
    lead_id: leadId,
    userId: user.id,
    loan_type: "",
    bank_name: "",
    loan_amount: "",
    emi: "",
    outstanding: "",
    created_by: user.id,
  });

  // task update states
  const [selectedStatus, setSelectedStatus] = useState(null);
  const statusOptions = [
    { value: "Interested", label: "Interested" },
    { value: "Follow Up", label: "Follow Up" },
    { value: "Call Back", label: "Call Back" },
    { value: "RNR ( Ring No Response )", label: "RNR ( Ring No Response )" },
    { value: "Switched Off", label: "Switched Off" },
    { value: "Busy", label: "Busy" },
    { value: "Not Interested", label: "Not Interested" },
    {
      value: "Not Working / Not Reachable",
      label: "Not Working / Not Reachable",
    },
    { value: "Message", label: "Message" },
    { value: "Email", label: "Email" },
    { value: "Not Possible", label: "Not Possible" },
  ];
  if (activityPayload.docsCollected) {
    statusOptions.push({ value: "Verification 1", label: "Verification 1" });
  }
  const tasksForStatuses = ["Call Back", "Follow Up"];
  const [taskDate, setTaskDate] = useState("");
  const [hour, setHour] = useState(12);
  const [minute, setMinute] = useState("00");
  const [period, setPeriod] = useState("AM");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // initial states for activity changes track
  const [initiallySelectedStatus, setInitiallySelectedStatus] = useState(null);
  const [initialTaskDateForTask, setInitialTaskDateForTask] = useState("");
  const [initialMinuteForTask, setInitialMinuteForTask] = useState("00");
  const [initialHourForTask, setInitialHourForTask] = useState(12);
  const [initialPeriodForTask, setInitialPeriodForTask] = useState("AM");
  const [initialDescription, setInitialDescription] = useState("");

  // reports summary states
  const [loanReportsSummary, setLoanReportsSummary] = useState([]);
  const [creditReportsSummary, setCreditReportsSummary] = useState([]);

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  // error handling state
  const [error, setError] = useState({});

  // initial payloads and data for change track
  const initialLoanReportPayload = {
    lead_id: leadId,
    userId: user.id,
    loan_type: "",
    bank_name: "",
    loan_amount: "",
    emi: "",
    outstanding: "",
    created_by: user.id,
  };

  const initialCreditReportPayload = {
    lead_id: leadId,
    userId: user.id,
    credit_card_name: "",
    total_outstanding: "",
    created_by: user.id,
  };

  // states for application status
  const [selectedApplicationStatus, setSelectedAppliationStatus] =
    useState(null);
  const [
    initialSelectedApplicationStatus,
    setInitialSelectedApplicationStatus,
  ] = useState(null);
  const [showRejectionReasonInput, setShowRejectionReasonInput] =
    useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [initialIsRejected, setInitialIsRejected] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [initialRejectionReason, setInitialRejectionReason] = useState(null);

  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  const [shouldPageEdit, setShouldPageEdit] = useState(true);

  // states for lead ( call ) status
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(null);
  const [showScheduleWalkInDialogue, setShowScheduleWalkInDialogue] =
    useState(false);

  // states for verification status
  const [selectedVerificationStatus, setSelectedVerificationStatus] =
    useState(null);
  const [
    payloadForUpdateVerificationStatus,
    setPayloadForUpdateVerificationStatus,
  ] = useState(null);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);

  // lead ( call ) status states
  const [payloadForConfirmationDialogue, setPayloadForConfirmationDialogue] = useState(null)
  const [titleForConfirmationDialogue, setTitleForConfirmationDialogue] = useState(null)
  const [apiNameForConfirmationDialogue, setApiNameForConfirmationDialogue] = useState(null)

  useEffect(() => {
    setBackdropMessage("Fetching Lead Details...");
    setOpenBackdrop(true);
    const fetchData = async () => {
      try {
        // Fetch common data
        const commonPromises = [
          fetchLeadByLeadId(leadId),
          fetchCompanyCategories(),
          fetchLoanReportsByLeadId(leadId),
          fetchCreditReportsByLeadId(leadId),
        ];

        // Conditionally fetch tasks or set states
        if (!showApplicationStatusSection) {
          commonPromises.push(fetchTasksByLeadId(leadId));
        }
        await Promise.all(commonPromises);
        setOpenBackdrop(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBackdropMessage("Failed to fetch lead details !");
        setResponseType("FAILED");
        setShouldBackdropCloseOnClickOfOutside(true);
      }
    };

    fetchData();
  }, [reload]);

  useEffect(() => {
    if (lead) {
      setActivityPayload((prevPayload) => ({
        ...prevPayload,
        docsCollected: lead.Activities?.[0]?.docs_collected === true, // Sync with fetched data
      }));
      setInitialActivityPayload((prevPayload) => ({
        ...prevPayload,
        docsCollected: lead.Activities?.[0]?.docs_collected === true, // Sync with fetched data
      }));
      if (showApplicationStatusSection) {
        setSelectedAppliationStatus(lead.application_status || "");
        setInitialSelectedApplicationStatus(lead.application_status || "");
        setIsRejected(lead.is_rejected);
        setShowRejectionReasonInput(lead.is_rejected);
        setInitialIsRejected(lead.is_rejected);
        setRejectionReason(lead.rejection_reason);
        setInitialRejectionReason(lead.rejection_reason);
      }
      if (showLeadStatusChangeSection) {
        setSelectedLeadStatus(lead.lead_status || "");
      }
      if (showVerificationStatusChangeSection) {
        setSelectedVerificationStatus(lead.verification_status || "");
      }
    }
  }, [lead]);

  useEffect(() => {
    console.log(
      "task date = ",
      taskDate,
      "type of task date = ",
      typeof taskDate
    );
    console.log("minute = ", minute, " type of minute = ", typeof minute);
    console.log(("hour = ", hour, "type of hout = ", typeof hour));
    console.log(("period = ", period, " type of period = ", typeof period));
  }, [taskDate, minute, hour, period]);

  async function fetchCompanyCategories() {
    try {
      const response = await getCompanyCategories();
      setCompanyCategories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const isLeadChanged = !isEqual(activityPayload, initialActivityPayload);
      console.log(
        "selected status = ",
        selectedStatus,
        " initial selected status = ",
        initiallySelectedStatus
      );
      console.log(
        "selected task date = ",
        taskDate,
        " initial task date = ",
        initialTaskDateForTask
      );
      console.log(
        "selected hour = ",
        hour,
        "initial hour = ",
        initialHourForTask
      );
      console.log(
        "selected minute = ",
        minute,
        " initial minute = ",
        initialMinuteForTask
      );
      console.log(
        "selected period = ",
        period,
        "initial perid = ",
        initialPeriodForTask
      );
      const isActivityChanged =
        selectedStatus !== initiallySelectedStatus ||
        taskDate !== initialTaskDateForTask ||
        hour !== initialHourForTask ||
        minute !== initialMinuteForTask ||
        period !== initialPeriodForTask ||
        description !== initialDescription ||
        activityPayload.docsCollected !== initialActivityPayload.docsCollected;

      const isLoanReportsPayloadChanged =
        loanReportsToBeSubmitted.length > 0 ||
        otherLoanReportsToBeSubmitted.length > 0;

      const isCreditReportPayloadChanged =
        creditReportsToBeSubmitted.length > 0;

      const isApplicationStatusChanged =
        selectedApplicationStatus !== initialSelectedApplicationStatus ||
        isRejected !== initialIsRejected ||
        rejectionReason !== initialRejectionReason;

      console.log("is activity changed = ", isActivityChanged);

      console.log(parseDateTime("2024-12-20T07:40:00.000Z"));
      let payload = {
        lead: null,
        userId: null,
        leadId: null,
        loanReports: null,
        creditReports: null,
        activity: null,
      };
      if (isLeadChanged) {
        payload["lead"] = { ...activityPayload };
        delete payload.lead.createdAt;
        delete payload.lead.updatedAt;
        delete payload.lead.lead_source;
        delete payload.lead.Activities;
        delete payload.lead.address;
        delete payload.lead.docsCollected;
        payload.lead.lead_status = selectedStatus;
        if (selectedStatus === "Verification 1") {
          payload.lead.verification_status = selectedStatus;
        }
      }
      payload.leadId = lead.id;
      payload.userId = user.id;

      if (isLoanReportsPayloadChanged) {
        payload.loanReports = [
          ...loanReportsToBeSubmitted,
          ...otherLoanReportsToBeSubmitted,
        ];
      }

      if (isCreditReportPayloadChanged) {
        payload.creditReports = [...creditReportsToBeSubmitted];
      }

      if (isActivityChanged) {
        if (selectedStatus === "Not Possible" && description === "") {
          setDescriptionError("Note is required for Not Possible Status");
          return;
        } else {
          setDescriptionError("");
        }
        let taskPayload = {};
        taskPayload.userId = user.id;
        taskPayload.lead_id = lead.id;
        taskPayload.activity_status = selectedStatus;
        taskPayload.created_by = user.id;
        if (tasksForStatuses.includes(selectedStatus)) {
          let followUpDate = formatDateTime(taskDate, hour, minute, period);
          taskPayload.follow_up = followUpDate;
        }
        taskPayload.description = description;
        taskPayload.docs_collected = activityPayload.docsCollected;
        payload.activity = taskPayload;
      }

      if (isApplicationStatusChanged) {
        if (lead.lead_status !== "12 documents collected") {
          setSnackbarMessage("12 Documents Not Collected Yet !");
          setSnackbarType("error");
          setOpenSnackbar(true);
          setTimeout(() => setOpenSnackbar(false), 2000);
          return;
        }
        payload.verification_status = lead.lead_status;
        payload.application_status = selectedApplicationStatus;
        payload.rejection_reason = rejectionReason;
      }
      payload.role = userFromContext.user.role;
      console.log("payload for api = ", payload);
      setResponseType("");
      setShouldBackdropCloseOnClickOfOutside(false);
      setBackdropMessage("Updating lead details ...");
      setOpenBackdrop(true);
      const response = await updateLeadAndAddReportsAndStatus(payload);
      console.log("response = ", response);
      if (
        response.data.status === "success" &&
        response.data.statusCode == 200
      ) {
        setResponseType("SUCCESS");
        setBackdropMessage("Lead updated successfully !");
        setShouldBackdropCloseOnClickOfOutside(true);
        // setReload(!reload);
      }
    } catch (error) {
      console.log(error);
      setResponseType("FAILED");
      setBackdropMessage("Failed to update lead !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  function activityInputChangeHandler(e) {
    const { name, value } = e.target;
    setActivityPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  }

  function loanReportInputChangeHandler(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setLoanReportPayload({ ...loanReportPayload, [name]: value });
  }

  function creditReportInputChangeHandler(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setCreditReportPayload({ ...creditReportPayload, [name]: value });
  }

  async function fetchLoanReportsByLeadId(leadId) {
    try {
      const response = await getLoanReportsByLeadId(leadId);
      console.log("loan reports = ", response.data.data);
      setLoanReports(response.data.data);
      setLoanReportsSummary([...loanReportsSummary, ...response.data.data]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCreditReportsByLeadId(leadId) {
    try {
      const response = await getCreditReportsByLeadId(leadId);
      setCreditReports(response.data.data);
      setCreditReportsSummary([...creditReportsSummary, ...response.data.data]);
    } catch (error) {
      console.log(error);
    }
  }

  function addLoanReportHandler() {
    if (
      loanReportPayload.loan_type &&
      loanReportPayload.bank_name &&
      loanReportPayload.emi &&
      loanReportPayload.loan_amount &&
      loanReportPayload.outstanding
    ) {
      if (editIndex === null) {
        // Add a new report
        setLoanReportsToBeSubmitted([
          ...loanReportsToBeSubmitted,
          loanReportPayload,
        ]);
        setLoanReportsSummary([...loanReportsSummary, loanReportPayload]);
      } else {
        // Update an existing report
        console.log(
          "loan reports to be submitted before edit = ",
          loanReportsToBeSubmitted
        );
        const updatedReports = [...loanReportsToBeSubmitted];
        updatedReports[editIndex] = loanReportPayload;
        console.log(
          "loan reports to be submitted after edit = ",
          updatedReports
        );
        setLoanReportsToBeSubmitted(updatedReports);
        setLoanReportsSummary([
          ...loanReports,
          ...otherLoanReportsToBeSubmitted,
          ...updatedReports,
        ]);
        setEditIndex(null); // Reset edit index after updating
      }
      resetLoanReportPayload();
    } else {
      alert("Please enter valid loan report details!");
    }
  }

  function resetLoanReportPayload() {
    setLoanReportPayload((prevPayload) => ({
      ...prevPayload,
      leadId: lead.id,
      userId: user.id,
      loan_type: "",
      bank_name: "",
      loan_amount: "",
      emi: "",
      outstanding: "",
    }));
  }

  function addCreditReportHandler() {
    if (
      creditReportPayload.credit_card_name &&
      creditReportPayload.total_outstanding
    ) {
      if (editIndex === null) {
        setCreditReportsToBeSubmitted([
          ...creditReportsToBeSubmitted,
          creditReportPayload,
        ]);
        setCreditReportsSummary([...creditReportsSummary, creditReportPayload]);
      } else {
        const updatedReports = [...creditReportsToBeSubmitted];
        updatedReports[editIndex] = creditReportPayload;
        setCreditReportsToBeSubmitted(updatedReports);
        setCreditReportsSummary([...creditReports, ...updatedReports]);
        setEditIndex(null);
      }
      resetCreditReportPayload();
    } else {
      alert("Please enter valid credit report details !");
    }
  }

  function resetCreditReportPayload() {
    setCreditReportPayload((prevPayload) => ({
      ...prevPayload,
      leadId: lead.id,
      userId: user.id,
      credit_card_name: "",
      total_outstanding: "",
    }));
  }

  function otherLoanReportInputChangeHandler(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setOtherLoanReportPayload({ ...otherLoanReportPayload, [name]: value });
  }

  function addOtherLoanReportHandler() {
    if (
      otherLoanReportPayload.loan_type &&
      otherLoanReportPayload.bank_name &&
      otherLoanReportPayload.emi &&
      otherLoanReportPayload.loan_amount &&
      otherLoanReportPayload.outstanding
    ) {
      if (editIndex === null) {
        setOtherLoanReportsToBeSubmitted([
          ...otherLoanReportsToBeSubmitted,
          otherLoanReportPayload,
        ]);
        setLoanReportsSummary([...loanReportsSummary, otherLoanReportPayload]);
      } else {
        const updatedReports = [...otherLoanReportsToBeSubmitted];
        updatedReports[editIndex] = otherLoanReportPayload;
        setOtherLoanReportsToBeSubmitted(updatedReports);
        setLoanReportsSummary([
          ...loanReports,
          ...loanReportsToBeSubmitted,
          ...updatedReports,
        ]);
        setEditIndex(null);
      }
      resetOtherLoanReportPayload();
    } else {
      alert("Please enter valid loan report details !");
    }
  }

  function resetOtherLoanReportPayload() {
    setOtherLoanReportPayload((prevPayload) => ({
      ...prevPayload,
      leadId: lead.id,
      userId: user.id,
      loan_type: "",
      bank_name: "",
      loan_amount: "",
      emi: "",
      outstanding: "",
    }));
  }

  async function fetchTasksByLeadId(leadId) {
    try {
      const response = await getTasksByLeadId(leadId);
      console.log(
        "response from gettaskbyleadid api = ",
        response.data.data[0]
      );
      const recentTask = response.data.data[0];
      console.log("recent task = ", recentTask);

      setSelectedStatus(recentTask?.activity_status);
      setInitiallySelectedStatus(recentTask?.activity_status);
      if (tasksForStatuses.includes(recentTask?.activity_status)) {
        const { taskDate, hour, minute, period } = parseDateTime(
          recentTask.follow_up
        );
        setTaskDate(taskDate);
        setHour(hour);
        setMinute(minute);
        setPeriod(period);

        //setting initial states
        setInitialTaskDateForTask(taskDate);
        setInitialHourForTask(hour);
        setInitialMinuteForTask(minute);
        setInitialPeriodForTask(period);
      }
      setDescription(recentTask?.description);
      setInitialDescription(recentTask?.description);
    } catch (error) {
      console.log(error);
    }
  }

  function handleEdit(index) {
    setLoanReportPayload(loanReportsToBeSubmitted[index]);
    setEditIndex(index);
  }

  function handleRemove(index) {
    const updatedReports = [...loanReportsToBeSubmitted];
    updatedReports.splice(index, 1);
    setLoanReportsToBeSubmitted(updatedReports);
    setLoanReportsSummary([
      ...loanReports,
      ...otherLoanReportsToBeSubmitted,
      ...updatedReports,
    ]);
    setEditIndex(null);
  }

  function handleEditCreditReport(index) {
    setCreditReportPayload(creditReportsToBeSubmitted[index]);
    setEditIndex(index);
  }

  function handleRemoveCreditReport(index) {
    const updatedReports = [...creditReportsToBeSubmitted];
    updatedReports.splice(index, 1);
    setCreditReportsToBeSubmitted(updatedReports);
    setCreditReportsSummary([...creditReports, ...updatedReports]);
    setEditIndex(null);
  }

  function handleEditOtherLoanReport(index) {
    setOtherLoanReportPayload(otherLoanReportsToBeSubmitted[index]);
    setEditIndex(index);
  }

  function handleRemoveOtherLoanReport(index) {
    const updatedReports = [...otherLoanReportsToBeSubmitted];
    updatedReports.splice(index, 1);
    setOtherLoanReportsToBeSubmitted(updatedReports);
    setLoanReportsSummary([
      ...loanReports,
      ...loanReportsToBeSubmitted,
      ...updatedReports,
    ]);
    setEditIndex(null);
  }

  async function fetchLeadByLeadId(leadId) {
    try {
      const response = await getLeadById(leadId);
      setLead(response.data.data);
      setInitialLead(response.data.data);
      setActivityPayload(response.data.data);
      setInitialActivityPayload(response.data.data);
      setShouldPageEdit(
        response.data.data.is_rejected &&
          userFromContext.user.role === ROLE_EMPLOYEE
          ? false
          : true
      );
    } catch (error) {
      console.log(error);
    }
  }

  function doValidation() {
    if (
      activityPayload.docsCollected === true &&
      loanReportsToBeSubmitted.length == 0 &&
      creditReportsToBeSubmitted.length == 0 &&
      otherLoanReportsToBeSubmitted == 0
    ) {
      setError((prevError) => ({
        ...prevError,
        docsCollectedError: "At least one report is required!",
      }));
      return false;
    }
    return true;
  }

  function removeErrorHandler(errorKey) {
    setError((prevError) => ({ ...prevError, [errorKey]: null }));
  }

  function applicationStatusChangeHandler(e) {
    e.stopPropagation()
    const { name, value } = e.target;
    setSelectedAppliationStatus(value);
    if (value === "Rejected") {
      setShowRejectionReasonInput(true);
    } else {
      setShowRejectionReasonInput(false);
    }
  }

  // lead status change handler functions
  function leadStatusChangeHandler(e) {
    e.stopPropagation();
    if (userFromContext.user.role !== ROLE_EMPLOYEE) {
      setSnackbarMessage("Manager has no access to update lead status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      return;
    }

    switch (fromPage) {
      case "verification-1":
        if (
          lead.verification_status !== "Approved for Walk-In" &&
          e.target.value === "Schedule For Walk-In"
        ) {
          setSnackbarMessage(
            "Only approved leads can be scheduled for Walk-In"
          );
          setSnackbarType("error");
          setOpenSnackbar(true);
          setTimeout(() => setOpenSnackbar(false), 2000);
        } else {
          if (e.target.value === "Schedule For Walk-In") {
            setSelectedLeadStatus(e.target.value);
            setShowScheduleWalkInDialogue(true);
          } else {
            setSelectedLeadStatus("");
          }
        }
        break;

      case "walk-ins":
        if (e.target.value !== "Schedule For Walk-In") {
          if (userFromContext.user.role !== ROLE_EMPLOYEE) {
            setSnackbarMessage("Manager has no access to update lead status !");
            setSnackbarType("error");
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 1000);
            return;
          } else {
            let payload = {};
            payload["lead_id"] = leadId;
            payload["lead_status"] = e.target.value;
            payload["role"] = userFromContext.user.role;
            console.log("payload for update lead status = ", payload);
            setPayloadForUpdateVerificationStatus(payload);
            setTitleForConfirmationDialogue("Update Lead Status");
            setApiNameForConfirmationDialogue("updateLeadStatus");
            setShowConfirmationDialogue(true);
          }
        }
    }
  }

  function updateLead(newStatus) {
    setLead((prevLead) => ({
      ...prevLead,
      lead_status: newStatus,
      verification_status: newStatus,
    }));
  }

  function verificationStatusChangeHandler(e) {
    e.stopPropagation();
    if (userFromContext.user.role === ROLE_EMPLOYEE) {
      setSnackbarMessage("No access to update verification status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      return;
    } else {
      let payload = {};
      payload["lead_id"] = lead.id;
      payload["verification_status"] = e.target.value;
      payload["role"] = userFromContext.user.role;
      payload["rejected_by_id"] = userFromContext.user.id;
      console.log("payload for update Verification status = ", payload);
      setPayloadForUpdateVerificationStatus(payload);
      setTitleForConfirmationDialogue("Update Verification Status");
      setApiNameForConfirmationDialogue("updateVerificationStatus");
      setShowConfirmationDialogue(true);
    }
  }

  function updateLeadState(lead) {
    setLead((prevLead) => ({ ...prevLead, ...lead }));
  }

  function navigateTo(fromPage) {
    // switch (fromPage) {
    //   case "verification-1":
    //     return "/verification-1";
    //   case "walk-ins":
    //     return "/walk-ins";
    //   case "emp-leads":
    //     return "/emp-leads";
    // }
    return `/${fromPage}`
  }

  return (
    <>
      {userFromContext?.user.role === ROLE_ADMIN ? (
        <AdminSidebar />
      ) : userFromContext?.user.role === ROLE_MANAGER ? (
        <ManagerSidebar />
      ) : (
        <EmployeeSidebar />
      )}
      <div className="global-container">
        <div className="container mt-5">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="my-2"
          >
            <Button
              onClick={() =>
                navigate(navigateTo(location.state.fromPage), {
                  state: { filters, pagination },
                })
              }
              style={{ backgroundColor: "#fd7e14", color: "white" }}
            >
              Back
            </Button>
            <h2
              className="text-center"
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <strong>Activity</strong>
            </h2>
          </div>
          <Form onSubmit={(e) => handleSubmit(e)}>
            {/* general information card */}
            <Card className="p-3">
              <h4>General Information</h4>
              {/* row for name and email */}
              <Row>
                {/* lead name */}
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formLeadId">
                    <Form.Label>Lead Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={activityPayload.name}
                      onChange={(e) => activityInputChangeHandler(e)}
                      placeholder="Enter Lead Name"
                      readOnly
                    />
                  </Form.Group>
                </Col>

                {/* email */}
                <Col md={6}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={activityPayload.email}
                      onChange={(e) => activityInputChangeHandler(e)}
                      placeholder="Enter email"
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* row for phone city  */}
              <Row>
                {/* phone */}
                <Col md={4}>
                  <Form.Group controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={activityPayload.phone}
                      onChange={(e) => activityInputChangeHandler(e)}
                      placeholder="Enter phone number"
                      maxLength={10} // Does not restrict in "number" type, but works for "text"
                      onInput={(e) => {
                        const value = e.target.value;
                        if (value.length > 10) {
                          e.target.value = value.slice(0, 10); // Restrict to 10 characters
                        }
                      }}
                      readOnly
                    />
                  </Form.Group>
                </Col>

                {/* city */}
                <Col md={4}>
                  <Form.Group controlId="formCity">
                    <Form.Label>City</Form.Label>
                    <div className="input-with-icon">
                      <Form.Control
                        type="text"
                        name="city"
                        value={activityPayload.city}
                        onChange={(e) => activityInputChangeHandler(e)}
                        placeholder="Enter city"
                      />
                      <i className="fas fa-pen"></i>
                    </div>
                  </Form.Group>
                </Col>

                {/* salary */}
                <Col md={4}>
                  <Form.Group controlId="formSalary">
                    <Form.Label>Salary</Form.Label>
                    <div className="input-with-icon">
                      <Form.Control
                        type="number"
                        name="salary"
                        value={activityPayload.salary}
                        onChange={(e) => activityInputChangeHandler(e)}
                        placeholder="Enter salary"
                        min="0"
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault(); // Block '-' and 'e'
                          }
                        }}
                      />
                      <i className="fas fa-pen"></i>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* row for company and salary */}
              <Row>
                <Col md={6} className="mt-3">
                  <Form.Group controlId="formCompany">
                    <Form.Label>Company</Form.Label>
                    <div className="input-with-icon">
                      <Form.Control
                        type="text"
                        name="company"
                        value={activityPayload.company}
                        onChange={(e) => activityInputChangeHandler(e)}
                        placeholder="Enter company name"
                      />
                      <i className="fas fa-pen"></i>
                    </div>
                  </Form.Group>
                </Col>

                {/* company category */}
                <Col md={6} className="mt-3">
                  <Form.Group controlId="formLeadId">
                    <Form.Label>Company Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="company_category_id"
                      value={activityPayload.company_category_id}
                      onChange={(e) => activityInputChangeHandler(e)}
                      // className="mt-2"
                    >
                      <option value="">Select a company category</option>
                      {companyCategories.length > 0 ? (
                        companyCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No leads found</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Card>

            {/* are documents collected toggle section */}
            <Form.Group className="mt-3">
              <Form.Label style={{ display: "flex", alignItems: "center" }}>
                Are all documents collected?
                {error.docsCollectedError && (
                  <h6 style={{ color: "red", margin: "0px 0.5rem" }}>
                    {error.docsCollectedError}
                  </h6>
                )}
              </Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Check
                  type="radio"
                  id="documentsCollectedYes"
                  name="documentsCollected"
                  label="Yes"
                  checked={activityPayload.docsCollected === true}
                  onClick={() => {
                    setActivityPayload((prevPayload) => ({
                      ...prevPayload,
                      docsCollected: true,
                    }));
                    removeErrorHandler("docsCollectedError");
                  }}
                  className="me-3"
                  required
                  disabled={!shouldPageEdit}
                />
                <Form.Check
                  type="radio"
                  id="documentsCollectedNo"
                  name="documentsCollected"
                  label="No"
                  checked={activityPayload.docsCollected === false}
                  onClick={() => {
                    setActivityPayload((prevPayload) => ({
                      ...prevPayload,
                      docsCollected: false,
                    }));
                    removeErrorHandler("docsCollectedError");
                  }}
                  required
                  disabled={!shouldPageEdit}
                />
              </div>
            </Form.Group>

            {activityPayload.docsCollected && (
              <>
                {/* payslips and credit bureau section */}
                <LeadDocuments
                  leadId={leadId}
                  leadName={lead.name}
                  disableUpload={!shouldPageEdit}
                />

                {/* loan information card */}
                <Card className="p-3">
                  <h4>Loan Information</h4>

                  {/* loan report section */}
                  <h5>Loan Report</h5>
                  <div className="row mb-3">
                    {/* loan_type */}
                    <div className="col">
                      <label htmlFor="loan_type">Loan Type</label>
                      <select
                        id="loan_type"
                        name="loan_type"
                        className="form-control"
                        value={loanReportPayload.loan_type}
                        onChange={(e) => loanReportInputChangeHandler(e)}
                      >
                        <option value="">Select Loan Type</option>
                        <option value="PERSONAL_LOAN">Personal Loan</option>
                        <option value="HOME_LOAN">Home Loan</option>
                        <option value="GOLD_LOAN">Gold Loan</option>
                      </select>
                    </div>

                    <div className="col">
                      <label htmlFor="bank_name">Bank Name:</label>
                      <input
                        type="text"
                        id="bank_name"
                        name="bank_name"
                        className="form-control"
                        value={loanReportPayload.bank_name}
                        onChange={(e) => loanReportInputChangeHandler(e)}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="loan_amount">Loan Amount:</label>
                      <input
                        type="number"
                        id="loan_amount"
                        name="loan_amount"
                        className="form-control"
                        value={loanReportPayload.loan_amount}
                        onChange={(e) => loanReportInputChangeHandler(e)}
                        maxLength={12}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="emi">EMI:</label>
                      <input
                        type="number"
                        id="emi"
                        name="emi"
                        className="form-control"
                        value={loanReportPayload.emi}
                        onChange={(e) => loanReportInputChangeHandler(e)}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="outstanding">Outstanding Amount:</label>
                      <input
                        type="number"
                        id="outstanding"
                        name="outstanding"
                        className="form-control"
                        value={loanReportPayload.outstanding}
                        onChange={(e) => loanReportInputChangeHandler(e)}
                      />
                    </div>
                  </div>

                  <Button
                    variant="secondary"
                    onClick={() => addLoanReportHandler()}
                  >
                    Add
                  </Button>

                  {/* newly added loan reports section */}
                  {loanReportsToBeSubmitted.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Loan Type</th>
                              <th>Bank Name</th>
                              <th>Loan Amount</th>
                              <th>EMI</th>
                              <th>Outstanding</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loanReportsToBeSubmitted.map((loan, index) => (
                              <tr key={loan.id}>
                                <td>{index + 1}</td>
                                <td>{loan.loan_type}</td>
                                <td>{loan.bank_name}</td>
                                <td>{loan.loan_amount}</td>
                                <td>{loan.emi}</td>
                                <td>{loan.outstanding}</td>
                                <td
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Button onClick={() => handleEdit(index)}>
                                    Edit
                                  </Button>
                                  <Button onClick={() => handleRemove(index)}>
                                    Remove
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <br />
                  <br />

                  {/* credit report section */}
                  <h5>Credit Report</h5>
                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="credit_card_name">
                        Credit Card Name:
                      </label>
                      <input
                        type="text"
                        id="credit_card_name"
                        name="credit_card_name"
                        className="form-control"
                        value={creditReportPayload.credit_card_name}
                        onChange={(e) => creditReportInputChangeHandler(e)}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="total_outstanding">
                        Total Outstanding Amount:
                      </label>
                      <input
                        type="number"
                        id="total_outstanding"
                        name="total_outstanding"
                        className="form-control"
                        value={creditReportPayload.total_outstanding}
                        onChange={(e) => creditReportInputChangeHandler(e)}
                      />
                    </div>
                  </div>

                  <Button
                    variant="secondary"
                    onClick={() => addCreditReportHandler()}
                  >
                    Add
                  </Button>

                  {/* newly added credit reports section */}
                  {creditReportsToBeSubmitted.length > 0 && (
                    <div className="col-sm-9 mt-3">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Credit Card Name</th>
                            <th>Total Outstanding</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {creditReportsToBeSubmitted.map((credit, index) => (
                            <tr key={credit.id}>
                              <td>{index + 1}</td>
                              <td>{credit.credit_card_name}</td>
                              <td>{credit.total_outstanding}</td>
                              <td
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Button
                                  onClick={() => handleEditCreditReport(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleRemoveCreditReport(index)
                                  }
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <br />
                  <br />

                  {/* other report or loan section */}
                  <h5>Other Loans</h5>
                  <div className="row mb-3">
                    {/* loan_type */}
                    <div className="col">
                      <label htmlFor="loan_type">Loan Type</label>
                      <input
                        type="text"
                        id="loan_type"
                        name="loan_type"
                        className="form-control"
                        value={otherLoanReportPayload.loan_type}
                        onChange={(e) => otherLoanReportInputChangeHandler(e)}
                      />
                    </div>

                    <div className="col">
                      <label htmlFor="bank_name">Bank Name:</label>
                      <input
                        type="text"
                        id="bank_name"
                        name="bank_name"
                        className="form-control"
                        value={otherLoanReportPayload.bank_name}
                        onChange={(e) => otherLoanReportInputChangeHandler(e)}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="loan_amount">Loan Amount:</label>
                      <input
                        type="number"
                        id="loan_amount"
                        name="loan_amount"
                        className="form-control"
                        value={otherLoanReportPayload.loan_amount}
                        onChange={(e) => otherLoanReportInputChangeHandler(e)}
                        maxLength={12}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="emi">EMI:</label>
                      <input
                        type="number"
                        id="emi"
                        name="emi"
                        className="form-control"
                        value={otherLoanReportPayload.emi}
                        onChange={(e) => otherLoanReportInputChangeHandler(e)}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="outstanding">Outstanding Amount:</label>
                      <input
                        type="number"
                        id="outstanding"
                        name="outstanding"
                        className="form-control"
                        value={otherLoanReportPayload.outstanding}
                        onChange={(e) => otherLoanReportInputChangeHandler(e)}
                      />
                    </div>
                  </div>

                  <Button
                    variant="secondary"
                    onClick={() => addOtherLoanReportHandler()}
                  >
                    Add
                  </Button>

                  {/* newly added other loan report section */}
                  {otherLoanReportsToBeSubmitted.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Loan Type</th>
                              <th>Bank Name</th>
                              <th>Loan Amount</th>
                              <th>EMI</th>
                              <th>Outstanding</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {otherLoanReportsToBeSubmitted.map(
                              (loan, index) => (
                                <tr key={loan.id}>
                                  <td>{index + 1}</td>
                                  <td>{loan.loan_type}</td>
                                  <td>{loan.bank_name}</td>
                                  <td>{loan.loan_amount}</td>
                                  <td>{loan.emi}</td>
                                  <td>{loan.outstanding}</td>
                                  <td
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <Button
                                      onClick={() =>
                                        handleEditOtherLoanReport(index)
                                      }
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleRemoveOtherLoanReport(index)
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Card>
                <br />

                {/* summary section */}
                <SummaryTable
                  loanReportsSummary={loanReportsSummary}
                  creditReportsSummary={creditReportsSummary}
                  downloadReport={downloadReport}
                  lead={lead}
                  userId={userFromContext.user.id}
                  setLoanReportsSummary={setLoanReportsSummary}
                  setCreditReportsSummary={setCreditReportsSummary}
                />
                <br />
              </>
            )}

            {/* status update section */}
            {showStatusUpdateSection && (
              <>
                {/* status update section */}
                <Card className="p-3">
                  <h4>Status</h4>
                  <Grid2
                    style={{
                      display: "flex",
                      width: "auto",
                      padding: "0rem 0.2rem",
                      margin: "0.6rem 0rem",
                    }}
                  >
                    <CommonSelect
                      label="Status"
                      value={selectedStatus}
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                        setDescription("");
                      }}
                      options={statusOptions}
                      helperText="Please update the status"
                      displayEmpty
                      fullWidth
                      id="status-select"
                    />
                  </Grid2>

                  {/* add task section */}
                  {tasksForStatuses.includes(selectedStatus) && (
                    <Grid2
                      style={{
                        display: "flex",
                        width: "auto",
                        padding: "0rem 0.2rem",
                        margin: "0.6rem 0rem",
                        justifyContent: "flex-start",
                      }}
                    >
                      {/* Add Task Form */}
                      <Row className="align-items-center">
                        {/* Task Date */}
                        <Col xs="auto">
                          <Form.Group controlId="taskDate">
                            <Form.Label>Task Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={taskDate}
                              onChange={(e) => setTaskDate(e.target.value)}
                              style={{
                                maxWidth: "10rem",
                                margin: "0rem 0.2rem",
                                marginLeft: "0rem",
                              }}
                            />
                          </Form.Group>
                        </Col>

                        {/* Hour Dropdown */}
                        <Col xs="auto">
                          <Form.Group controlId="hour">
                            <Form.Label>Hour</Form.Label>
                            <Form.Control
                              as="select"
                              value={hour}
                              onChange={(e) => setHour(e.target.value)}
                              style={{
                                minWidth: "6rem",
                                margin: "0rem 0.2rem",
                              }}
                            >
                              <option value="">Select Hour</option>
                              {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* Minute Dropdown */}
                        <Col xs="auto">
                          <Form.Group controlId="minute">
                            <Form.Label>Minute</Form.Label>
                            <Form.Control
                              as="select"
                              value={minute}
                              onChange={(e) => setMinute(e.target.value)}
                              style={{
                                minWidth: "6rem",
                                margin: "0rem 0.2rem",
                              }}
                            >
                              <option value="">Select Minute</option>
                              {Array.from({ length: 60 }, (_, i) => (
                                <option
                                  key={i}
                                  value={i < 10 ? `0${i}` : `${i}`}
                                >
                                  {i < 10 ? `0${i}` : `${i}`}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* AM/PM Dropdown */}
                        <Col xs="auto">
                          <Form.Group controlId="period">
                            <Form.Label>AM/PM</Form.Label>
                            <Form.Control
                              as="select"
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                              style={{
                                minWidth: "6rem",
                                margin: "0rem 0.2rem",
                              }}
                            >
                              <option value="">Select Period</option>
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Grid2>
                  )}

                  {/* note section */}
                  <Row
                    className="align-items-center"
                    style={{ margin: "0.6rem 0rem" }}
                  >
                    <Col xs={12} style={{ padding: "0rem 0.2rem" }}>
                      <Form.Group controlId="note">
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Add Note if any"
                          isInvalid={!!descriptionError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {descriptionError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </>
            )}

            {/* application status section */}
            {showApplicationStatusSection && (
              <>
                {/* application status update section */}
                <Card className="p-3">
                  <h4 style={{ marginBottom: "1rem" }}>Application Status</h4>
                  <Grid2
                    container
                    spacing={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* Status Dropdown */}
                    <Grid2
                      item
                      xs={12}
                      style={{ marginBottom: "1rem", fontWeight: "bold" }}
                    >
                      <CommonSelect
                        label="Status"
                        value={selectedApplicationStatus}
                        onChange={(e) => applicationStatusChangeHandler(e)}
                        options={applicationStatusesForWalkInsPageOptions}
                        // helperText="Please update the status"
                        displayEmpty
                        fullWidth
                        id="status-select"
                      />
                    </Grid2>

                    {/* Rejection Reason Section */}
                    {showRejectionReasonInput && (
                      <Grid2 item xs={12}>
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <Form.Group controlId="rejectionReason">
                              <Form.Label
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                Rejection Reason
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="rejection_reason"
                                style={{ resize: "none" }}
                                value={rejectionReason}
                                placeholder="Provide the reason for rejection"
                                onChange={(e) =>
                                  setRejectionReason(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Grid2>
                    )}
                  </Grid2>
                </Card>
              </>
            )}

            {/* lead ( call ) status section */}
            {showLeadStatusChangeSection && (
              <Card className="p-3">
                <h4 style={{ marginBottom: "1rem" }}>Lead (Call) Status</h4>
                <Grid2
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* Status Dropdown */}
                  <Grid2
                    item
                    xs={12}
                    style={{ marginBottom: "1rem", fontWeight: "bold" }}
                  >
                    <CommonSelect
                      label="Status"
                      value={
                        selectedLeadStatus === "Scheduled For Walk-In"
                          ? "Schedule For Walk-In"
                          : selectedLeadStatus
                      }
                      onChange={(e) => leadStatusChangeHandler(e)}
                      options={
                        fromPage === "verification-1" || location.state.leadBucket === 'Verification 1'
                          ? leadStatusesForVerificationPageOptions.filter(
                              (item) => item.value !== "Scheduled For Walk-In"
                            )
                          : leadStatusesForWalkInPageOptions.filter(
                              (item) => item.value !== "Scheduled For Walk-In"
                            )
                      }
                      // helperText="Please update the status"
                      displayEmpty
                      fullWidth
                      id="status-select"
                    />
                  </Grid2>
                </Grid2>
              </Card>
            )}

            {/* verification status change section */}
            {showVerificationStatusChangeSection && (
              <Card className="p-3">
                <h4 style={{ marginBottom: "1rem" }}>Verification Status</h4>
                <Grid2
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* Status Dropdown */}
                  <Grid2
                    item
                    xs={12}
                    style={{ marginBottom: "1rem", fontWeight: "bold" }}
                  >
                    <CommonSelect
                      label="Status"
                      value={
                        selectedVerificationStatus === "Verification 1"
                          ? "Under Review"
                          : selectedVerificationStatus
                      }
                      onChange={(e) => verificationStatusChangeHandler(e)}
                      options={verificationStatusesForVerificationPageOptions}
                      // helperText="Please update the status"
                      displayEmpty
                      fullWidth
                      id="status-select"
                    />
                  </Grid2>
                </Grid2>
              </Card>
            )}

            {lead.is_rejected && (
              <Card className="p-3 mt-3 pb-0">
                <Table>
                  <thead>
                    <tr>
                      <th>Rejected By</th>
                      <th>Rejected At</th>
                      <th>Rejection Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ color: "red", width: "20%" }}>
                        {
                          users.find((user) => user.id === lead.rejected_by_id)
                            ?.name
                        }
                      </td>
                      <td style={{ color: "red", width: "15%" }}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {moment(lead.rejected_at)
                            .utcOffset(330)
                            .format("DD MMM, YYYY hh:mm:ss A")}
                        </span>
                      </td>
                      <td style={{ color: "red", width: "65%" }}>
                        {lead.rejection_reason}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            )}
            {shouldPageEdit && (
              <Button
                variant="info"
                type="submit"
                className="mt-3"
                // disabled={!shouldPageEdit}
              >
                Submit
              </Button>
            )}
          </Form>
        </div>
      </div>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
      {showScheduleWalkInDialogue && (
        <ScheduleWalkInDialogue
          open={showScheduleWalkInDialogue}
          handleClose={() => setShowScheduleWalkInDialogue(false)}
          lead={lead}
          updateLeadStatus={(id = null, newStatus) => updateLead(newStatus)}
          statusToBeUpdated={selectedLeadStatus}
        />
      )}
      {showConfirmationDialogue && (
        <ConfirmationDialogue
          open={showConfirmationDialogue}
          handleClose={() => setShowConfirmationDialogue(false)}
          payload={payloadForUpdateVerificationStatus}
          updateLeadState={(lead) => updateLeadState(lead)}
          apiName={apiNameForConfirmationDialogue}
          title={titleForConfirmationDialogue}
          setPayloadForUpdateVerificationStatus={
            setPayloadForUpdateVerificationStatus
          }
        />
      )}
    </>
  );
});

export default LeadDetailsPage;
