import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Container,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import { FaTasks, FaCalendarAlt, FaChartBar } from "react-icons/fa";
import axios from "./../../components/axios";
import "./Dashboard.css";
import { useNavigate, Link } from "react-router-dom";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import { useAuth } from "../../context/AuthContext";
import {
  getAllLeads,
  getTotalLeadsCount,
} from "../../services/admin/leads-services";
import moment from "moment";
import { getAllActivities } from "../../services/admin/tasks-services";
import RecentTasks from "../../components/RecentTasks";
import { getAllTasks, updateTaskStatus } from "../../services/tasks-services";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import CommonSnackbar from "../../components/common/CommonSnackbar";
import WalkInsTable from "../../components/WalkInsTable";
import { getWalkIns, getWalkInsCount, updateWalkInStatus } from "../../services/walk-in-services";
import AnalyticsDashboard from "../../components/AnalyticsDashboard";

const Dashboard = () => {
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [leadsToday, setLeadsToday] = useState(0);
  const [walkinsTotal, setWalkinsTotal] = useState(0);
  const [walkinsToday, setWalkinsToday] = useState(0);
  const [walkInLeads, setWalkInLeads] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const [payLoadForWalkInLeadsByUser, setPayLoadForWalkInLeadsByUser] =
    useState({
      verification_status: "Approved for Walk-In",
      page: 1,
      limit: 5,
    });
  const [
    payLoadForRecentActivitiesByUser,
    setPayLoadForRecentActivitiesByUser,
  ] = useState({
    page: 1,
    pageSize: 5,
  });
  const [
    disableShowMoreButtonForWalkInLeads,
    setDisableShowMoreButtonForWalkInLeads,
  ] = useState(false);
  const [
    disableShowMoreButtonForRecentActivities,
    setDisableShowMoreButtonForRecentActivities,
  ] = useState(false);

  // Recent Tasks component states
  const [tasks, setTasks] = useState([]);
  const [payloadForRecentTasks, setPayloadForRecentTasks] = useState({
    page: 1,
    pageSize: Math.floor(window.innerHeight / 40),
  });
  const [
    disableShowMoreButtonForRecentTasks,
    setDisableShowMoreButtonForRecentTasks,
  ] = useState(false);

  // Walk In Table component states
  const [walkIns, setWalkIns] = useState([]);
  const [payloadForWalkIns, setPayloadForWalkIns] = useState({
    page: 1,
    pageSize: Math.floor(window.innerHeight / 40),
  });
  const [disableShowMoreButtonForWalkIns, setDisableShowMoreButtonForWalkIns] =
    useState(false);

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  useEffect(() => {
    setBackdropMessage("Loading...");
    setOpenBackdrop(true);
    const fetchData = async () => {
      if (user?.user.id) {
        payLoadForWalkInLeadsByUser["assigned_to"] = user.user.id;
        payLoadForRecentActivitiesByUser["created_by"] = user.user.id;
        payloadForRecentTasks["created_by"] = user.user.id;
        payloadForWalkIns["created_by"] = user.user.id;
        setUserId(user.user.id);
        try {
          await Promise.all([
            fetchTotalLeads({ assigned_to: user.user.id }),
            fetchLeadsToday({ assigned_to: user.user.id, today: true }),
            // fetchWalkinsToday({
            //   assigned_to: user.user.id,
            //   today: true,
            //   verification_status: "Approved for Walk-In",
            // }),
            // fetchWalkinsTotal({
            //   assigned_to: user.user.id,
            //   verification_status: "Approved for Walk-In",
            // }),
            fetchWalkInsCount({created_by:user.user.id}),
            fetchWalkInLeadsByUser({ ...payLoadForWalkInLeadsByUser }),
            fetchRecentActivitiesByUser({
              ...payLoadForRecentActivitiesByUser,
            }),
            fetchRecentTasks({ ...payloadForRecentTasks }),
            fetchWalkIns({ ...payloadForWalkIns }),
          ]);
          setOpenBackdrop(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setBackdropMessage(error.message);
          setResponseType("FAILED");
          setShouldBackdropCloseOnClickOfOutside(true);
        }
      }
    };
    fetchData();
  }, [user]);

  function updateWalkIn(walk_in_id, new_walk_in_status){
    setWalkIns((prevWalkIns)=>
      prevWalkIns.map((walkIn)=>{
        if(walkIn.id === walk_in_id){
          return {
            ...walkIn,
            walk_in_status: new_walk_in_status,
            is_rescheduled: new_walk_in_status === "Rescheduled" ? true : walkIn.is_rescheduled, // Set is_rescheduled to true if the new status is "Rescheduled"
          };
        }
        return walkIn
      })
    )
  }

  async function updateTaskStatusHandler({ activity_id, task_status }) {
    console.log("Activity ID:", activity_id, "Task Status:", task_status);

    try {
      const updatedTask = { activity_id, task_status };
      const response = await updateTaskStatus(updatedTask);
      if (
        response.data.status === "success" &&
        response.data.statusCode === 200
      ) {
        setSnackbarMessage(response.data.message);
        setSnackbarType("success");
        setOpenSnackbar(true);
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === updatedTask.activity_id
              ? { ...task, task_status: updatedTask.task_status }
              : task
          )
        );
      }
    } catch (error) {
      console.log("Error in handleStatusChange:", error);
      setSnackbarMessage("Failed to update task status!");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  }

  async function updateWalkInStatusHandler({ walk_in_id, walk_in_status }) {
    try {
      const updatedTask = { walk_in_id, walk_in_status };
      const response = await updateWalkInStatus(updatedTask);
      if (
        response.data.status === "success" &&
        response.data.statusCode === 200
      ) {
        setSnackbarMessage(response.data.message);
        setSnackbarType("success");
        setOpenSnackbar(true);
        setWalkIns((prevTasks) =>
          prevTasks.map((walkIn) =>
            walkIn.id === updatedTask.walk_in_id
              ? { ...walkIn, walk_in_status: updatedTask.walk_in_status }
              : walkIn
          )
        );
      }
    } catch (error) {
      console.log("Error in handleStatusChange:", error);
      setSnackbarMessage("Failed to update task status!");
      setSnackbarType("error");
      setOpenSnackbar(true);
    }
  }

  async function showMoreClickHandler(buttonClickedFor) {
    switch (buttonClickedFor) {
      case "WALK-IN-LEADS":
        setPayLoadForWalkInLeadsByUser((prevState) => {
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchWalkInLeadsByUser(updatedState); // pass updated state to API
          return updatedState;
        });
        return;

      case "RECENT-ACTIVITIES":
        setPayLoadForRecentActivitiesByUser((prevState) => {
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchRecentActivitiesByUser(updatedState); // pass updated state to API
          return updatedState;
        });
        return;

      case "tasks":
        setPayloadForRecentTasks((prevState) => {
          console.log("prev acitivity = ", prevState);
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchRecentTasks(updatedState); // pass updated state to API
          return updatedState;
        });
        return;

      case "walk_ins":
        setPayloadForWalkIns((prevState) => {
          const updatedState = { ...prevState, page: prevState.page + 1 };
          fetchWalkIns(updatedState); // pass updated state to API
          return updatedState;
        });

      default:
        console.warn("Unknown buttonClickedFor:", buttonClickedFor);
        return;
    }
  }

  async function fetchLeadsToday(params) {
    try {
      const response = await getTotalLeadsCount(params);
      setLeadsToday(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTotalLeads(params) {
    try {
      const response = await getTotalLeadsCount(params);
      setTotalLeads(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  // async function fetchWalkinsToday(params) {
  //   try {
  //     const response = await getTotalLeadsCount(params);
  //     setWalkinsToday(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // async function fetchWalkinsTotal(params) {
  //   try {
  //     const response = await getTotalLeadsCount(params);
  //     setWalkinsTotal(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function fetchWalkInsCount(params){
    try {
      const response = await getWalkInsCount(params)
      setWalkinsTotal(response.data.data.totalWalkIns)
      setWalkinsToday(response.data.data.todayWalkIns)
    } catch (error) {
      console.log((error));
    }
  }

  async function fetchWalkInLeadsByUser(params) {
    try {
      const response = await getAllLeads(params);
      console.log("walk in leads = ", response.data.data);
      if (response.data.data.length > 0) {
        setWalkInLeads([...walkInLeads, ...response.data.data]);
      } else {
        setDisableShowMoreButtonForWalkInLeads(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRecentActivitiesByUser(params) {
    try {
      const response = await getAllActivities(params);
      console.log(response.data);
      if (response.data.data.length > 0) {
        setRecentActivities([...recentActivities, ...response.data.data]);
      } else {
        setDisableShowMoreButtonForRecentActivities(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRecentTasks(params) {
    try {
      const response = await getAllTasks(params);
      if (
        response.data.pagination.totalPages === 1 ||
        response.data.data.length === 0
      ) {
        setTasks([...tasks, ...response.data.data]);
        setDisableShowMoreButtonForRecentTasks(true);
      } else {
        setTasks([...tasks, ...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchWalkIns(params) {
    try {
      const response = await getWalkIns(params);
      if (
        response.data.pagination.totalPages === 1 ||
        response.data.data.length === 0
      ) {
        setWalkIns([...walkIns, ...response.data.data]);
        setDisableShowMoreButtonForWalkIns(true);
      } else {
        setWalkIns([...walkIns, ...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <EmployeeSidebar />
      <Container fluid className="global-container">
        <div className="container">
          <Row>
            <Col md={12}>
              <div>
                <Card.Body>
                  {/* <h2 className="text-center">Employee Dashboard</h2> */}
                  <Row className="text-center">
                    <Col md={4}>
                      <Link to={`/emp-leads`} className="text-decoration-none">
                        <Card className="metric-card">
                          <Card.Body>
                            <FaTasks size={40} className="metric-icon" />
                            <h3>{totalLeads}</h3>
                            <p>Total Leads</p>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                    <Col md={4}>
                      <Link to={`/emp-leads`} className="text-decoration-none">
                        <Card className="metric-card">
                          <Card.Body>
                            <FaChartBar size={40} className="metric-icon" />
                            <h3>
                              {leadsToday}/{totalLeads}
                            </h3>
                            <p> Leads Today</p>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                    <Col md={4}>
                      <Link
                        to={`/walkins-list`}
                        className="text-decoration-none"
                      >
                        <Card className="metric-card">
                          <Card.Body>
                            <FaCalendarAlt size={40} className="metric-icon" />
                            <h3>
                              {walkinsToday}/{walkinsTotal}
                            </h3>
                            <p>Walk-ins Today</p>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "white",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <AnalyticsDashboard />
          </Row>
          <WalkInsTable
            showMoreClickHandler={(clickedOn) =>
              showMoreClickHandler(clickedOn)
            }
            disableShowMoreButtonForWalkIns={disableShowMoreButtonForWalkIns}
            userId={userId}
            walkIns={walkIns}
            updateTask={(data) => updateWalkInStatusHandler(data)}
            updateWalkIn={(walk_in_id, walk_in_status)=>updateWalkIn(walk_in_id, walk_in_status)}
          />
          <RecentTasks
            tasks={tasks}
            showMoreClickHandler={(clickedOn) =>
              showMoreClickHandler(clickedOn)
            }
            disableShowMoreButtonForRecentTasks={
              disableShowMoreButtonForRecentTasks
            }
            userId={userId}
            updateTask={(data) => updateTaskStatusHandler(data)}
          />
        </div>
      </Container>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
    </>
  );
};

export default Dashboard;
