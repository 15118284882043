import React, { useCallback, useEffect, useState } from "react";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import { Form, Button, Table, Modal, Container } from "react-bootstrap";
import CallIcon from "@mui/icons-material/Call";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllLeads,
  updateVerificationStatus,
} from "../../services/admin/leads-services";
import TablePagination from "@mui/material/TablePagination";
import { debounce, exportLeadsHandler } from "../../utilities/helper-functions";
import {
  activityStatuses,
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  verificationStatuses,
} from "../../constants/AppConstants";
import AddActivityDialogue from "../../components/employee/leads/AddActivityDialogue";
import { getUserByUserId, getUsers } from "../../services/admin/users-services";
import CommonSnackbar from "../../components/common/CommonSnackbar";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import { useAuth } from "../../context/AuthContext";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import ManagerSidebar from "../../components/Sidebar/ManagerSidebar";
import ConfirmationDialogue from "../../components/common/ConfirmationDialogue";
import ScheduleWalkInDialogue from "../../components/ScheduleWalkInDialogue";

const Verification1 = React.memo(function Verification1() {
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const { user, users } = useAuth();
  const [leads, setLeads] = useState([]);
  const [pagination, setPagination] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    // verificationStatus: "Verification 1",
    lead_status: "Verification 1",
  });
  const [isFiltersUpdated, setIsFiltersUpdated] = useState(false);
  const [showActivityDialogue, setShowActivityDialogue] = useState(false);
  const [showScheduleWalkInDialogue, setShowScheduleWalkInDialogue] =
    useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [
    payloadForUpdateVerificationStatus,
    setPayloadForUpdateVerificationStatus,
  ] = useState(null);
  // const [userRole, setUserRole] = useState(ROLE_EMPLOYEE);
  const [employees, setEmployees] = useState([]);
  const [isClickingDropdown, setIsClickingDropdown] = useState(false);

  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const fieldsToExport = ["id", "name", "email","phone","verification_status", "lead_status","status", "createdAt", "updatedAt", "LeadAssignments"];

  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  function handleFilterChange() {}
  useEffect(() => {
    let areFiltersExists = location.state && Object.keys(location?.state).includes('filters')
    let arePaginationExists = location.state && Object.keys(location?.state).includes('pagination')
    if (user?.user.id) {
      if(areFiltersExists || arePaginationExists){
        setFilters((prevFilters) => {
          const updatedFilters = {...prevFilters}
          if (user.user.role === ROLE_EMPLOYEE) {
            updatedFilters.assigned_to = user.user.id;
          } else {
            delete updatedFilters.assigned_to;
          }
          Object.assign(updatedFilters, location.state.filters)
          return updatedFilters
        })
        if (location.state.pagination) {
          setPagination((prevPagination) => ({
            ...prevPagination,
            ...location.state.pagination,
            page: location.state.pagination.page + 1, // Increment page
          }));
        }
        navigate(location.pathname, { replace: true });
      }else{
        setFilters((prevFilters) => {
          const updatedFilters = { ...prevFilters };
          if (user.user.role === ROLE_EMPLOYEE) {
            updatedFilters.assigned_to = user.user.id;
          } else {
            delete updatedFilters.assigned_to;
          }
          return updatedFilters;
        });
      }
      // Set the flag to true after filters are updated
      setIsFiltersUpdated(true);
    }
  }, [user]); // This effect runs when `user` changes

  useEffect(() => {
    // Only fetch leads when filters are updated and the flag is true
    const fetchData = async () => {
      setBackdropMessage("Loading...");
      setOpenBackdrop(true); // Show the backdrop before starting API calls
      try {
        // Replace with your API calls in Promise.all
        await Promise.all([fetchAllLeads(filters, false), fetchEmployees()]);

        setBackdropMessage(""); // Clear the backdrop message
        setOpenBackdrop(false); // Close the backdrop
      } catch (error) {
        console.error("Error fetching data:", error);
        setBackdropMessage(error.message); // Show error in backdrop message
        setResponseType("FAILED"); // Update the response type for errors
        setShouldBackdropCloseOnClickOfOutside(true); // Allow backdrop close on outside click
      }
    };
    if (isFiltersUpdated) {
      fetchData();
      setIsFiltersUpdated(false); // Reset the flag to avoid subsequent API calls
    }
  }, [filters, isFiltersUpdated]);

  function handleMouseDown(e) {
    // Prevent triggering the row double-click event when interacting with the dropdown
    setIsClickingDropdown(true);
    setTimeout(() => {
      setIsClickingDropdown(false);
    }, 2000); // Reset after 1000ms (1 second)
  }

  function handleChangePage(event, newPage) {
    console.log("newPage = ", newPage);
    setPage(newPage);
    fetchAllLeads(
      {
        page: newPage + 1,
        limit: rowsPerPage,
        ...filters,
      },
      false
    );
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    // Fetch data for the first page with new rows per page
    fetchAllLeads(
      {
        page: 1,
        pageSize: newRowsPerPage,
        ...filters,
      },
      false
    );
  };

  const debouncedFetchLeads = useCallback(
    debounce((params) => fetchAllLeads(params, true), 700),
    []
  );

  function handleFilterChange(e) {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    let updatedFilters = null;
    if (name === "verification_status") {
      // if(value !== "Under Review")
      if(value === "Under Review"){
        updatedFilters = { ...filters, [name]: "Verification 1" };
        setFilters({...updatedFilters, verification_status:"Under Review"});
      }else{
        updatedFilters = { ...filters, [name]: value };
        setFilters(updatedFilters);
      }
    } else {
      updatedFilters = { ...filters, [name]: value };
      setFilters(updatedFilters);
    }
    // setFilters((prevFilters)=>({...prevFilters, [e.target.name]:e.target.value}))
    console.log(filters);
    // Fetch leads with updated filters
    debouncedFetchLeads({
      ...updatedFilters,
      page: 1,
      limit: rowsPerPage,
    });
  }

  //   function openActivityDialogueHandler(e, lead) {
  //     e.stopPropagation();
  //     setSelectedLead(lead);
  //     setSelectedStatus({...selectedStatus, status:''})
  //     setShowActivityDialogue(true);
  //   }

  function handleLeadStatusChange(e, selectedLead) {
    e.stopPropagation();
    setSelectedLead(selectedLead);
    console.log("role = ", user.user.role);
    if (user.user.role !== ROLE_EMPLOYEE) {
      setSnackbarMessage("Manager has no access to update lead status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      return;
    } else {
      console.log(
        "target name = ",
        e.target.name,
        " target value = ",
        e.target.value
      );
      console.log(
        "selected leads verification status = ",
        selectedLead.verification_status
      );
      if (
        selectedLead.verification_status !== "Approved for Walk-In" &&
        e.target.value === "Schedule For Walk-In"
      ) {
        setSnackbarMessage("Only approved leads can be scheduled for Walk-In");
        setSnackbarType("error");
        setOpenSnackbar(true);
        setTimeout(() => setOpenSnackbar(false), 2000);
        // } else if (
        //   selectedLead.verification_status === "Verification 1" &&
        //   e.target.value !== "Schedule For Walk-In"
        // ) {
        //   setSelectedStatus(e.target.value);
        //   setShowActivityDialogue(true);
        // }
      } else {
        setSelectedStatus(e.target.value);
        setShowScheduleWalkInDialogue(true);
      }
    }
  }

  async function verificationStatusChangeHandler(e, lead_id) {
    e.stopPropagation();
    console.log("role = ", user.user.role);
    if (user.user.role === ROLE_EMPLOYEE) {
      setSnackbarMessage("No access to update verification status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      return;
    } else {
      let payload = {};
      payload["lead_id"] = lead_id;
      payload["verification_status"] = e.target.value;
      payload["role"] = user.user.role;
      payload["rejected_by_id"] = user.user.id;
      console.log("payload for update Verification status = ", payload);
      setPayloadForUpdateVerificationStatus(payload);
      setShowConfirmationDialogue(true);
    }
  }

  // this function changes updated new verification status of the changedlead
  function updateLeadState(updatedLead) {
    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id === updatedLead.id ? { ...lead, ...updatedLead } : lead
      )
    );
  }

  function updateLeadStatus(id, newStatus) {
    console.log("id = ", id, " new status = ", newStatus);

    try {
      setLeads(
        (prevLeads) =>
          prevLeads
            .map((lead) => {
              if (lead.id === id) {
                let updatedActivities;

                if (lead.Activities && lead.Activities.length > 0) {
                  // If activities exist, update the status of the first activity
                  updatedActivities = lead.Activities.map((activity, index) =>
                    index === 0
                      ? { ...activity, activity_status: newStatus }
                      : activity
                  );
                } else {
                  // If activities array is empty, create the first activity
                  updatedActivities = [
                    {
                      activity_status: newStatus,
                      description: "",
                      docs_collected: false,
                      follow_up: null,
                      lead_status: null,
                      status: null,
                    },
                  ];
                }

                // Return the updated lead with updated activities and lead_status
                return {
                  ...lead,
                  Activities: updatedActivities,
                  lead_status: newStatus,
                };
              }
              return lead;
            })
            .filter((lead) => lead.lead_status === "Verification 1") // Filter leads with the desired lead_status
      );
    } catch (error) {
      alert("Failed to update lead status: " + error.message);
    }
  }

  async function fetchAllLeads(params, withFilters) {
    try {
      setResponseType("");
      if (withFilters) {
        setBackdropMessage("Applying Filters...");
      } else {
        setBackdropMessage("Fetching Leads...");
      }
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
      const response = await getAllLeads(params);
      console.log("response = ", response.data);
      setLeads(response.data.data);
      setPagination({
        ...response.data.pagination,
        page: response.data.pagination.page - 1,
      });
      setRowsPerPage(response.data.pagination.pageSize);
      setResponseType("SUCCESS");
      setBackdropMessage(response.data.message);
      setShouldBackdropCloseOnClickOfOutside(true);
      setOpenBackdrop(false);
    } catch (error) {
      console.log(error);
      setResponseType("FAILED");
      setBackdropMessage("Failed to fetch leads !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  async function fetchEmployees() {
    try {
      const response = await getUsers();
      setEmployees(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  function resetFiltersHandler(){
    let newFilters = {lead_status: "Verification 1",}
    setFilters(newFilters);
    debouncedFetchLeads({
      ...newFilters,
      page: 1,
      limit: rowsPerPage,
    });
  }

  return (
    <>
      {user?.user.role === ROLE_ADMIN ? (
        <AdminSidebar />
      ) : user?.user.role === ROLE_MANAGER ? (
        <ManagerSidebar />
      ) : (
        <EmployeeSidebar />
      )}
      <Container className="global-container">
        <div className="container">
          {/* <h1 className="text-left">{employees?.name}</h1> */}
          {/* filters section */}
          <div className="col-md-12 mb-3">
            <div className="filter-form row">
              <div className="col-md-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Filter by Lead Name"
                  value={filters.name || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by date */}
              {/* <div className="col-md-3">
                <input
                  type="date"
                  name="date"
                  className="form-control"
                  placeholder="Filter by Date"
                  value={filters.date || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div> */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="leadId"
                  className="form-control"
                  placeholder="Filter by ID"
                  value={filters.leadId || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              {/* filter by status */}
              {/* <div className="col-md-3">
                <Form.Control
                  as="select"
                  name="activity_status"
                  className="form-control"
                  value={filters.leadStatus}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Filter by Status</option>
                  <option value="Interested">Interested</option>
                  <option value="Follow Up">Follow Up</option>
                  <option value="Call Back">Call Back</option>
                  <option value="RNR">RNR (Ring No Response)</option>
                  <option value="Switch Off">Switched Off</option>
                  <option value="Busy">Busy</option>
                  <option value="Not Interested">Not Interested</option>
                  <option value="Not Working/Not Reachable">
                    Not Working / Not Reachable
                  </option>
                  <option value="message">Message</option>
                  <option value="email">Email</option>
                  <option value="schedule appointment with manager">
                    Schedule Appointment with Manager
                  </option>
                  <option value="customer walkin">Customer Walk-in</option>
                </Form.Control>
              </div> */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Filter by Phone"
                  value={filters.phone || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>

              <div className="col-md-3">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Filter by Email"
                  value={filters.email || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>
              <p>&nbsp;</p>
              {/* filter by employee name */}
              {user.user.role !== ROLE_EMPLOYEE && (
                <div className="col-md-3">
                  <select
                    name="assigned_to"
                    className="form-control me-2"
                    value={filters.assigned_to || ""}
                    onChange={(e) => handleFilterChange(e)}
                    defaultValue=""
                    style={{ cursor: "pointer" }}
                  >
                    <option value="">Filter by Employee Name</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col-md-3">
                <select
                  name="verification_status"
                  className="form-control me-2"
                  onChange={(e) => handleFilterChange(e)}
                  defaultValue=""
                  value={filters.verification_status || ""}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Select Verification Status</option>
                    {
                      verificationStatuses
                      .map((verificationStatus) => (
                        <option
                          value={verificationStatus.name}
                          key={verificationStatus.id}
                        >
                          {verificationStatus.name}
                        </option>
                      ))
                    }
                </select>
              </div>

              <div className="col-md-3 mb-3 position-relative" style={{placeContent:'center', display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                {
                  user.user.role !== ROLE_EMPLOYEE &&
                    <Button style={{backgroundColor:'green', color:'white'}} onClick={()=>exportLeadsHandler(filters,[], leads, users, fieldsToExport)}>Export Leads</Button>    
                }
                <Button style={{backgroundColor:'red', color:'white', marginLeft:user.user.role !== ROLE_EMPLOYEE && '1rem'}} onClick={()=>resetFiltersHandler()}>Reset Filters</Button>
              </div>
            </div>
          </div>
          <br />

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Lead Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  {user.user.role !== ROLE_EMPLOYEE && <th>Lead Owner</th>}
                  <th>Lead (Call) Status</th>
                  <th>Verification Status</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr
                    onDoubleClick={() => {
                      if (!isClickingDropdown) {
                        navigate(`/lead-details-emp/${lead.id}`,{
                          state:{
                            showLeadStatusChangeSection : user.user.role === ROLE_EMPLOYEE,
                            showVerificationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE,
                            filters,
                            pagination,
                            fromPage: 'verification-1'
                          }
                        });
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{lead?.id}</td>
                    <td>{lead?.name}</td>
                    <td>{lead?.email}</td>
                    <td>{lead?.phone}</td>
                    {/* lead owner column */}
                    {user.user.role !== ROLE_EMPLOYEE && (
                      <td>{lead?.LeadAssignments[0].AssignedTo.name}</td>
                    )}
                    {/* lead (call) status column */}
                    <td>
                      <Form.Control
                        as="select"
                        name="leadStatus"
                        className="form-control"
                        value={lead?.Activities[0]?.activity_status || ""}
                        onChange={(e) => handleLeadStatusChange(e, lead)}
                        onMouseDown={(e) => handleMouseDown(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        {/* {lead?.verification_status !==
                        "Approved for Walk-In" ? (
                          activityStatuses.map((activityStatus) => (
                            <option
                              value={activityStatus.name}
                              key={activityStatus.id}
                            >
                              {activityStatus.name}
                            </option>
                          ))
                        ) : */}
                        {/* ( */}
                        <>
                          <option value="Verification 1">Verification 1</option>
                          <option value="Schedule For Walk-In">
                            Schedule For Walk-In
                          </option>
                        </>
                        {/* // )} */}
                      </Form.Control>
                    </td>

                    {/* Verification status column */}
                    <td>
                      <Form.Control
                        as="select"
                        name="verification_status"
                        className="form-control"
                        value={lead?.verification_status || ""}
                        onChange={(e) =>
                          verificationStatusChangeHandler(e, lead.id)
                        }
                        onMouseDown={(e) => handleMouseDown(e)}
                        style={{ cursor: "pointer" }}
                        disabled={user.user.role === ROLE_EMPLOYEE}
                      >
                        <option value="" disabled>
                          Select Verification Status
                        </option>
                        {verificationStatuses.map((verificationStatus) => (
                          <option
                            value={verificationStatus.name}
                            key={verificationStatus.id}
                          >
                            {verificationStatus.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={pagination.total || 0}
            page={pagination.page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Container>
      {showActivityDialogue && (
        <AddActivityDialogue
          open={showActivityDialogue}
          handleClose={() => setShowActivityDialogue(false)}
          lead={selectedLead}
          updateLeadStatus={(id, newStatus) => updateLeadStatus(id, newStatus)}
          statusToBeUpdated={selectedStatus}
        />
      )}
      {showScheduleWalkInDialogue && (
        <ScheduleWalkInDialogue
          open={showScheduleWalkInDialogue}
          handleClose={() => setShowScheduleWalkInDialogue(false)}
          lead={selectedLead}
          updateLeadStatus={(id, newStatus) => updateLeadStatus(id, newStatus)}
          statusToBeUpdated={selectedStatus}
        />
      )}
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
      {showConfirmationDialogue && (
        <ConfirmationDialogue
          open={showConfirmationDialogue}
          handleClose={() => setShowConfirmationDialogue(false)}
          payload={payloadForUpdateVerificationStatus}
          updateLeadState={(lead) => updateLeadState(lead)}
          apiName={"updateVerificationStatus"}
          title="Update Verification Status"
          setPayloadForUpdateVerificationStatus={
            setPayloadForUpdateVerificationStatus
          }
        />
      )}
    </>
  );
});

export default Verification1;
