import axios from "axios";

export async function scheduleWalkIn(payload){
    return axios.post('/api/walk-ins/schedule-walk-in',{...payload})
}

export async function getWalkIns(params){
    return axios.get('/api/walk-ins/get-walk-ins',{params:params})
}

export async function updateWalkInStatus(payload){
    return axios.post('/api/walk-ins/update-walk-in-status', {...payload})
}

export async function rescheduleWalkIn(payload){
    return axios.post('/api/walk-ins/reschedule-walk-in', {...payload})
}

export async function getWalkInsCount(params){
    return axios.get('/api/walk-ins/get-walk-ins-count', {params:params})
}