import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar.css";
import logo from "./../../assets/samcint_logo_2.png";
import { useAuth } from '../../context/AuthContext';

const AdminSidebar = () => {
  const [error] = useState('');
  // const user = JSON.parse(localStorage.getItem('user'));
  const {user} = useAuth()
  const location = useLocation();

  return (
    <aside className="main-sidebar elevation-4 fixed">
      <div className="sidebar">
        <img className="brand-image" src={logo} alt="Logo" />
        <h2 className="brand-text font-weight-light">
          <i className="nav-icon fas fa-user" style={{ margin: '5px', color: "#6c24248c" }} />
          {user ? user.user.name.split(' ')[0] : error || "Guest"}
        </h2>
        <div className="sidebar-scroll">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" role="menu" data-accordion="false">
              <li className="nav-item">
                <NavLink
                  to="/admin-dashboard"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fas fa-tachometer-alt" style={{ marginRight: "8px" }} />
                  <p style={{ margin: 0 }}>Dashboard</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink
                  to="/lead-list"
                  className={({ isActive }) =>
                    isActive || ['/Leads', '/lead-list', '/lead-details', '/manage-leads'].includes(location.pathname)
                      ? "nav-link active"
                      : "nav-link"
                  }
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-rocket" style={{ marginRight: "8px" }} />
                  <p>Leads</p>
                </NavLink>
              </li>
              <li className="nav-item has-treeview">
                <NavLink
                  to="/verification-1"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-users" style={{ marginRight: "8px" }} />
                  <p>Verificaiton 1</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink
                  to="/walk-ins"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-users" style={{ marginRight: "8px" }} />
                  <p>Walk-Ins</p>
                </NavLink>
              </li>

              <li className="nav-item has-treeview">
                <NavLink
                  to="/employee"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-users" style={{ marginRight: "8px" }} />
                  <p>Employees</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/settings"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-cog" style={{ marginRight: "8px" }} />
                  <p>Settings</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/invalid-leads"
                  className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                  style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                >
                  <i className="nav-icon fa fa-cog" style={{ marginRight: "8px" }} />
                  <p>Invalid Leads</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default AdminSidebar;