import { jwtDecode } from "jwt-decode";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable"; // For table generation in PDF
import * as XLSX from "xlsx";
import { utils, writeFile } from "xlsx";
import montserratRegular from "../fonts/Montserrat-Regular-normal.js";
import montserratBold from "../fonts/Montserrat-Bold-bold.js";
import { getAllLeads } from "../services/admin/leads-services.js";
import { getAllActivities } from "../services/admin/tasks-services.js";
const ExcelJS = require("exceljs");

export function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function getUserDetails() {
  let token = localStorage.getItem("token");
  let user;
  const decodedToken = jwtDecode(token);
  user = decodedToken.user;
  if (!user) {
    return null;
  }
  return user;
}

export function formatDateTime(taskDate, hour, minute, period) {
  try {
    if (taskDate && hour !== undefined && minute !== undefined && period) {
      let adjustedHour = Number(hour); // Ensure hour is a number
      const parsedMinute = Number(minute); // Ensure minute is a number

      if (isNaN(adjustedHour) || isNaN(parsedMinute)) {
        throw new Error("Invalid hour or minute provided");
      }

      if (period === "PM" && adjustedHour !== 12) {
        adjustedHour += 12; // Convert PM hour to 24-hour format, except for 12 PM
      } else if (period === "AM" && adjustedHour === 12) {
        adjustedHour = 0; // Convert 12 AM to 00 (midnight)
      }

      // Ensure minutes are in the correct format (always two digits)
      const formattedMinute = String(parsedMinute).padStart(2, "0");

      // Construct the valid Date string in the format: 'YYYY-MM-DDTHH:mm:ss'
      const dateString = `${taskDate}T${String(adjustedHour).padStart(
        2,
        "0"
      )}:${formattedMinute}:00`;

      console.log("Constructed Date String:", dateString); // Debugging output

      // Create a Date object from the constructed string
      const date = new Date(dateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }

      // Format the Date into the desired format: 'Wed Dec 18 2024 12:00:00 GMT+0530 (India Standard Time)'
      return date.toString();
    } else {
      throw new Error("Missing required parameters");
    }
  } catch (error) {
    console.error("Error formatting date:", error.message);
    throw new Error(error.message);
  }
}

export function parseDateTime(dateString) {
  try {
    // Create a Date object from the provided date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid Date");
    }

    // Extract the taskDate in 'YYYY-MM-DD' format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const taskDate = `${year}-${month}-${day}`;

    // Extract hour and minute
    let hour = date.getHours(); // Get hours in 24-hour format
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM and adjust hour for 12-hour format
    const period = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour -= 12; // Convert to 12-hour format
    } else if (hour === 0) {
      hour = 12; // Convert midnight to 12 AM
    }

    // Return the extracted components
    return {
      taskDate,
      hour: String(hour),
      minute,
      period,
    };
  } catch (error) {
    console.error("Error parsing date:", error.message);
    throw new Error(error.message);
  }
}

export function showFormattedDate(date) {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  const time = date.toLocaleString("en-US", options);

  return `${day}-${month}-${year} ${time}`;
}

export async function downloadReport(
  format,
  loanReportsSummary,
  creditReportsSummary,
  leadName,
  leadId
) {
  console.log("format = ", format);
  console.log("load report summary = ", loanReportsSummary);
  console.log("creditReportsSummary = ", creditReportsSummary);
  console.log("leadName", leadName);
  console.log("lead id = ", leadId);

  if (format === "pdf") {
    const doc = new jsPDF();

    // Register both Regular and Bold fonts
    doc.addFileToVFS("Montserrat-Regular-normal.ttf", montserratRegular); // Add the regular font to VFS
    doc.addFont(
      "Montserrat-Regular-normal.ttf",
      "Montserrat-Regular",
      "normal"
    ); // Register the regular font
    doc.setFont("Montserrat-Regular"); // Set the regular font for the entire document

    doc.addFileToVFS("Montserrat-Bold-bold.ttf", montserratBold); // Add the bold font to VFS
    doc.addFont("Montserrat-Bold-bold.ttf", "Montserrat-Bold", "normal"); // Register the bold font

    // Add Header with LeadId and LeadName (Use Bold font for titles)
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.setFont("Montserrat-Bold"); // Use bold font for the titles
    doc.text(`Lead ID: ${leadId}`, 14, 20);
    doc.text(`Lead Name: ${leadName}`, 180, 20, { align: "right" });

    // Loan Report Summary Title (Use Bold font)
    doc.setFontSize(14);
    doc.text("Loan Reports Summary", 14, 30); // Adjust position for visibility

    // Loan Report Summary Table (Regular font)
    doc.setFont("Montserrat-Regular"); // Switch back to regular font for table content
    doc.autoTable({
      head: [["Loan Type", "Bank Name", "Loan Amount", "EMI", "Outstanding"]],
      body: [
        ...loanReportsSummary.map((loan) => [
          loan.loan_type,
          loan.bank_name,
          Number(loan.loan_amount),
          Number(loan.emi),
          Number(loan.outstanding),
        ]),
        [
          { content: "", colSpan: 1 },
          { content: "Total", colSpan: 1 },
          Number(
            loanReportsSummary.reduce(
              (total, loan) => total + Number(loan.loan_amount),
              0
            )
          ),
          Number(
            loanReportsSummary.reduce(
              (total, loan) => total + Number(loan.emi),
              0
            )
          ),
          Number(
            loanReportsSummary.reduce(
              (total, loan) => total + Number(loan.outstanding),
              0
            )
          ),
        ],
      ],
      theme: "grid",
      styles: { halign: "center", valign: "middle" },
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
      columnStyles: {
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
      },
      startY: 35, // Adjusted startY
      didParseCell: (data) => {
        if (data.row.index === loanReportsSummary.length) {
          // Last row (Total row) - apply bold style
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    // Add spacing before Credit Report Summary
    const loanReportsEndY = doc.lastAutoTable.finalY + 10;

    // Credit Report Summary Title (Use Bold font)
    doc.setFontSize(14);
    doc.setFont("Montserrat-Bold"); // Use Bold font for Credit Report Title
    doc.text("Credit Reports Summary", 14, loanReportsEndY);

    // Credit Report Summary Table (Regular font)
    doc.setFont("Montserrat-Regular"); // Switch back to regular font for table content
    doc.autoTable({
      head: [["Credit Card Name", "Total Outstanding"]],
      body: [
        ...creditReportsSummary.map((credit) => [
          credit.credit_card_name,
          Number(credit.total_outstanding),
        ]),
        [
          { content: "Total", colSpan: 1 },
          Number(
            creditReportsSummary.reduce(
              (total, credit) => total + Number(credit.total_outstanding),
              0
            )
          ),
        ],
      ],
      theme: "grid",
      styles: { halign: "center", valign: "middle" },
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
      columnStyles: {
        1: { halign: "right" },
      },
      startY: loanReportsEndY + 5,
      didParseCell: (data) => {
        if (data.row.index === creditReportsSummary.length) {
          // Last row (Total row) - apply bold style
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    // Save the PDF
    doc.save(`${leadId}_${leadName}_Loan_and_Credit_Reports.pdf`);
  } else if (format === "excel") {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Loan and Credit Report");

    // Header
    worksheet.mergeCells("A1:B1");
    worksheet.getCell("A1").value = `Lead ID: ${leadId}`;
    worksheet.getCell("A1").alignment = {
      horizontal: "left",
      vertical: "middle",
    };

    worksheet.mergeCells("D1:E1");
    worksheet.getCell("D1").value = `Lead Name: ${leadName}`;
    worksheet.getCell("D1").alignment = {
      horizontal: "right",
      vertical: "middle",
    };

    // Loan Reports Section
    worksheet.addRow([]);
    worksheet.addRow(["Loan Reports Summary"]).eachCell((cell) => {
      cell.font = { bold: true };
    });
    const loanSummaryHeader = worksheet.addRow([
      "Loan Type",
      "Bank Name",
      "Loan Amount",
      "EMI",
      "Outstanding",
    ]);

    // Styling Loan Header
    loanSummaryHeader.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0070C0" },
      };
      cell.alignment = { horizontal: "center" };
    });

    // Adding Loan Data
    loanReportsSummary.forEach((item) => {
      worksheet
        .addRow([
          item.loan_type,
          item.bank_name,
          Number(item.loan_amount),
          Number(item.emi),
          Number(item.outstanding),
        ])
        .eachCell((cell, colNumber) => {
          if (colNumber === 1 || colNumber === 2) {
            // Center alignment for loanType and bankName
            cell.alignment = { vertical: "middle", horizontal: "center" };
          } else {
            // Right alignment for numeric columns
            cell.alignment = { vertical: "middle", horizontal: "right" };
          }
        });
    });

    // Adding Loan Totals Row
    const totalLoanAmount = Number(
      loanReportsSummary.reduce((sum, row) => sum + Number(row.loan_amount), 0)
    );
    const totalEMI = Number(
      loanReportsSummary.reduce((sum, row) => sum + Number(row.emi), 0)
    );
    const totalOutstanding = Number(
      loanReportsSummary.reduce((sum, row) => sum + Number(row.outstanding), 0)
    );
    const loanTotalRow = worksheet.addRow([
      "",
      "Total",
      totalLoanAmount,
      totalEMI,
      totalOutstanding,
    ]);

    loanTotalRow.eachCell((cell, colNumber) => {
      if (colNumber === 2) {
        // Center alignment for loanType and bankName
        cell.alignment = { vertical: "middle", horizontal: "center" };
      } else {
        // Right alignment for numeric columns
        cell.alignment = { vertical: "middle", horizontal: "right" };
      }
      cell.font = { bold: true };
    });

    // Credit Reports Section
    worksheet.addRow([]);
    worksheet.addRow(["Credit Reports Summary"]).eachCell((cell) => {
      cell.font = { bold: true };
    });
    const creditSummaryHeader = worksheet.addRow([
      "Credit Card Name",
      "Total Outstanding",
    ]);

    // Styling Credit Header
    creditSummaryHeader.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0070C0" },
      };
      cell.alignment = { horizontal: "center" };
    });

    // Adding Credit Data
    creditReportsSummary.forEach((item) => {
      worksheet
        .addRow([item.credit_card_name, Number(item.total_outstanding)])
        .eachCell((cell, colNumber) => {
          if (colNumber === 1) {
            // Center alignment for loanType and bankName
            cell.alignment = { vertical: "middle", horizontal: "center" };
          } else {
            // Right alignment for numeric columns
            cell.alignment = { vertical: "middle", horizontal: "right" };
          }
        });
    });

    // Adding Credit Totals Row
    const totalCreditOutstanding = Number(
      creditReportsSummary.reduce(
        (sum, row) => sum + Number(row.total_outstanding),
        0
      )
    );
    const creditTotalRow = worksheet.addRow(["Total", totalCreditOutstanding]);

    creditTotalRow.eachCell((cell, colNumber) => {
      if (colNumber === 1) {
        // Center alignment for loanType and bankName
        cell.alignment = { vertical: "middle", horizontal: "center" };
      } else {
        // Right alignment for numeric columns
        cell.alignment = { vertical: "middle", horizontal: "right" };
      }
      cell.font = { bold: true };
    });

    // Adjust Column Widths
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    // Save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${leadId}_${leadName}_Loan_and_Credit_Reports.xlsx`);
  }
}

export async function exportLeadsHandler(
  params,
  selectedLeadIds = [],
  leads = [],
  users = [],
  fieldsToExport = []
) {
  try {
    let leadsToBeExported = [];
    if (selectedLeadIds.length === 0) {
      const response = await getAllLeads({ ...params, isPaginationOff: true });
      leadsToBeExported = response.data.data;
    } else {
      leadsToBeExported = leads.filter((lead) =>
        selectedLeadIds.includes(lead.id)
      );
    }

    const processedLeads = leadsToBeExported.map((lead) => {
      // Convert UTC to IST and format the date
      const formatToISTDate = (utcDate) => {
        if (!utcDate) return ""; // Handle missing or invalid dates
        const date = new Date(utcDate);
        // Adjust to IST
        date.setMinutes(date.getMinutes() + 330); // 330 minutes = 5 hours 30 minutes

        // Extract date components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`; // Return in DD-MM-YYYY format
      };

      const processedLead = {
        ...lead,
        createdAt: formatToISTDate(lead.createdAt),
        updatedAt: formatToISTDate(lead.updatedAt),
      };

      if (lead.LeadAssignments && lead.LeadAssignments.length > 0) {
        // Map assigned_to IDs to names using the users array
        const assignedToNames = lead.LeadAssignments.map((assignment) => {
          const user = users.find((user) => user.id === assignment.assigned_to);
          return user ? user.name : "Unknown";
        });
        processedLead.LeadAssignments = assignedToNames[0]; // Replace with comma-separated names
      }

      return processedLead;
    });

    // Extract only the required fields for export
    const leadsToExport = processedLeads.map((lead) => {
      const exportedLead = {};
      fieldsToExport.forEach((field) => {
        exportedLead[field] = lead[field] || ""; // Ensure undefined fields are empty
      });
      return exportedLead;
    });

    const worksheet = utils.json_to_sheet(leadsToExport);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Leads");
    const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
    const filename = `Leads_${currentDate}.xlsx`;
    writeFile(workbook, filename);
  } catch (error) {
    console.log(error);
  }
}

// Helper function to format UTC dates to IST in DD-MM-YYYY format
const formatToISTDate = (utcDate) => {
  if (!utcDate) return ""; // Handle missing or invalid dates
  const date = new Date(utcDate);
  // Adjust to IST
  date.setMinutes(date.getMinutes() + 330); // 330 minutes = 5 hours 30 minutes

  // Extract date components
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`; // Return in DD-MM-YYYY format
};

export async function exportActivities(params) {
  // Fetch data from the API
  const response = await getAllActivities({ ...params, isPaginationOff: true });
  let data = response.data.data;

  // Transform the data into the required structure
  const transformedData = data.map((item) => {
    const leadAssignment = item.Lead?.LeadAssignments?.[0] || {};
    const assignedTo = leadAssignment.AssignedTo?.name || "";
    const assignedDate = leadAssignment.createdAt
      ? formatToISTDate(leadAssignment.createdAt)
      : "";

    return {
      "Lead Id": item.Lead?.id || "",
      "Lead Name": item.Lead?.name || "",
      "Lead Status": item.Lead?.lead_status || "",
      Phone: item.Lead?.phone || "",
      "Assigned To": assignedTo,
      "Assigned Date": assignedDate,
      "Activity Status": item.activity_status || "",
      "Created On": item.createdAt ? formatToISTDate(item.createdAt) : "",
      Description: item.description || "",
      "Follow Up": item.follow_up || "",
      "Verification Status": item.Lead?.verification_status || "",
    };
  });

  // Create a worksheet from the transformed data
  const worksheet = XLSX.utils.json_to_sheet(transformedData);

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
  const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
  // Export the workbook as an Excel file
  const filename = `Leads_${currentDate}.xlsx`;
  XLSX.writeFile(workbook, filename);
}

export function convertDateFormat(dateStr) {
  // Parse the input date string (DD-MM-YYYY HH:MM AM/PM)
  const dateParts = dateStr.split(' ');
  const [day, month, year] = dateParts[0].split('-');
  const [time, ampm] = dateParts[1].split(' ');

  // Split the time into hours and minutes
  let [hours, minutes] = time.split(':');

  // Convert the hours and handle AM/PM time conversion
  hours = parseInt(hours, 10);
  if (ampm === 'PM' && hours < 12) {
      hours += 12;
  } else if (ampm === 'AM' && hours === 12) {
      hours = 0;
  }

  // Format the output in YYYY-MM-DDTHH:MM format
  const formattedDate = `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${minutes}`;
  return formattedDate;
}