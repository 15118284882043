import React, { useState } from "react";
import { Dialog, CircularProgress, DialogContent, Grid, Box } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme)=>({
    customDialog : {
        backgroundColor:'transparent',
        width:'100vw'
    }
}))

export default function CommonBackdrop(props) {
    const classes = useStyles();
    const responseType = props.type ? props.type : ''
    
    function displayContent(responseType)
    {
        switch (responseType) {
            case 'SUCCESS':
                return <TaskAltIcon style={{color:'green'}} fontSize="large"/>

            case 'FAILED':
                return <ErrorOutlineIcon style={{color:'red'}} fontSize="large"/>
        
            default:
                return <CircularProgress style={{color:'rgb(127, 12, 134)'}} />
        }
    }
    return (
        <div>
            <Dialog
                open={props.open}
                onClick={props.shouldBackdropCloseOnClickOfOutside && props.onClose}
                className={classes.customDialog}
                onClose={props.shouldBackdropCloseOnClickOfOutside && props.onClose}
            >
                <DialogContent>
                    <Box style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                        {   
                            displayContent(responseType)
                        }
                    </Box>
                    <Grid style={{textWrap: 'nowrap'}}>
                        {props.message}
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}