import axios from "axios"

const baseURL = 'http://localhost:3000'

const PublicAxios = axios.create({
    baseURL,
    withCredentials: true
})

PublicAxios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)

const PrivateAxios = axios.create({
    baseURL,
    withCredentials: true
})

PrivateAxios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken")
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

PrivateAxios.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            // handle refresh token logic here
        }
        return Promise.reject(error)
    }
)

export { PublicAxios, PrivateAxios }