import axios from "axios";

export async function deleteInvalidLeads(){
    return axios.delete('/api/invalid-leads/delete-invalid-leads')
}

export async function getAllInvalidLeads(params){
    return axios.get('/api/invalid-leads/get-all-invalid-leads', {params:params})
}

export async function deleteInvalidLeadsByLeadIds(payload){
    return axios.post('/api/invalid-leads/delete-invalid-leads-by-lead-ids', payload)
}

export async function getDistinctInvalidLeadReasons(){
    return axios.get('/api/invalid-leads/get-distinct-invalid-lead-reasons')
}