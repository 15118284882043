import React, { useEffect, useState } from "react";
import { ROLE_EMPLOYEE, ROLE_MANAGER } from "../constants/AppConstants";
import ManagerSidebar from "./Sidebar/ManagerSidebar";
import AdminSidebar from "./Sidebar/AdminSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { updateTaskStatus } from "../services/tasks-services";
import CommonSnackbar from "./common/CommonSnackbar";
import { useAuth } from "../context/AuthContext";

const RecentTasks = React.memo(function RecentTasks({
  tasks,
  showMoreClickHandler,
  disableShowMoreButtonForRecentTasks,
  userId,
  employees = [],
  handleRecentTasksFilterChange,
  updateTask,
}) {
  const {user} = useAuth()
  const navigate = useNavigate();
  console.log("user id = ", userId);
  const [columns, setColumns] = useState([
    "Lead Id",
    "Lead Name",
    "Phone",
    // "Lead Owner",
    "Task",
    "Note",
    "Task Date",
    "Task Status",
  ]);
  
  useEffect(() => {
    if (userId === null) {
      setColumns((prevColumns) => {
        if (!prevColumns.includes("Lead Owner")) {
          const newColumns = [...prevColumns];
          newColumns.splice(3, 0, "Lead Owner"); // Add at the 4th position
          return newColumns;
        }
        return prevColumns; // No changes if already present
      });
    } else {
      setColumns((prevColumns) =>
        prevColumns.filter((column) => column !== "Lead Owner")
      );
    }
  }, [userId]);

  const getStatusDetails = (task) => {
    // Get the current date (only the date part, ignoring time)
    const currentDate = moment().startOf("day");
  
    // Get the follow-up date (only the date part, ignoring time)
    const followUpDate = moment(task.follow_up).startOf("day");
  
    // Calculate T+2 date (add 2 days to current date)
    const twoDaysFromNow = currentDate.clone().add(2, "days");
  
    // Check if the follow-up date is within T+2 days (including today and T+2)
    const isUpcoming =
      followUpDate.isSameOrAfter(currentDate) && followUpDate.isBefore(twoDaysFromNow);
  
    // Check if the follow-up date is beyond T+2 days (but still not overdue)
    const isBeyondTPlus2 =
      followUpDate.isAfter(twoDaysFromNow) && task.task_status !== "Completed";
  
    // Check if the follow-up date is overdue (before today and task_status is "Upcoming")
    const isOverdue =
      followUpDate.isBefore(currentDate) && task.task_status === "Upcoming";
  
    // Determine the task status and style
    if (task.task_status === "Completed") {
      return {
        status: "Completed",
        style: { fontWeight: "bold", color: "green" },
      };
    } else if (isUpcoming || isBeyondTPlus2) {
      // Apply the same blue color to both "Upcoming" and beyond T+2 tasks
      return {
        status: "Upcoming",
        style: { fontWeight: "bold", color: "blue" }, // Blue for Upcoming and beyond T+2
      };
    } else if (isOverdue) {
      return {
        status: "Pending",
        style: { fontWeight: "bold", color: "red" }, // Red for Pending (overdue)
      };
    }
  
    // Default case (if no specific status matches)
    return { status: task.task_status, style: {} };
  };

  function handleStatusChange(activity_id, task_status) {
    if (typeof updateTask === "function") {
      updateTask({ activity_id, task_status });
    } else {
      console.error("updateTask is not a function");
    }
  }

  return (
    <>
      <Row>
        <Col
          md={12}
          style={{
            backgroundColor: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          <Card.Body>
            <Row className="my-2">
              <Col md={6}>
                <h1>Recent Tasks</h1>
              </Col>
              {employees.length > 0 && (
                <>
                  <Col md={3}>
                    {/* <div> */}
                    <select
                      name="created_by"
                      className="form-control me-2"
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Employee Name</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                    </select>
                    {/* </div> */}
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="date"
                      placeholder="Filter by Follow Up Date"
                      name="follow_up"
                      defaultValue=""
                      // value={filters.filter2}
                      onChange={(e) => handleRecentTasksFilterChange(e)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tasks?.map((task, index) => {
                  const { status, style } = getStatusDetails(task);
                  return (
                    <tr
                      key={task.id}
                      onDoubleClick={() =>
                        navigate(`/lead-details-emp/${task.lead_id}`,{
                          state:{
                            showVerificationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && task.Lead.lead_status === "Verification 1",
                            showApplicationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && task.Lead.verification_status === "Scheduled For Walk-In",
                            showLeadStatusChangeSection: user.user.role === ROLE_EMPLOYEE,
                            leadBucket: task.Lead.lead_status === "Verification 1" ? 'Verification 1' : task.Lead.verification_status === "Scheduled For Walk-In" ? "WalkIns" : 'Leads',
                            fromPage: user.user.role === ROLE_EMPLOYEE ? 'admin-dashboard' : 'employee-dashboard'
                          }
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{task.lead_id}</td>
                      <td>{task.Lead.name}</td>
                      <td>{task.Lead.phone}</td>
                      {userId === null && (
                        <td>{task.Lead.LeadAssignments[0].AssignedTo.name}</td>
                      )}
                      <td>{task.activity_status}</td>
                      <td>{task.description}</td>

                      <td>
                        {moment(task.follow_up)
                          .utcOffset(330)
                          .format("DD MMM, YYYY hh:mm A")}
                      </td>
                      <td style={style}>
                        {employees.length <= 0 ? (
                          <select
                            value={task.task_status}
                            onChange={(e) =>
                              handleStatusChange(task.id, e.target.value)
                            }
                            style={{
                              ...style, // Apply the same style (color, boldness)
                              border: "none",
                              backgroundColor: "transparent",
                              outline: "none",
                              fontWeight: "bold",
                              fontSize: "inherit",
                              cursor: "pointer",
                            }}
                          >
                            <option
                              value="Upcoming"
                              style={{ color: "orange", cursor: "pointer" }}
                            >
                              Upcoming
                            </option>
                            <option
                              value="Pending"
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              Pending
                            </option>
                            <option
                              value="Completed"
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Completed
                            </option>
                          </select>
                        ) : (
                          <span style={style}>{status}</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button
              onClick={() => showMoreClickHandler("tasks")}
              disabled={disableShowMoreButtonForRecentTasks}
            >
              Show More...
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </>
  );
});

export default RecentTasks;
