import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Helper to create row data
function createData(srNo, taskDate, taskTime) {
  return { srNo, taskDate, taskTime };
}

// Table row data
const rows = [
  createData(1, "2024-08-01", "10:00 AM"),
  createData(2, "2024-08-02", "11:30 AM"),
  createData(3, "2024-08-03", "02:00 PM"),
  createData(4, "2024-08-04", "04:15 PM"),
  createData(5, "2024-08-05", "06:45 PM"),
];

export default function TaskTable() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="task table">
          {/* Table Head */}
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f0f0f0" }}> {/* Apply background */}
              <TableCell sx={{ fontWeight: "bold" }}>Sr No</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Task Date
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Task Time
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.srNo}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.srNo}
                </TableCell>
                <TableCell align="center">{row.taskDate}</TableCell>
                <TableCell align="center">{row.taskTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
