import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import axios from "./../../components/axios";
import { useNavigate } from "react-router-dom";
import "./../../App.css";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { getLeadById } from "../../services/admin/leads-services";
import {
  getTasksByLeadId,
  updateTaskByTaskId,
} from "../../services/admin/tasks-services";
import { getUserByUserId } from "../../services/admin/users-services";
import CommonSnackbar from "../../components/common/CommonSnackbar";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import {
  getUserDetails,
  showFormattedDate,
} from "../../utilities/helper-functions";
import moment from "moment";

const LeadDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [locationData, setLocationData] = useState(location.state.lead);
  const [filters, setFilters] = useState(location.state.filters)
  const [pagination, setPagination] = useState(location.state.pagination)
  const [assignedEmployee, setAssignedEmployee] = useState(location.state.assignedEmployee)  
  const [user, setUser] = useState(getUserDetails());
  const [lead, setLead] = useState(null);
  const [activities, setActivities] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [activeTab, setActiveTab] = useState("Basic Info");
  const [isEditing, setIsEditing] = useState(false);

  // Initialize state for all fields
  const [editLead, setEditLead] = useState({});

  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  // backdrop states
  const [loading, setLoading] = useState(true); // Initial state is true
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  useEffect(() => {
    setBackdropMessage("Loading...");
    setOpenBackdrop(true);
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchLeadByLeadId(locationData.id),
          fetchTasksByLeadId(locationData.id),
          fetchUserByUserId(user.id),
        ]);
        setOpenBackdrop(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBackdropMessage(error.message);
        setResponseType("FAILED");
        setShouldBackdropCloseOnClickOfOutside(true);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditLead({
      ...editLead,
      [name]: value,
    });
  };

  async function handleTaskStatusChange(taskId, newStatus) {
    // Show an initial message indicating the update process
    setSnackbarType("info");
    setSnackbarMessage("Updating Status...");
    setOpenSnackbar(true);

    try {
      console.log(
        "task status should update",
        "task id = ",
        taskId,
        " new status = ",
        newStatus
      );

      let payload = {
        taskStatus: newStatus,
        userId: employee.id,
        leadId: lead.id,
      };

      const response = await updateTaskByTaskId(payload, taskId);
      console.log("response = ", response);

      if (
        response.data.status === "success" &&
        response.data.statusCode === 200
      ) {
        // Update Snackbar type and message after successful status update
        setSnackbarType("success");
        setSnackbarMessage(response.data.message);

        // Update the activity list with the new status
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === taskId
              ? { ...activity, taskStatus: newStatus }
              : activity
          )
        );
      } else {
        // Handle API failure with an error message
        setSnackbarType("error");
        setSnackbarMessage(response.data.message);
      }
    } catch (error) {
      console.error(
        "Error updating task status:",
        error.response?.data?.message || error.message
      );

      // Handle error with a fallback message
      setSnackbarType("error");
      setSnackbarMessage(error.response?.data?.message || "An error occurred");
    } finally {
      // Ensure Snackbar is opened after updating the type and message
      setOpenSnackbar(true);
    }
  }

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `/lead/${lead.id}/${editLead}`,
        editLead
      );
    } catch (error) {
      console.error("Error updating lead:", error);
    }
    setIsEditing(false);
  };

  const handleNavigate = async () => {
    navigate("/add-task-employee", { state: { lead } });
  };

  async function fetchLeadByLeadId(leadId) {
    try {
      const response = await getLeadById(leadId);
      setLead(response.data.data);
      if (response.data.data) {
        updateEditLeadHandler(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTasksByLeadId(leadId) {
    try {
      const response = await getTasksByLeadId(leadId);
      setActivities(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserByUserId(userId) {
    try {
      const response = await getUserByUserId(userId);
      setEmployee(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  function updateEditLeadHandler(lead) {
    setEditLead({
      name: lead.name || "",
      phone: lead.phone || "",
      email: lead.email || "",
      source: lead.lead_source || "",
      gender: lead.gender || "",
      dob: lead.dob || "",
      company: lead.company || "",
      city: lead.city || "",
      tags: lead.tags || "",
      mailSent: lead.mailSent || "",
      updatedBy: lead.updatedBy || "",
      updatedOn: lead.updatedOn || "",
      comment: lead.comment || "",
      followupUpdatedBy: lead.followupUpdatedBy || "",
      followupUpdatedOn: lead.followupUpdatedOn || "",
      followupComment: lead.followupComment || "",
      tasks: lead.tasks || "",
      note: lead.note || "",
      documents: lead.documents || "",
    });
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "Basic Info":
        return (
          <div className="card-body">
            <h4 className="mb-3">Lead Information</h4>

            <div className="row">
              {[
                "name",
                "phone",
                "email",
                "source",
                "gender",
                "dob",
                "company",
                "city",
                "tags",
              ].map((field) => (
                <div className="col-md-6 mb-3" key={field}>
                  <label className="form-label">
                    <strong>
                      {field.charAt(0).toUpperCase() + field.slice(1)}:
                    </strong>
                  </label>
                  {isEditing ? (
                    <input
                      type={
                        field === "email"
                          ? "email"
                          : field === "dob"
                          ? "date"
                          : "text"
                      }
                      name={field}
                      className="form-control"
                      value={editLead[field]}
                      onChange={handleInputChange}
                      readOnly={field === "phone"}
                    />
                  ) : (
                    <p className="form-control-plaintext">{editLead[field]}</p>
                  )}
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? "Cancel" : "Edit Lead"}
              </button>

              {isEditing && (
                <button className="btn btn-primary btn-sm" onClick={handleSave}>
                  Save Changes
                </button>
              )}
            </div>
          </div>
        );
      case "activity":
        return (
          <div className="card-body">
            {/* {['mailSent', 'updatedBy', 'updatedOn', 'comment'].map((field) => (
                                <p key={field}>
                                    <strong>{field.charAt(0).toUpperCase() + field.slice(1)}:</strong>
                                    {isEditing ? (
                                        field === 'comment' ? (
                                            <textarea
                                                name={field}
                                                value={editLead[field]}
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <input
                                                type={field === 'updatedOn' ? 'date' : 'text'}
                                                name={field}
                                                value={editLead[field]}
                                                onChange={handleInputChange}
                                            />
                                        )
                                    ) : (
                                        <span>{editLead[field]}</span>
                                    )}
                                </p>
                            ))} */}
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* <th>Task ID</th> */}
                  <th>Lead Name</th>
                  <th>Status</th>
                  <th>Task</th>
                  <th>Follow Up</th>
                  <th>Notes</th>
                  <th>Documnents Collected</th>
                  <th>Created By</th>
                  <th>Created On</th>
                  <th>Task Status</th>
                </tr>
              </thead>
              <tbody>
                {activities.length > 0 ? (
                  activities.map((task) => (
                    <tr key={task.id}>
                      {/* <td>{taskItem.id}</td> */}
                      <td>{lead.name}</td>

                      <td>{task.activity_status}</td>
                      <td>{task.actionType}</td>
                      <td>
                        {task.follow_up
                          ? showFormattedDate(new Date(task.follow_up))
                          : "Not Available"}
                      </td>
                      <td>{task.description}</td>
                      <td>{task.docs_collected === true ? "YES" : "NO"}</td>
                      <td>{task.CreatedBy.name}</td>
                      <td>
                        {moment(task.createdAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </td>

                      <td>
                        <Form.Control
                          as="select"
                          value={task.taskStatus}
                          onChange={(e) =>
                            handleTaskStatusChange(task.id, e.target.value)
                          }
                          className="dropdownText"
                        >
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                        </Form.Control>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No tasks found</td>
                  </tr>
                )}
              </tbody>
            </table>

            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleNavigate(lead)}
            >
              {isEditing ? "Cancel" : "Add Activity"}
            </button>
            <br />
            {isEditing && (
              <button className="btn btn-primary btn-sm" onClick={handleSave}>
                Save Changes
              </button>
            )}
          </div>
        );

      // case 'note':
      //     return (
      //         <div className="card-body">
      //             <p>
      //                 {isEditing ? (
      //                     <textarea
      //                         name="note"
      //                         value={editLead.note}
      //                         onChange={handleInputChange}
      //                     />
      //                 ) : (
      //                     <span>{editLead.note}</span>
      //                 )}
      //             </p>
      //             <button className="btn btn-primary btn-sm" onClick={() => setIsEditing(!isEditing)}>
      //                 {isEditing ? 'Cancel' : 'Edit'}
      //             </button>
      //             <br/>
      //             {isEditing && <button className="btn btn-primary btn-sm" onClick={handleSave}>Save Changes</button>}
      //         </div>
      //     );
      // case 'documents':
      //     return (
      //         <div className="card-body">
      //             <p>
      //                 <strong>PDF - CIBIL Bureau:</strong>
      //                 {isEditing ? (
      //                     <input
      //                         type="text"
      //                         className="form-group"
      //                         name="documents"
      //                         value={editLead.documents}
      //                         onChange={handleInputChange}
      //                     />
      //                 ) : (
      //                     <span>{editLead.documents}</span>
      //                 )}
      //             </p>
      //             <button className="btn btn-primary btn-sm" onClick={() => setIsEditing(!isEditing)}>
      //                 {isEditing ? 'Cancel' : 'Edit'}
      //             </button>
      //             <br/>
      //             {isEditing && <button className="btn btn-primary btn-sm" onClick={handleSave}>Save Changes</button>}
      //         </div>
      //     );
      default:
        return null;
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="global-container row">
        <div className="col-sm-12">
          <div style={{display:'flex', alignItems:'center', margin:'0.5rem 0rem', justifyContent:'space-between', width:'max-content'}}>
            <Button onClick={()=>navigate('/lead-list', {state:{filters,pagination,assignedEmployee}})} style={{backgroundColor:'#fd7e14', color:'white'}}>Back</Button>
            <div style={{display:'flex', alignItems:'center', marginLeft:'1rem'}}><h5>{lead?.name || "Lead Details"}</h5></div>
          </div>
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-tabs">
                {["Basic Info", "activity"].map((tab) => (
                  <li key={tab} className="nav-item">
                    <button
                      className={`nav-link ${
                        activeTab === tab ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                      style={{
                        marginRight: "1rem",
                        float: "left",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {renderTabContent()}
          </div>
        </div>
      </div>
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
    </>
  );
};

export default LeadDetail;
