import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Table, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RescheduleConfirmationDialogue from "./RescheduleConfirmationDialogue";
import { useAuth } from "../context/AuthContext";
import { ROLE_EMPLOYEE } from "../constants/AppConstants";

const WalkInsTable = React.memo(function WalkInsTable({
  walkIns,
  showMoreClickHandler,
  disableShowMoreButtonForWalkIns,
  userId,
  employees = [],
  handleWalkInsFilterChange,
  updateTask,
  updateWalkIn,
}) {
  const {user} = useAuth()
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    "Lead Id",
    "Lead Name",
    "Phone",
    "Walk Date Time",
    "Rescheduled Date Time",
    "Walk-In Status",
    "Note",
  ]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  //   reschedule confirmation dialogue states
  const [
    showRescheduleConfirmationDialogue,
    setShowRescheduleConfirmationDialogue,
  ] = useState(false);
  const [selectedWalkInStatus, setSelectedWalkInStatus] = useState(null);
  const [selectedWalkIn, setSelectedWalkIn] = useState(null);

  const getStatusDetails = (walkIn) => {
    // Get the current date (only the date part, ignoring time)
    const currentDate = moment().startOf("day");

    // Determine the relevant date to use (rescheduled or original walk-in date)
    const followUpDate = walkIn.is_rescheduled
      ? moment(walkIn.rescheduled_date_time).startOf("day")
      : moment(walkIn.walk_in_date_time).startOf("day");

    // Calculate T+2 date (add 2 days to current date)
    const twoDaysFromNow = currentDate.clone().add(2, "days");

    // Check if the walk-in date is within T+2 days (including today and T+2)
    const isUpcoming =
      followUpDate.isSameOrAfter(currentDate) &&
      followUpDate.isBefore(twoDaysFromNow);

    // Check if the follow-up date is beyond T+2 days (but still not overdue)
    const isBeyondTPlus2 =
      followUpDate.isAfter(twoDaysFromNow) &&
      walkIn.walk_in_status !== "Completed";

    // Check if the follow-up date is overdue (before today and task_status is "Upcoming")
    const isOverdue =
      followUpDate.isBefore(currentDate) &&
      walkIn.walk_in_status === "Upcoming";

    // Check if the walk-in is rescheduled and past date
    const isRescheduledOverdue =
      walkIn.is_rescheduled &&
      followUpDate.isBefore(currentDate) &&
      walkIn.walk_in_status === "Rescheduled";

    // Determine the task status and style
    if (walkIn.walk_in_status === "Completed") {
      return {
        status: "Completed",
        style: { fontWeight: "bold", color: "green" },
      };
    } else if (isUpcoming || isBeyondTPlus2) {
      // Blue color for "Upcoming" and beyond T+2
      return {
        status: "Upcoming",
        style: { fontWeight: "bold", color: "blue" },
      };
    } else if (isOverdue || isRescheduledOverdue) {
      // Red color for "Pending" (Rescheduled or overdue)
      return {
        status: "Pending",
        style: { fontWeight: "bold", color: "red" },
      };
    }

    // Default case (if no specific status matches)
    return { status: walkIn.walk_in_status, style: {} };
  };

  useEffect(() => {
    if (userId === null) {
      setColumns((prevColumns) => {
        if (!prevColumns.includes("Lead Owner")) {
          const newColumns = [...prevColumns];
          newColumns.splice(3, 0, "Lead Owner"); // Add at the 4th position
          return newColumns;
        }
        return prevColumns; // No changes if already present
      });
    } else {
      setColumns((prevColumns) =>
        prevColumns.filter((column) => column !== "Lead Owner")
      );
    }
  }, [userId]);

  function handleStatusChange(walk_in_id, walk_in_status, lead_name) {
    let payload = { walk_in_id, walk_in_status, lead_name };
    if (typeof updateTask === "function") {
      if (walk_in_status === "Rescheduled") {
        setSelectedWalkInStatus("Reschedule Walk In");
        setSelectedWalkIn({ walk_in_id, walk_in_status, lead_name });
        setShowRescheduleConfirmationDialogue(true);
      } else {
        updateTask(payload);
      }
    } else {
      console.error("updateTask is not a function");
    }
  }

  return (
    <>
      <Row className="my-4">
        <Col
          md={12}
          style={{
            backgroundColor: "white",
            padding: "0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          <Card.Body>
            <Row className="my-2">
              <Col md={6}>
                <h1>Walk-Ins</h1>
              </Col>
              {employees.length > 0 && (
                <>
                  <Col md={3}>
                    {/* <div> */}
                    <select
                      name="created_by"
                      className="form-control me-2"
                      onChange={(e) => handleWalkInsFilterChange(e)}
                      defaultValue=""
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Filter by Employee Name</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                    </select>
                    {/* </div> */}
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="date"
                      placeholder="Filter by Follow Up Date"
                      name="date"
                      defaultValue=""
                      // value={filters.filter2}
                      onChange={(e) => handleWalkInsFilterChange(e)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {walkIns?.map((walkIn, index) => {
                  const { status, style } = getStatusDetails(walkIn);
                  return (
                    <tr
                      key={walkIn.id}
                      onDoubleClick={() =>
                        navigate(`/lead-details-emp/${walkIn.lead_id}`,{
                          state:{
                            showVerificationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && walkIn.lead.lead_status === "Verification 1",
                            showApplicationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE && walkIn.lead.verification_status === "Scheduled For Walk-In",
                            showLeadStatusChangeSection: user.user.role === ROLE_EMPLOYEE,
                            leadBucket: walkIn.lead.lead_status === "Verification 1" ? 'Verification 1' : walkIn.lead.verification_status === "Scheduled For Walk-In" ? "WalkIns" : 'Leads',
                            fromPage: user.user.role === ROLE_EMPLOYEE ? 'admin-dashboard' : 'employee-dashboard'
                          }
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{walkIn.lead_id}</td>
                      <td>{walkIn.lead.name}</td>
                      <td>{walkIn.lead.phone}</td>
                      {userId === null && (
                        <td>
                          {walkIn.lead.LeadAssignments[0]?.AssignedTo?.name}
                        </td>
                      )}
                      <td>
                        {moment(walkIn.walk_in_date_time)
                          .utcOffset(330)
                          .format("DD MMM, YYYY hh:mm A")}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {walkIn.is_rescheduled
                          ? moment(walkIn.rescheduled_date_time)
                              .utcOffset(330)
                              .format("DD MMM, YYYY hh:mm A")
                          : ""}
                      </td>
                      <td style={style}>
                        {employees.length <= 0 ? (
                          <select
                            value={walkIn.walk_in_status || "Upcoming"} // Fallback to "Upcoming" if undefined or invalid
                            onChange={(e) =>
                              handleStatusChange(
                                walkIn.id,
                                e.target.value,
                                walkIn.lead.name
                              )
                            }
                            onClick={(e)=>e.target.value = ""}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                e.target.value = walkIn.walk_in_status || "Upcoming";
                              }
                            }}
                            style={{
                              ...style,
                              border: "none",
                              backgroundColor: "transparent",
                              outline: "none",
                              fontWeight: "bold",
                              fontSize: "inherit",
                              cursor: "pointer",
                            }}
                          >
                            {walkIn.walk_in_status !== "Rescheduled" && (
                              <option
                                value="Upcoming"
                                style={{ color: "orange", cursor: "pointer" }}
                              >
                                Upcoming
                              </option>
                            )}
                            <option
                              value="Rescheduled"
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              Rescheduled
                            </option>
                            <option
                              value="Completed"
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Completed
                            </option>
                          </select>
                        ) : (
                          <span style={style}>{status}</span>
                        )}
                      </td>
                      <td>{walkIn.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button
              onClick={() => showMoreClickHandler("walk_ins")}
              disabled={disableShowMoreButtonForWalkIns}
            >
              Show More...
            </Button>
          </Card.Body>
        </Col>
      </Row>
      {showRescheduleConfirmationDialogue && (
        <RescheduleConfirmationDialogue
          open={showRescheduleConfirmationDialogue}
          handleClose={() => setShowRescheduleConfirmationDialogue(false)}
          walkIn={selectedWalkIn}
          updateLeadStatus={(id, newStatus) => updateWalkIn(id, newStatus)}
          statusToBeUpdated={selectedWalkInStatus}
        />
      )}
    </>
  );
});

export default WalkInsTable;
