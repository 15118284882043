import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "./../../components/axios";
import "./LeadList.css";
import "./../../App.css";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import {
  assignLeads,
  getAllDistinctLeadSources,
  getAllLeads,
  uploadLeadsInBulk,
} from "../../services/admin/leads-services";
import TablePagination from "@mui/material/TablePagination";
import { debounce, exportLeadsHandler } from "../../utilities/helper-functions";
import { getUsers } from "../../services/admin/users-services";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import { useAuth } from "../../context/AuthContext";
import ManagerSidebar from "../../components/Sidebar/ManagerSidebar";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import { ROLE_ADMIN, ROLE_MANAGER } from "../../constants/AppConstants";
import ConfirmationDialogue from "../../components/common/ConfirmationDialogue";
import DateTimeRangeFilter from "../../components/common/DateTimeRangeFilter";

const LeadList = () => {
  const location = useLocation()
  const { user: userFromContext, users } = useAuth();
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [pagination, setPagination] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [filters, setFilters] = useState({});
  const [assignedEmployee, setAssignedEmployee] = useState(null);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [user, setUser] = useState(null);
  const [updatePage, setUpdatePage] = useState(false);
  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  const [progress, setProgress] = useState(0);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  // lead source states
  const [leadSources, setLeadSources] = useState([]);
  const [areAllLeadsSelected, setAreAllLeadsSelected] = useState(false);
  const [assigned_to, setAssignedTo] = useState(null)

  // confirmation dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogPayload, setDialogPayload] = useState({});
  const [dialogTitle, setDialogTitle] = useState("");

  const fieldsToExport = ["id", "name", "email","phone","lead_source","verification_status", "lead_status", "application_status","status", "createdAt", "updatedAt", "LeadAssignments"];

  useEffect(() => {
    fetchDistinctLeadSources();
  }, []);

  useEffect(() => {
    console.log("leads updated");
  }, [leads]);

  useEffect(() => {
    getUserDetails();
    if(location.state){
      fetchLeads({...location.state.pagination, ...location.state.filters, page: location.state.pagination.page + 1})
      setFilters({...location.state.filters})
      if (location.state.filters && Object.keys(location.state.filters).includes("assigned_to")) {
        setAssignedTo(location.state.filters.assigned_to); // Set assigned_to if present
      } else {
        setAssignedTo(""); // Reset if not present
      }
      navigate(location.pathname, { replace: true });
    }else{
      fetchLeads({ page: 1 });
    }
    fetchEmployees();
  }, [updatePage]);

  useEffect(() => {
    console.log("pagination", pagination);
    console.log("employees = ", employees);
  }, [pagination, employees]);

  useEffect(() => {
    console.log("assigned empoloyee = ", assignedEmployee);
    console.log("selected lead ids = ", selectedLeadIds);
  }, [assignedEmployee, selectedLeadIds]);

  function handleChangePage(event, newPage) {
    console.log("newPage = ", newPage);
    setPage(newPage);
    setSelectedLeadIds([])
    setAreAllLeadsSelected(false)
    fetchLeads({ page: newPage + 1, pageSize: rowsPerPage, ...filters });
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    // Fetch data for the first page with new rows per page
    fetchLeads({ page: 1, pageSize: newRowsPerPage, ...filters });
  };

  function handleFilterChange(e) {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    const updatedFilters = { ...filters, [name]: value };
    if (name === "assigned_to") {
      setAssignedTo(value);
    }
    setFilters(updatedFilters);
    // setFilters((prevFilters)=>({...prevFilters, [e.target.name]:e.target.value}))
    setSelectedLeadIds([])
    setAreAllLeadsSelected(false)
    console.log(filters);
    // Fetch leads with updated filters
    debouncedFetchLeads({ ...updatedFilters, page: 1, pageSize: rowsPerPage });
  }

  // Debounced fetch function
  const debouncedFetchLeads = useCallback(
    debounce((params) => fetchLeads(params), 700),
    []
  );

  function getAssigneeEmployeeName(employees, LeadAssignment) {
    // Assuming LeadAssignment contains a `assignedToUserId`
    let employee = employees.find(
      (employee) => employee.id === LeadAssignment.assignedToUserId
    );
    return employee ? employee.name : "Not Assigned"; // Return employee's name or default message
  }

  const handleCheckboxChange = (id) => {
    let updatedIds;
    if (selectedLeadIds.includes(id)) {
      updatedIds = selectedLeadIds.filter((leadId) => leadId !== id);
      setAreAllLeadsSelected(false)
    } else {
      updatedIds = [...selectedLeadIds, id];
      if (updatedIds.length === leads.length) {
        setAreAllLeadsSelected(true)
      }
    }
    setSelectedLeadIds(updatedIds);
  };

  async function assignLeadsToEmployee() {
    setBackdropMessage("Assigning leads...");
    setResponseType("");
    setOpenBackdrop(true)
    try {
      if (selectedLeadIds.length === 0) {
        setResponseType("FAILED");
        setBackdropMessage("Leads not selected");
        throw new Error("Leads not selected");
      }

      const reassignedLeads = [];
      const freshLeads = [];
      const filteredLeadIds = selectedLeadIds.filter((leadId) => {
        const lead = leads.find((lead) => lead.id === leadId);
        if (
          lead &&
          lead.LeadAssignments[0]?.assigned_to === assignedEmployee.id
        ) {
          return false;
        }
        if (lead?.LeadAssignments[0]) {
          reassignedLeads.push(lead);
        } else {
          freshLeads.push(lead);
        }
        return true;
      });

      if (filteredLeadIds.length === 0) {
        setResponseType("FAILED");
        setBackdropMessage(
          "All selected leads are already assigned to the existing employee."
        );
        throw new Error("All selected leads are already assigned to the existing employee.");
      }

      const reassignedCount = reassignedLeads.length;
      const freshCount = freshLeads.length;
      let promptMessage = "";

      if (reassignedCount > 0 && freshCount > 0) {
        promptMessage = `Lead Assignment Confirmation - You are about to reassign ${reassignedCount} lead(s) and assign ${freshCount} fresh lead(s) to ${assignedEmployee.name}.`;
      } else if (reassignedCount > 0) {
        promptMessage = `Lead Assignment Confirmation - You are about to reassign ${reassignedCount} lead(s) to ${assignedEmployee.name}.`;
      } else if (freshCount > 0) {
        promptMessage = `Lead Assignment Confirmation - You are about to assign ${freshCount} fresh lead(s) to ${assignedEmployee.name}.`;
      }

      // Open the confirmation dialog
      setDialogTitle(promptMessage);
      setDialogPayload({
        leadIds: filteredLeadIds,
        assignedTo: assignedEmployee.id,
        assignedBy: user.id,
      });
      setDialogOpen(true);
    } catch (error) {
      setBackdropMessage(error.message)
      setShouldBackdropCloseOnClickOfOutside(true)
    }
  }

  async function handleDialogSubmit(payload) {
    try {
      setDialogOpen(false)
      const response = await assignLeads(payload);
      const assignedToName = response.data.data.assignedTo?.name;

      setResponseType("SUCCESS");
      setBackdropMessage(`Leads successfully assigned to ${assignedEmployee.name}`);
      setShouldBackdropCloseOnClickOfOutside(true)
      setLeads((prevLeads) =>
        prevLeads.map((lead) => {
          if (payload.leadIds.includes(lead.id)) {
            return {
              ...lead,
              assignedToName,
              LeadAssignments: Array.isArray(lead.LeadAssignments) && lead.LeadAssignments.length > 0
                ? lead.LeadAssignments.map((assignment) => ({
                    ...assignment,
                    assigned_to: assignedEmployee.id,
                  }))
                : [
                    {
                      assigned_to: assignedEmployee.id,
                    },
                  ],
              updatedAt: new Date()
            };
          }
          return lead;
        })
      );         
      setSelectedLeadIds([]);
    } catch (error) {
      console.error(error);
      setBackdropMessage("Failed to assign leads");
      setResponseType("FAILED");
      setShouldBackdropCloseOnClickOfOutside(true)
    } finally {
      setDialogOpen(false);
    }
  }

  async function handleFileUpload(e) {
    setResponseType("");
    setBackdropMessage("Uploading leads to the database...");
    setShouldBackdropCloseOnClickOfOutside(false);
    setOpenBackdrop(true);
  
    const fileInput = e.target;
    const file = fileInput.files[0];
    const reader = new FileReader();
  
    const validateHeaders = (headerRow) => {
      const requiredFields = {
        name: ["name", "lead name", "customer name", "full name", "Full Name", "full_name"],
        phone: ["mobile", "mobile number", "phone", "phone number", "mob no", "mob. no.", "phone_number", "mobile_number", "Mobile"],
        source: ["source", "lead source", "lead-source", "LEAD SOURCE", "Source"],
      };
  
      const missingFields = Object.keys(requiredFields).filter(
        (field) =>
          !headerRow.some((header) =>
            requiredFields[field].some((name) => header.trim().toLowerCase().includes(name.toLowerCase()))
          )
      );
  
      if (missingFields.length > 0) {
        throw new Error(
          `The uploaded sheet is missing the required fields : ${missingFields.join(" , ")}.`
        );
      }
    };
  
    const normalizeField = (field, possibleNames) =>
      Object.keys(field).find((key) =>
        possibleNames.some((name) =>
          key.trim().toLowerCase().includes(name.toLowerCase())
        )
      );
  
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Get raw data as rows
  
        if (!jsonData || jsonData.length === 0) {
          throw new Error("The uploaded sheet is empty.");
        }
  
        // Validate headers
        const headerRow = jsonData[0].map((col) => col.trim().toLowerCase());
        validateHeaders(headerRow);
  
        // Process data
        const jsonLeads = XLSX.utils.sheet_to_json(worksheet);
        const importedLeads = jsonLeads.map((item) => ({
          name:
            item[
              normalizeField(item, [
                "name",
                "lead name",
                "customer name",
                "full name",
                "Full Name",
                "full_name",
              ])
            ] || null,
          email:
            item[
              normalizeField(item, [
                "email",
                "email address",
                "e-mail",
                "Email",
              ])
            ] || null,
          phone:
            item[
              normalizeField(item, [
                "mobile",
                "mobile number",
                "phone",
                "phone number",
                "mob no",
                "mob. no.",
                "phone_number",
                "mobile_number",
                "Mobile",
              ])
            ] || null,
          lead_source:
            item[
              normalizeField(item, [
                "source",
                "lead source",
                "lead-source",
                "LEAD SOURCE",
                "Source",
              ])
            ] || null,
        }));

        const batchSize = 200;
        for (let i = 0; i < importedLeads.length; i += batchSize) {
          const batch = importedLeads.slice(i, i + batchSize);
          try {
            const response = await uploadLeadsInBulk(batch);
            console.log("Leads imported successfully:", response.data);
  
            // Update progress
            const newProgress = Math.round(
              ((i + batchSize) / importedLeads.length) * 100
            );
            setProgress(newProgress);
            setBackdropMessage(`Uploading leads... ${newProgress}%`);
          } catch (err) {
            console.error("Error importing leads batch:", err);
            const newProgress = Math.round(
              ((i + batchSize) / importedLeads.length) * 100
            );
            setBackdropMessage(
              `Error importing some batches. Continuing... ${newProgress}%`
            );
          }
        }
  
        setProgress(100);
        setResponseType("SUCCESS");
        setBackdropMessage("Leads uploaded successfully!");
        setShouldBackdropCloseOnClickOfOutside(true);
        setTimeout(() => {
          setUpdatePage(!updatePage);
        }, 1500);
      } catch (err) {
        console.error("Error processing the file:", err);
        setResponseType("FAILED");
        setBackdropMessage(err.message);
        setShouldBackdropCloseOnClickOfOutside(true);
      } finally {
        fileInput.value = "";
      }
    };
  
    reader.readAsArrayBuffer(file);
  }

  async function fetchLeads(params) {
    try {
      setBackdropMessage("Fetching Leads ...");
      setResponseType("");
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
      const response = await getAllLeads(params);
      console.log("response = ", response.data);
      const leads = response.data.data;

      setLeads(
        leads.map((lead) => {
          const assignedToName =
            lead.LeadAssignments?.[0]?.AssignedTo?.name || null;
          return { ...lead, assignedToName };
        })
      );
      setPagination({
        ...response.data.pagination,
        page: response.data.pagination.page - 1,
      });
      setRowsPerPage(response.data.pagination.pageSize);
      setOpenBackdrop(false);
      setBackdropMessage("");
      // setResponseType('SUCCESS')
      // setBackdropMessage(response.data.message)
      // setShouldBackdropCloseOnClickOfOutside(true)
    } catch (error) {
      console.log(error.message);
      setBackdropMessage(error.message);
      setResponseType("FAILED");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  async function fetchEmployees() {
    try {
      const response = await getUsers();
      setEmployees(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  function getUserDetails() {
    let token = localStorage.getItem("token");
    let user;
    const decodedToken = jwtDecode(token);
    user = decodedToken.user;
    if (user) {
      setUser(user);
    }
  }

  async function fetchDistinctLeadSources() {
    try {
      const response = await getAllDistinctLeadSources();
      setLeadSources([...response.data.data]);
    } catch (error) {
      console.log(error);
    }
  }

  function selectAllLeads() {
    const updatedIds = leads.map((lead) => lead.id); // Map once
    if (areAllLeadsSelected) {
      setSelectedLeadIds([]); // Unselect all
      setAreAllLeadsSelected(false);
    } else {
      setSelectedLeadIds(updatedIds); // Select all
      setAreAllLeadsSelected(true);
    }
  }

  function handleDateRangeChange(startDateTime,endDateTime,filterName,shouldRemoveFilter){
    console.log("Selected Range:", startDateTime, endDateTime, filterName);
    let updatedFilters = {...filters}
    if(filterName){
      if(shouldRemoveFilter){
        delete updatedFilters[filterName]
      }else{
        updatedFilters = {...filters, [filterName]:`${startDateTime},${endDateTime}`}
      }
    }
    setFilters(updatedFilters)
    setSelectedLeadIds([])
    setAreAllLeadsSelected(false)
    debouncedFetchLeads({...updatedFilters, page:1, pageSize:rowsPerPage})
  }

  function resetFiltersHandler(){
    let newFilters = {}
    if(Object.keys(filters).includes("assigned_to")){
      setAssignedTo(null)
    }
    if(Object.keys(filters).includes("lead_source")){
      delete filters["lead_source"]
    }
    setAssignedEmployee(null)
    setFilters(newFilters)
    debouncedFetchLeads({...newFilters, page: 1, pageSize:rowsPerPage})
  }

  return (
    <>
      {userFromContext?.user.role === ROLE_ADMIN ? (
        <AdminSidebar />
      ) : userFromContext?.user.role === ROLE_MANAGER ? (
        <ManagerSidebar />
      ) : (
        <EmployeeSidebar />
      )}
      <div className="global-container">
        <div className="container">
          <h1 className="text-left">Lead List</h1>
          <div className="row mb-3">
            {/* Filters Section */}
            <div className="col-md-12 mb-3">
              <div className="filter-form row">

                {/* filter by lead id */}
                <div className="col-md-2">
                  <input
                    type="text"
                    name="leadId"
                    className="form-control"
                    placeholder="Filter by ID"
                    value={filters.leadId || ""}
                    onChange={(e) => handleFilterChange(e)}
                  />
                </div>

                {/* filter by phone */}
                <div className="col-md-2">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Filter by Phone"
                    value={filters.phone || ""}
                    onChange={(e) => handleFilterChange(e)}
                  />
                </div>

                {/* filter by lead name */}
                <div className="col-md-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Filter by Lead Name"
                    value={filters.name || ""}
                    onChange={handleFilterChange}
                  />
                </div>

                {/* filter by lead status */}
                <div className="col-md-2">
                  <Form.Control
                    as="select"
                    name="lead_status"
                    className="form-control"
                    value={filters.lead_status || ""}
                    onChange={(e) => handleFilterChange(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <option value="">Filter by Status</option>
                    <option value="Not Contacted">Not Contacted</option>
                    <option value="Interested">Interested</option>
                    <option value="Follow Up">Follow Up</option>
                    <option value="Call Back">Call Back</option>
                    <option value="RNR ( Ring No Response )">RNR (Ring No Response)</option>
                    <option value="Switched Off">Switched Off</option>
                    <option value="Busy">Busy</option>
                    <option value="Not Interested">Not Interested</option>
                    <option value="Not Working / Not Reachable">
                      Not Working / Not Reachable
                    </option>
                    <option value="Not Possible">Not Possible</option>
                    <option value="Message">Message</option>
                    <option value="email">Email</option>
                    <option value="Scheduled For Walk-In">
                      Schedule Appointment with Manager
                    </option>
                    <option value="Verification 1">Verification-1</option>
                  </Form.Control>
                </div>

                {/* filter by employee */}
                <div className="col-md-3">
                  <select
                    name="assigned_to"
                    className="form-control me-2"
                    onChange={(e) => handleFilterChange(e)}
                    defaultValue=""
                    style={{ cursor: "pointer" }}
                    value={assigned_to || ""}
                  >
                    <option value="">Filter by Employee Name</option>
                    <option value="not_assigned">Not Assigned</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>

                <p style={{height:'0.5rem'}}>&nbsp;</p>

                {/* filter by imported on */}
                <div className="col-md-3" style={{position:'relative'}}>
                  <label htmlFor="importedOn" style={{ fontSize: "small", fontWeight: "bold" }}>Imported On</label>
                  {/* <input
                    type="date"
                    name="importedOn"
                    className="form-control"
                    placeholder="Filter by Date"
                    value={filters.importedOn || ""}
                    onChange={(e) => handleFilterChange(e)}
                  /> */}
                  <DateTimeRangeFilter onDateRangeChange={handleDateRangeChange} name="importedOn" filters={filters}/>
                </div>

                {/* filter by assigned date */}
                <div className="col-md-3" style={{position:'relative'}}>
                  <label htmlFor="assigned_on" style={{ fontSize: "small", fontWeight: "bold" }}>Assigned On</label>
                  <DateTimeRangeFilter onDateRangeChange={handleDateRangeChange} name="assigned_on" filters={filters}/>
                </div>

                {/* filter by last updated date */}
                <div className="col-md-3" style={{position:'relative'}}>
                  <label htmlFor="last_updated" style={{ fontSize: "small", fontWeight: "bold" }}>Last Updated</label>
                  <DateTimeRangeFilter onDateRangeChange={handleDateRangeChange} name="last_updated" filters={filters}/>
                </div>

                {/* filter by email */}
                {/* <div className="col-md-3">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Filter by Email"
                    value={filters.email}
                    onChange={(e) => handleFilterChange(e)}
                  />
                </div> */}

                {/* filter by lead source */}
                <div className="col-md-3">
                  <label htmlFor="importedOn" style={{ fontSize: "small", fontWeight: "bold" }}></label>
                  <Form.Control
                    list="leadSourcesList"
                    name="lead_source"
                    className="form-control"
                    value={filters.lead_source || ""}
                    onChange={(e) => {
                      handleFilterChange(e); // Update the selected/typed value
                    }}
                    placeholder="Enter or select Lead Source"
                    style={{ cursor: "pointer" }}
                  />
                  <datalist id="leadSourcesList">
                    {leadSources.map((leadSource, index) => (
                      <option value={leadSource} key={index}>
                        {leadSource}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            </div>

            {/* Bulk Actions Section */}
            <div className="col-md-4 mb-3">
              <div className="d-flex align-items-center">
                <select
                  className="form-control me-2"
                  onChange={(e) => {
                    const selectedEmployeeId = e.target.value;
                    const selectedEmployee = employees.find(
                      (employee) => employee.id === parseInt(selectedEmployeeId)
                    );
                    setAssignedEmployee(selectedEmployee);
                  }}
                  // defaultValue=""
                  value={assignedEmployee?.id || ""}
                >
                  <option value="">Assign to</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>

                {/* assign button */}
                {assignedEmployee && (
                  <div style={{ minWidth: "fit-content" }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm mx-2"
                      onClick={() => assignLeadsToEmployee()}
                    >
                      {`Assign To ${assignedEmployee.name}`}
                    </button>
                  </div>
                )}

                {/* revert button */}
                {/* <button
                  className="btn btn-primary btn-sm"
                  // onClick={handleRevertAssignments}
                  // disabled={selectedLeads.length === 0}
                >
                  Revert
                </button> */}
              </div>
            </div>
            {/* File Upload Section */}
            {userFromContext?.user.role === ROLE_ADMIN && (
              <div className="col-md-4 mb-3 position-relative">
                <input
                  type="file"
                  className="form-control"
                  id="file-upload"
                  accept=".xlsx, .xls"
                  onChange={(e) => handleFileUpload(e)}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    zIndex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                />
                <label
                  htmlFor="file-upload"
                  className="form-control-file-placeholder d-flex justify-content-center align-items-center"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 0,
                    width: "100%",
                    height: "100%",
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    cursor: "pointer",
                    textAlign: "center",
                    lineHeight: "2.5rem",
                  }}
                >
                  Upload Leads
                </label>
              </div>
            )}
            <div className="col-md-4 mb-3 position-relative" style={{placeContent:'center', display:'flex', justifyContent:'flex-start'}}>
              <Button style={{backgroundColor:'green', color:'white'}} onClick={()=>exportLeadsHandler(filters, selectedLeadIds, leads, users, fieldsToExport)}>Export Leads</Button>
              <Button style={{backgroundColor:'red', color:'white', marginLeft:'1rem'}} onClick={()=>resetFiltersHandler()}>Reset Filters</Button>
            </div>
          </div>
          {/* Table Section */}
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={areAllLeadsSelected}
                      onChange={() => selectAllLeads()}
                      style={{cursor:'pointer'}}
                    />
                  </th>
                  <th>Lead Id.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Lead Source</th>
                  {/* <th>Lead Owner</th> */}
                  <th>Imported On</th>
                  <th>Updated At</th>
                  <th>Assigned To</th>
                  <th>Assigned Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {leads?.map((lead) => (
                  <tr
                    key={lead.id}
                    style={{ cursor: "pointer" }}
                    onDoubleClick={() =>
                      navigate("/lead-details", { state: { lead, filters, pagination, assignedEmployee } })
                    }
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedLeadIds.includes(lead.id)}
                        onChange={() => handleCheckboxChange(lead.id)}
                        style={{cursor:'pointer'}}
                      />
                    </td>
                    <td>{lead.id}</td>
                    <td>{lead.name}</td>
                    <td>{lead.email}</td>
                    <td>{lead.phone}</td>
                    <td>{lead.lead_status || "Not Contacted"}</td>
                    <td>
                      {lead.lead_source ? lead.lead_source : "Not Available"}
                    </td>
                    {/* <td>{lead.leadOwner}</td> */}
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.createdAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.createdAt)
                          .utcOffset(330)
                          .format("hh:mm:ss A")}
                      </span>
                    </td>
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.updatedAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(lead.updatedAt)
                          .utcOffset(330)
                          .format("hh:mm:ss A")}
                      </span>
                    </td>
                    <td>
                      {lead.assignedToName || "Not Assigned"}
                      {/* {lead?.LeadAssignments?.length === 0
                        ? lead?.assignedTo === "N/A"
                          ? "Not Assigned"
                          : lead.assignedTo
                        : getAssigneeEmployeeName(
                            employees,
                            lead?.LeadAssignments[0]
                          )} */}
                    </td>
                    <td>
                      {
                        lead.LeadAssignments && lead.LeadAssignments.length > 0 ?
                        <>
                          <span style={{ display: "flex", alignItems: "center" }}>
                            {moment(lead?.LeadAssignments[0]?.updatedAt)
                              .utcOffset(330)
                              .format("DD MMM, YYYY")}
                          </span>
                          <span style={{ display: "flex", alignItems: "center" }}>
                            {moment(lead?.LeadAssignments[0]?.updatedAt)
                              .utcOffset(330)
                              .format("hh:mm:ss A")}
                          </span>
                        </> :
                          "Not Assigned"
                      }                      
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => alert("Delete Feature Not Available")}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <TablePagination
            component="div"
            count={pagination.total || 0}
            page={pagination.page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
      {
        dialogOpen &&
        <ConfirmationDialogue
        open={dialogOpen}
        handleClose={() => {setDialogOpen(false); backdropCloseHandler()}}
        payload={dialogPayload}
        updateLeadState={handleDialogSubmit}
        apiName="assignLeadsToEmployee"
        title={dialogTitle}
      />
      }
    </>
  );
};

export default LeadList;
