import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ allowedRoles, children }) => {
  let token = localStorage.getItem("token");
  let user = null;

  // Check if a token is available
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      user = decodedToken.user;
    } catch (error) {
      console.error("Invalid token:", error);
      // If token decoding fails, redirect to login
      return <Navigate to="/login" />;
    }
  }

  // If there's no user or user role doesn't match allowed roles, redirect to login
  if (!user || !allowedRoles.includes(user?.role)) {
    return <Navigate to="/login" />;
  }

  // If allowed role is found, render the Outlet (child routes)
  return children;
};

export default PrivateRoute;