import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { valHooks } from "jquery";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Col, Row, Form } from "react-bootstrap";
import CommonSelect from "./common/CommonSelect";
import { formatDateTime, getUserDetails } from "../utilities/helper-functions";
import { addActivity } from "../services/employee/tasks-services";
import { rescheduleWalkIn, scheduleWalkIn } from "../services/walk-in-services";

// Create custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    maxWidth: "none", // Removes maxWidth
    maxHeight: "none", // Removes maxHeight
  },
  dialogContent: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  dialogActions: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  secondaryButton: {
    border: "1px solid black",
    color: "black",
    "&:hover": {
      backgroundColor: "gray",
      color: "white",
    },
  },
  primaryButton: {
    border: "1px solid black",
    color: "white",
    backgroundColor: "#fd7e14",
    "&:hover": {
      backgroundColor: "#fd7e14",
      color: "white",
    },
  },
}));

const RescheduleConfirmationDialogue = React.memo(function RescheduleConfirmationDialogue({
  open,
  handleClose,
  walkIn,
  updateLeadStatus,
  statusToBeUpdated = null,
}) {    
  const classes = useStyles();
  const [user, setUser] = useState(null);
//   const docs_collected = lead?.activities?.[0]?.docsCollected === true;
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
//   const tasksForStatuses = ["Call Back", "Follow Up"];
  const [taskDate, setTaskDate] = useState("");
  const [hour, setHour] = useState(12);
  const [minute, setMinute] = useState("00");
  const [period, setPeriod] = useState("AM");
  const statusOptions = [
    { value: "Reschedule Walk In", label: "Reschedule Walk In" },
  ];

  const [description, setDescription] = useState("");
  const [helperMessage, setHelperMessage] = useState(null);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(
    !statusToBeUpdated
  );
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [apiResponseType, setApiResponseType] = useState("inProgress");
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [
    shouldActivityDialogueCloseOnClickOfOutside,
    setShouldActivityDialogueCloseOnClickOfOutside,
  ] = useState(true);

  useEffect(() => {
    setUser(getUserDetails());
    
    // Log the payload and walk_in_status to verify
    // console.log('Payload:', payload);
    // console.log('Walk-in Status:', payload?.walk_in_status);

    // Update selectedStatus if payload.walk_in_status is available
    if (statusToBeUpdated) {
      setSelectedStatus(statusToBeUpdated);
    } else {
      setSelectedStatus("Select Status");  // fallback to default if no status is provided
    }
  }, []);

  function handleStatusChange(e) {
    const selectedValue = e.target.value;
    // Check if "Select Status" is selected
    if (selectedValue === "Select Status") {
      setDisableCancelButton(true);
      setDisableSubmitButton(true);
    } else {
      setDisableCancelButton(false);
      setDisableSubmitButton(false);
    }

    setSelectedStatus(selectedValue);
  }

  async function handleSubmit(actionFrom) {
    setDisableCloseButton(true);
    setApiResponseType("inProgress");
    setShouldActivityDialogueCloseOnClickOfOutside(false);
    setDisableSubmitButton(true);
    setDisableCancelButton(true);
    if (actionFrom === "retry") {
      setHelperMessage("Retrying...");
    } else {
      setHelperMessage("Please wait walk in is rescheduling...");
    }

    let payload = {};
    payload.walk_in_id = walkIn.walk_in_id
    let followUpDate = formatDateTime(taskDate, hour, minute, period);
    payload.rescheduled_date_time = followUpDate;
    payload.note = description;

    console.log("payload for add task api = ", payload);
    try {
      const response = await rescheduleWalkIn(payload);
      setHelperMessage(response.data.message);
      setDisableCancelButton(false);
      setDisableCloseButton(false);
      setApiResponseType("success");
      setShowCloseButton(true);
      updateLeadStatus(walkIn.walk_in_id, "Rescheduled");
      setShouldActivityDialogueCloseOnClickOfOutside(true);
      resetStates();
    } catch (error) {
      console.log(error);
      setHelperMessage("Failed to schedule walk-in !");
      setDisableCloseButton(false);
      setShowCloseButton(true);
      setApiResponseType("error");
      setDisableCancelButton(true);
      setShouldActivityDialogueCloseOnClickOfOutside(true);
    }
  }

  function resetStates() {
    setSelectedStatus("Select Status");
    setDisableCancelButton(true);
    setDisableSubmitButton(true);
    setDescription("");
    setTaskDate("");
    setHour(12);
    setMinute("00");
    setPeriod("AM");
  }

  return (
    <>
      <Dialog
        onClose={shouldActivityDialogueCloseOnClickOfOutside && handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitle}
        >
          <span>
            Reschedule Walk In For <strong>{walkIn.lead_name}</strong>
          </span>
          <IconButton
            aria-label="close"
            onClick={shouldActivityDialogueCloseOnClickOfOutside && handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {/* content container */}
          <Grid2
            style={{
              height: "max-content",
              width: "50vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxHeight: "75vh",
            }}
          >
            {helperMessage ? (
              <>
                {/* helper message UI code */}
                <Grid2
                  style={{
                    minHeight: "50vh",
                    minWidth: "50vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {apiResponseType == "success" ? (
                    <TaskAltIcon style={{ color: "green" }} fontSize="large" />
                  ) : apiResponseType == "error" ? (
                    <ErrorOutlineIcon
                      style={{ color: "red" }}
                      fontSize="large"
                    />
                  ) : (
                    <CircularProgress style={{ color: "rgb(127, 12, 134)" }} />
                  )}
                  {helperMessage}
                </Grid2>
              </>
            ) : (
              <>
                {/* actual content UI code */}
                {/* status section */}
                <Grid2
                  style={{
                    display: "flex",
                    width: "auto",
                    padding: "0rem 0.2rem",
                    margin: "0.6rem 0rem",
                  }}
                >
                  <CommonSelect
                    label="Status"
                    value={selectedStatus || "Select Status"}
                    onChange={(e) => handleStatusChange(e)}
                    options={statusOptions}
                    helperText="Please update the status"
                    displayEmpty
                    fullWidth
                    id="status-select"
                  />
                </Grid2>

                {/* add task section */}
                <Grid2
                  style={{
                    display: "flex",
                    width: "auto",
                    padding: "0rem 0.2rem",
                    margin: "0.6rem 0rem",
                    justifyContent: "flex-start",
                  }}
                >
                  {/* Add Task Form */}
                  <Row className="align-items-center">
                    {/* Task Date */}
                    <Col xs="auto">
                      <Form.Group controlId="taskDate">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={taskDate}
                          onChange={(e) => setTaskDate(e.target.value)}
                          min={
                            new Date()
                              .toISOString()
                              .replace("T", " ")
                              .slice(0, -5) + " UTC"
                          }
                          style={{
                            maxWidth: "10rem",
                            margin: "0rem 0.2rem",
                            marginLeft: "0rem",
                          }}
                        />
                      </Form.Group>
                    </Col>

                    {/* Hour Dropdown */}
                    <Col xs="auto">
                      <Form.Group controlId="hour">
                        <Form.Label>Hour</Form.Label>
                        <Form.Control
                          as="select"
                          value={hour}
                          onChange={(e) => setHour(e.target.value)}
                          style={{
                            minWidth: "6rem",
                            margin: "0rem 0.2rem",
                          }}
                        >
                          <option value="">Select Hour</option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    {/* Minute Dropdown */}
                    <Col xs="auto">
                      <Form.Group controlId="minute">
                        <Form.Label>Minute</Form.Label>
                        <Form.Control
                          as="select"
                          value={minute}
                          onChange={(e) => setMinute(e.target.value)}
                          style={{
                            minWidth: "6rem",
                            margin: "0rem 0.2rem",
                          }}
                        >
                          <option value="">Select Minute</option>
                          {Array.from({ length: 60 }, (_, i) => (
                            <option key={i} value={i < 10 ? `0${i}` : `${i}`}>
                              {i < 10 ? `0${i}` : `${i}`}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    {/* AM/PM Dropdown */}
                    <Col xs="auto">
                      <Form.Group controlId="period">
                        <Form.Label>AM/PM</Form.Label>
                        <Form.Control
                          as="select"
                          value={period}
                          onChange={(e) => setPeriod(e.target.value)}
                          style={{
                            minWidth: "6rem",
                            margin: "0rem 0.2rem",
                          }}
                        >
                          <option value="">Select Period</option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Grid2>

                {/* note section */}
                <Row
                  className="align-items-center"
                  style={{ margin: "0.6rem 0rem" }}
                >
                  <Col xs={12} style={{ padding: "0rem 0.2rem" }}>
                    <Form.Group controlId="note">
                      <Form.Label>Note</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Add Note if any"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Grid2>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {showCloseButton ? (
            <>
              <Button
                autoFocus
                onClick={
                  shouldActivityDialogueCloseOnClickOfOutside && handleClose
                }
                className={classes.secondaryButton}
                disabled={disableCloseButton}
              >
                Close
              </Button>
              {apiResponseType === "error" && (
                <Button
                  autoFocus
                  className={classes.primaryButton}
                  onClick={() => handleSubmit("retry")}
                >
                  Retry
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                autoFocus
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
                className={classes.secondaryButton}
                disabled={disableCancelButton}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                onClick={() => handleSubmit("submit")}
                className={classes.primaryButton}
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
});

export default RescheduleConfirmationDialogue;
