import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { convertDateFormat } from "../../utilities/helper-functions";
import { makeStyles } from "@mui/styles";

dayjs.extend(customParseFormat);
const useStyles = makeStyles((theme)=>({
  customTextField: {
    marginBottom:'1rem'
  },
}))

const DateTimeRangeFilter = ({ onDateRangeChange, name, filters }) => {
  const classes = useStyles()
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);
  const inputRef = useRef(null);

  const DATE_FORMAT = "DD-MM-YYYY hh:mm A";

  useEffect(() => {
    // Close the picker when the Escape key is pressed
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPicker(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Close picker when clicking outside
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       pickerRef.current &&
  //       !pickerRef.current.contains(event.target) &&
  //       inputRef.current &&
  //       !inputRef.current.contains(event.target)
  //     ) {
  //       setShowPicker(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // Reset the picker values if filters are cleared
  useEffect(() => {
    if (!filters[name]) {
      setStartDateTime(null);
      setEndDateTime(null);
    }
  }, [filters, name]);

  const handleInputClick = () => setShowPicker(!showPicker);

  const handleDateChange = (type, newValue) => {
    if (newValue) {
      const formattedDate = newValue.format(DATE_FORMAT);
      if (type === "start") {
        setStartDateTime(formattedDate);
      } else {
        setEndDateTime(formattedDate);
      }
    }
  };

  const handleManualChange = (type, value) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2} (AM|PM)$/;
    if (dateRegex.test(value)) {
      if (type === "start") {
        setStartDateTime(value);
      } else {
        setEndDateTime(value);
      }
    }
  };

  const handleApply = () => {
    if(startDateTime && endDateTime){
      console.log('start date time = ', startDateTime, ' end date time = ', endDateTime);
      
      onDateRangeChange(convertDateFormat(startDateTime), convertDateFormat(endDateTime), name, false);
    }else{
      console.log('start date time = ', startDateTime);
      onDateRangeChange(convertDateFormat(startDateTime),"", name, false)
    }
    setShowPicker(false)
  };

  const handleClear = () => {
    setStartDateTime(null);
    setEndDateTime(null);
    onDateRangeChange("", "", name, true);
  };

  const handleClose = () => {
    setStartDateTime(null);
    setEndDateTime(null);
    setShowPicker(false);
  }
  return (
    <FormGroup>
      <InputGroup>
        <FormControl
          ref={inputRef}
          type="text"
          placeholder="Select Date & Time Range"
          value={
            startDateTime && endDateTime
              ? `${startDateTime} to ${endDateTime}`
              : startDateTime
              ? startDateTime
              : ""
          }
          readOnly
          onClick={handleInputClick}
        />
      </InputGroup>

      {showPicker && (
        <div
          ref={pickerRef}
          className="border p-3 mt-2"
          style={{
            position: "absolute",
            top: "3.8rem",
            left: "0.5rem",
            zIndex: "1050",
            width: "100%",
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.4)",
            backgroundColor: "white",
            minWidth:'18rem'
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event propagation
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Start Date & Time"
              value={startDateTime ? dayjs(startDateTime, DATE_FORMAT) : null}
              onChange={(newValue) => handleDateChange("start", newValue)}
              slotProps={{
                textField: {
                  onBlur: (e) => handleManualChange("start", e.target.value),
                },
              }}
              sx={{
                marginBottom:'1rem'
              }}
              format={DATE_FORMAT}
            />
            <DateTimePicker
              label="End Date & Time"
              value={endDateTime ? dayjs(endDateTime, DATE_FORMAT) : null}
              onChange={(newValue) => handleDateChange("end", newValue)}
              slotProps={{
                textField: {
                  onBlur: (e) => handleManualChange("end", e.target.value),
                },
              }}
              format={DATE_FORMAT}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "0.5rem",
              }}
            >
              <button
                className="btn btn-primary mt-2"
                onClick={handleApply}
                disabled={!startDateTime}
              >
                Apply
              </button>
              <button className="btn btn-secondary mt-2 ml-10" onClick={handleClear}>
                Clear
              </button>
              <button className="btn btn-secondary mt-2 ml-10" onClick={handleClose} style={{backgroundColor:'red'}}>
                Close
              </button>
            </div>
          </LocalizationProvider>
        </div>
      )}
    </FormGroup>
  );
};

export default DateTimeRangeFilter;
