import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { addOrCreateUser } from "../../services/admin/users-services";

const SignupPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    Role: { id: "", role_name: "" },
    department: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    console.log("payload = ", formData);
  }, [formData]);
  const roleMapping = {
    ROLE_ADMIN: 1,
    ROLE_MANAGER: 2,
    ROLE_EMPLOYEE: 3,
  };

  // Handle change in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "role_name") {
      // Update role_name and role_id
      setFormData((prevData) => ({
        ...prevData,
        Role: {
          role_name: value,
          id: roleMapping[value] || "",
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSignup = async () => {
    try {
      const payload = { ...formData, role_name: formData.Role.role_name };
      const response = await addOrCreateUser(payload);
      if (response.data) {
        window.location.href = "/login";
        // navigate('/login');
      } else {
        alert("Signup failed: " + response.data.message || "Unknown error");
      }
    } catch (error) {
      alert("Signup failed: " + error.response.data.message);
    }
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="Enter your email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="mobile">Mobile</label>
        <input
          type="text"
          className="form-control"
          id="mobile"
          name="mobile"
          placeholder="Enter your mobile number"
          value={formData.mobile}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          placeholder="Enter your password"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="role_name">Role</label>
        <select
          className="form-control"
          id="role_name"
          name="role_name"
          value={formData.Role.role_name}
          onChange={handleChange}
        >
          <option value="">Select Role</option>
          <option value="ROLE_ADMIN">Admin</option>
          <option value="ROLE_MANAGER">Manager</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="department">Department</label>
        <input
          type="text"
          className="form-control"
          id="department"
          name="department"
          placeholder="Enter your department"
          value={formData.department}
          onChange={handleChange}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary btn-sm mt-3"
        onClick={handleSignup}
      >
        Signup
      </button>
    </>
  );
};

export default SignupPage;
