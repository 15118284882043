import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Form } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { getUsersByName } from "../services/user-services";
import {
  getChartsData,
  getUserNameAndId,
} from "../services/dashboard-services";

const AnalyticsDashboard = () => {
  const [dateFilter, setDateFilter] = useState(null);
  const [metrics, setMetrics] = useState();
  // {
  //   "calls_done": [
  //     { "created_by": 1, "count": 35 },
  //     { "created_by": 2, "count": 42 },
  //     { "created_by": 3, "count": 50 },
  //     { "created_by": 4, "count": 48 },
  //     { "created_by": 5, "count": 45 },
  //     { "created_by": 6, "count": 25 },
  //     { "created_by": 7, "count": 3 },
  //     { "created_by": 8, "count": 39 },
  //     { "created_by": 9, "count": 28 },
  //     { "created_by": 10, "count": 50 },
  //     { "created_by": 11, "count": 36 },
  //     { "created_by": 12, "count": 44 },
  //     { "created_by": 13, "count": 30 },
  //     { "created_by": 14, "count": 37 },
  //     { "created_by": 15, "count": 40 },
  //     { "created_by": 16, "count": 32 },
  //     { "created_by": 17, "count": 46 },
  //     { "created_by": 18, "count": 35 },
  //     { "created_by": 19, "count": 38 },
  //     { "created_by": 20, "count": 42 },
  //     { "created_by": 21, "count": 48 },
  //     { "created_by": 22, "count": 41 },
  //     { "created_by": 23, "count": 33 },
  //     { "created_by": 24, "count": 29 },
  //     { "created_by": 25, "count": 43 },
  //     { "created_by": 26, "count": 50 },
  //     { "created_by": 27, "count": 34 },
  //     { "created_by": 28, "count": 29 },
  //     { "created_by": 29, "count": 37 },
  //     { "created_by": 30, "count": 48 },
  //     { "created_by": 31, "count": 46 },
  //     { "created_by": 32, "count": 45 },
  //     { "created_by": 33, "count": 31 },
  //     { "created_by": 34, "count": 40 },
  //     { "created_by": 35, "count": 39 },
  //     { "created_by": 36, "count": 32 },
  //     { "created_by": 37, "count": 41 },
  //     { "created_by": 38, "count": 33 },
  //     { "created_by": 39, "count": 50 },
  //     { "created_by": 40, "count": 36 },
  //     { "created_by": 41, "count": 47 },
  //     { "created_by": 42, "count": 43 },
  //     { "created_by": 43, "count": 44 },
  //     { "created_by": 44, "count": 38 },
  //     { "created_by": 45, "count": 30 },
  //     { "created_by": 46, "count": 36 },
  //     { "created_by": 47, "count": 42 },
  //     { "created_by": 48, "count": 50 },
  //     { "created_by": 49, "count": 41 },
  //     { "created_by": 50, "count": 38 }
  //   ],
  //   "connected_calls": [
  //     { "created_by": 1, "count": 27 },
  //     { "created_by": 2, "count": 20 },
  //     { "created_by": 3, "count": 29 },
  //     { "created_by": 4, "count": 25 },
  //     { "created_by": 5, "count": 24 },
  //     { "created_by": 6, "count": 16 },
  //     { "created_by": 7, "count": 2 },
  //     { "created_by": 8, "count": 18 },
  //     { "created_by": 9, "count": 12 },
  //     { "created_by": 10, "count": 35 },
  //     { "created_by": 11, "count": 21 },
  //     { "created_by": 12, "count": 19 },
  //     { "created_by": 13, "count": 16 },
  //     { "created_by": 14, "count": 20 },
  //     { "created_by": 15, "count": 22 },
  //     { "created_by": 16, "count": 15 },
  //     { "created_by": 17, "count": 23 },
  //     { "created_by": 18, "count": 18 },
  //     { "created_by": 19, "count": 14 },
  //     { "created_by": 20, "count": 21 },
  //     { "created_by": 21, "count": 19 },
  //     { "created_by": 22, "count": 17 },
  //     { "created_by": 23, "count": 13 },
  //     { "created_by": 24, "count": 12 },
  //     { "created_by": 25, "count": 23 },
  //     { "created_by": 26, "count": 29 },
  //     { "created_by": 27, "count": 17 },
  //     { "created_by": 28, "count": 15 },
  //     { "created_by": 29, "count": 19 },
  //     { "created_by": 30, "count": 22 },
  //     { "created_by": 31, "count": 25 },
  //     { "created_by": 32, "count": 23 },
  //     { "created_by": 33, "count": 20 },
  //     { "created_by": 34, "count": 19 },
  //     { "created_by": 35, "count": 26 },
  //     { "created_by": 36, "count": 22 },
  //     { "created_by": 37, "count": 20 },
  //     { "created_by": 38, "count": 21 },
  //     { "created_by": 39, "count": 24 },
  //     { "created_by": 40, "count": 26 },
  //     { "created_by": 41, "count": 20 },
  //     { "created_by": 42, "count": 29 },
  //     { "created_by": 43, "count": 28 },
  //     { "created_by": 44, "count": 17 },
  //     { "created_by": 45, "count": 16 },
  //     { "created_by": 46, "count": 19 },
  //     { "created_by": 47, "count": 25 },
  //     { "created_by": 48, "count": 22 },
  //     { "created_by": 49, "count": 19 },
  //     { "created_by": 50, "count": 18 }
  //   ],
  //   "interested_leads": [
  //     { "created_by": 6, "count": 1 },
  //     { "created_by": 15, "count": 3 },
  //     { "created_by": 19, "count": 2 },
  //     { "created_by": 21, "count": 1 },
  //     { "created_by": 24, "count": 2 },
  //     { "created_by": 28, "count": 3 },
  //     { "created_by": 33, "count": 4 },
  //     { "created_by": 35, "count": 1 },
  //     { "created_by": 37, "count": 2 },
  //     { "created_by": 40, "count": 1 },
  //     { "created_by": 42, "count": 3 },
  //     { "created_by": 45, "count": 2 },
  //     { "created_by": 49, "count": 1 }
  //   ],
  //   "walkins_scheduled": [
  //     { "created_by": 1, "count": 2 },
  //     { "created_by": 4, "count": 3 },
  //     { "created_by": 6, "count": 1 },
  //     { "created_by": 7, "count": 0 },
  //     { "created_by": 8, "count": 2 },
  //     { "created_by": 10, "count": 1 },
  //     { "created_by": 11, "count": 2 },
  //     { "created_by": 12, "count": 1 },
  //     { "created_by": 13, "count": 3 },
  //     { "created_by": 16, "count": 0 },
  //     { "created_by": 18, "count": 1 },
  //     { "created_by": 21, "count": 2 },
  //     { "created_by": 23, "count": 1 },
  //     { "created_by": 25, "count": 0 },
  //     { "created_by": 27, "count": 1 },
  //     { "created_by": 29, "count": 2 },
  //     { "created_by": 32, "count": 0 },
  //     { "created_by": 34, "count": 3 },
  //     { "created_by": 36, "count": 2 },
  //     { "created_by": 38, "count": 1 },
  //     { "created_by": 40, "count": 2 },
  //     { "created_by": 42, "count": 1 },
  //     { "created_by": 44, "count": 0 },
  //     { "created_by": 46, "count": 2 },
  //     { "created_by": 48, "count": 3 },
  //     { "created_by": 50, "count": 1 }
  //   ],
  //   "walkins_today": [
  //     { "created_by": 3, "count": 1 },
  //     { "created_by": 5, "count": 2 },
  //     { "created_by": 7, "count": 0 },
  //     { "created_by": 8, "count": 1 },
  //     { "created_by": 9, "count": 2 },
  //     { "created_by": 11, "count": 1 },
  //     { "created_by": 12, "count": 0 },
  //     { "created_by": 14, "count": 1 },
  //     { "created_by": 16, "count": 0 },
  //     { "created_by": 18, "count": 2 },
  //     { "created_by": 19, "count": 1 },
  //     { "created_by": 21, "count": 2 },
  //     { "created_by": 24, "count": 1 },
  //     { "created_by": 26, "count": 2 },
  //     { "created_by": 28, "count": 0 },
  //     { "created_by": 30, "count": 1 },
  //     { "created_by": 32, "count": 0 },
  //     { "created_by": 34, "count": 1 },
  //     { "created_by": 36, "count": 1 },
  //     { "created_by": 37, "count": 1 },
  //     { "created_by": 40, "count": 2 },
  //     { "created_by": 42, "count": 1 },
  //     { "created_by": 44, "count": 0 },
  //     { "created_by": 46, "count": 1 },
  //     { "created_by": 48, "count": 2 }
  //   ]
  // }

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format: yyyy-mm-dd
    setDateFilter(formattedDate);
  }, []);

  useEffect(() => {
    const fetchData = async (selectedDate) => {
      // setLoading(true);
      try {
        // Fetch charts data
        let params = { date: selectedDate };
        const chartsResponse = await getChartsData(params);
        const metricsData = chartsResponse.data.data;

        // Fetch user names
        const usersResponse = await getUserNameAndId();
        const usersData = usersResponse.data.data;

        // Map user IDs to names
        const nameMap = usersData.reduce((acc, { id, name }) => {
          acc[id] = name;
          return acc;
        }, {});

        // Replace `created_by` with `name` in metrics
        const updatedMetrics = Object.entries(metricsData).reduce(
          (acc, [key, values]) => ({
            ...acc,
            [key]: values.map((item) => ({
              ...item,
              created_by: nameMap[item.created_by] || "Unknown", // Replace ID with name or "Unknown"
            })),
          }),
          {}
        );
        console.log("updated metrics = ", updatedMetrics);

        // Set updated metrics and names in state
        setMetrics(updatedMetrics);
      } catch (err) {
        // setError("Error fetching data");
        console.error("Error fetching metrics and user names:", err);
      }
    };

    fetchData(dateFilter);
  }, [dateFilter]);

  // Generate the chart data
  const generateChartData = (data) => {
    console.log("Chart data for metric:", data); // Log the data for debugging
    return data.map((item) => ({
      created_by: `${item.created_by}`,
      count: item.count,
    }));
  };

  // Function to generate random colors for each bar
  const getRandomColor = () => {
    const colors = [
      "#4CAF50",
      "#FF9800",
      "#3F51B5",
      "#F44336",
      "#2196F3",
      "#9C27B0",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const getColumnSize = (data) => {
    return data.length > 40 ? 12 : 6; // If there are more than 40 records, use full width (md=12), otherwise half (md=6)
  };

  function dateFilterChangeHandler(e) {
    console.log("filter changes");

    setDateFilter(e.target.value);
  }

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          margin: "0.5rem 0",
        }}
      >
        <h2>Performance Analytics</h2>
        <div style={{ width: "25%" }}>
          <Form.Control
            type="date"
            placeholder="Filter by Follow Up Date"
            name="date"
            defaultValue=""
            value={dateFilter}
            onChange={(e) => dateFilterChangeHandler(e)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      {!metrics ? (
        <p>Loading charts...</p>
      ) : (
        <Row>
          {Object.keys(metrics).map((metric, index) => (
            <Col
              md={getColumnSize(metrics[metric])}
              key={index}
              style={{ marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  padding: "0.5rem",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Adding shadow for depth
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    marginBottom: "0.1rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {metric.replace("_", " ")}
                </h4>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart
                    data={generateChartData(metrics[metric]).sort(
                      (a, b) => b.count - a.count
                    )}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis
                      dataKey="created_by"
                      tick={{ fontSize: 10, angle: -30, textAnchor: "end" }}
                      interval={0} // Ensures all labels are displayed
                      padding={{ left: 10, right: 10 }}
                      tickFormatter={(name) =>
                        name.length > 10 ? `${name.slice(0, 10)}...` : name
                      }
                    />
                    <YAxis
                      tick={{ fontSize: 12 }}
                      allowDecimals={false}
                      domain={[0, "dataMax + 5"]} // Dynamically extend Y-axis range
                      padding={{ top: 10, bottom: 10 }}
                    />
                    <Tooltip />
                    <Bar
                      dataKey="count"
                      fill={getRandomColor()} // Assign random color to each bar
                      barSize={30}
                      radius={[5, 5, 0, 0]} // Rounded corners for bars
                      isAnimationActive={true} // Enable animations
                      animationDuration={1000} // Duration of the animation
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default AnalyticsDashboard;
