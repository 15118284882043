import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import * as XLSX from "xlsx";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import axios from "./../../components/axios";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../components/common/CommonBackdrop";
import { uploadLeadsInBulk } from "../../services/admin/leads-services";

export default function Leads() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState("");
  const [todaysLeads, setTodaysLeads] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  const [progress, setProgress] = useState(0);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setError("");
  };

  async function handleFileUpload(e) {
      setResponseType("");
      setBackdropMessage("Uploading leads to the database...");
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
  
      const fileInput = e.target;
      const file = fileInput.files[0];
      const reader = new FileReader();
  
      reader.onload = async (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet) || [];
  
          const normalizeField = (field, possibleNames) =>
            Object.keys(field).find((key) =>
              possibleNames.some((name) =>
                key.trim().toLowerCase().includes(name.toLowerCase())
              )
            );
  
          const importedLeads = jsonData.map((item) => ({
            name:
              item[
                normalizeField(item, [
                  "name",
                  "lead name",
                  "customer name",
                  "full name",
                  "Full Name",
                  "full_name",
                ])
              ] || null,
            email:
              item[
                normalizeField(item, [
                  "email",
                  "email address",
                  "e-mail",
                  "Email",
                ])
              ] || null,
            phone:
              item[
                normalizeField(item, [
                  "mobile",
                  "mobile number",
                  "phone",
                  "phone number",
                  "mob no",
                  "mob. no.",
                  "phone_number",
                  "mobile_number",
                  "Mobile",
                ])
              ] || null,
            lead_source:
              item[
                normalizeField(item, [
                  "source",
                  "lead source",
                  "lead-source",
                  "LEAD SOURCE",
                  "Source",
                ])
              ] || null,
          }));
  
          const batchSize = 200;
          for (let i = 0; i < importedLeads.length; i += batchSize) {
            const batch = importedLeads.slice(i, i + batchSize);
            try {
              const response = await uploadLeadsInBulk(batch);
              console.log("Leads imported successfully:", response.data);
  
              // Calculate progress percentage
              const newProgress = Math.round(
                ((i + batchSize) / importedLeads.length) * 100
              );
              setProgress(newProgress);
  
              // Update backdrop message with progress percentage
              setBackdropMessage(`Uploading leads... ${newProgress}%`);
            } catch (err) {
              console.error("Error importing leads batch:", err);
              const newProgress = Math.round(
                ((i + batchSize) / importedLeads.length) * 100
              );
              setBackdropMessage(
                `Error importing some batches. Continuing... ${newProgress}%`
              );
            }
          }
  
          setProgress(100);
          setResponseType("SUCCESS");
          setBackdropMessage("Leads uploaded successfully!");
          setShouldBackdropCloseOnClickOfOutside(true);
        } catch (err) {
          console.error("Error processing the file:", err);
          setResponseType("FAILED");
          setBackdropMessage(err.message);
          setShouldBackdropCloseOnClickOfOutside(true);
        } finally {
          fileInput.value = "";
        }
      };
  
      reader.readAsArrayBuffer(file);
    }
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <AdminSidebar />
      <div className="global-container">
        <div className="container">
          <p>&nbsp;</p>
          <div className="page-header">
            <h1>Manage Leads</h1>
          </div>
          <div className="mb-3">
            <label className="btn btn-primary btn-md">
              Add Leads
              <input
                type="file"
                accept=".xlsx, .xls"
                style={{ display: "none" }}
                onChange={(e)=>handleFileUpload(e)}
                disabled
              />
            </label>
            <Button
              className="btn btn-primary btn-md ms-2"
              onClick={() => handleNavigate("/lead-list")}
            >
              View Leads
            </Button>
            <Button
              className="btn btn-primary btn-md ms-2"
              onClick={() => handleNavigate("/manage-leads")}
              disabled
            >
              Manage Leads
            </Button>
          </div>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              <h2>Please upload Leads in the form of an Excel sheet...</h2>
              <input
                type="file"
                className="form-control"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </Modal.Body>
            <div className="d-flex justify-content-center">
              <Button className="btn btn-primary" onClick={handleClose}>
                Close
              </Button>
              <Button className="btn btn-primary" onClick={handleClose}>
                Save Changes
              </Button>
            </div>
          </Modal>

          <p>&nbsp;</p>
        </div>
      </div>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
    </>
  );
}
