import React, { useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import CommonBackdrop from "../components/common/CommonBackdrop";
import {
  deleteInvalidLeads,
  deleteInvalidLeadsByLeadIds,
  getAllInvalidLeads,
  getDistinctInvalidLeadReasons,
} from "../services/invalid-lead-services";
import moment from "moment";
import { TablePagination } from "@mui/material";

const InvalidLeadsPage = React.memo(function InvalidLeadsPage() {
  const [reasons, setReasons] = useState([])
  const [areAllLeadsSelected, setAreAllLeadsSelected] = useState(false);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const calculateRowsPerPage = () => Math.floor(window.innerHeight / 40);
  const [filters, setFilters] = useState({})
  const [leads, setLeads] = useState([]);
  const [reload, setReload] = useState(false);
  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
    setReload(!reload);
  }

  // pagination states
  const [pagination, setPagination] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(calculateRowsPerPage());

  useEffect(() => {
    fetchInvalidLeads({ ...filters, pageSize: rowsPerPage });
    fetchUniqueInvalidLeadsReasons()
  }, [reload]);

  useEffect(() => {
    console.log("selected lead ids = ", selectedLeadIds);
  }, [selectedLeadIds]);

  async function fetchInvalidLeads(params) {
    try {
      setResponseType("");
      setBackdropMessage("Fetching Invalid Leads...");
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
      const response = await getAllInvalidLeads(params);
      setLeads([...response.data.data]);
      setPagination({
        ...response.data.pagination,
        page: response.data.pagination.page - 1,
      });
      setRowsPerPage(response.data.pagination.pageSize);
      setOpenBackdrop(false);
    } catch (error) {
      setResponseType("FAILED");
      setBackdropMessage("Failed to fetch Invalid Leads !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  async function exportToExcel() {
    try {
      setBackdropMessage("Exporting...");
      setResponseType("");
      setOpenBackdrop(true);
      const leadsToExport = leads.map(
        ({ name, email, phone, lead_source, reason }) => ({
          name,
          email,
          phone,
          source: lead_source,
          reason,
        })
      );
      const worksheet = utils.json_to_sheet(leadsToExport);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Invalid Leads");

      // Generate a filename with current date and descriptive title
      const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
      const filename = `Invalid_Leads_${currentDate}.xlsx`;

      writeFile(workbook, filename);
      setBackdropMessage("Leads exported successfully.");
      setResponseType("SUCCESS");
      setShouldBackdropCloseOnClickOfOutside(true);
      // try {
      //   const response = await deleteInvalidLeads();
      //   if (
      //     response.data.status === "success" &&
      //     response.data.statusCode === 200
      //   ) {
      //     setBackdropMessage("Leads exported successfully.");
      //     setResponseType("SUCCESS");
      //     setShouldBackdropCloseOnClickOfOutside(true);
      //     setLeads([]);
      //   } else {
      //     setBackdropMessage(response.data.message);
      //     setResponseType("FAILED");
      //     setShouldBackdropCloseOnClickOfOutside(true);
      //   }
      // } catch (error) {
      //   setBackdropMessage("Failed to Delete Invalid Leads !");
      //   setResponseType("FAILED");
      //   setShouldBackdropCloseOnClickOfOutside(true);
      // }
    } catch (error) {
      setResponseType("FAILED");
      setBackdropMessage("Failed to Export Invalid Leads !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  function handleChangePage(event, newPage) {
    fetchInvalidLeads({ page: newPage + 1, pageSize: rowsPerPage, ...filters });
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    fetchInvalidLeads({ page: 1, pageSize: newRowsPerPage, ...filters });
  };

  function selectAllLeads() {
    const updatedIds = leads.map((lead) => lead.id); // Map once
    if (areAllLeadsSelected) {
      setSelectedLeadIds([]); // Unselect all
      setAreAllLeadsSelected(false);
    } else {
      setSelectedLeadIds(updatedIds); // Select all
      setAreAllLeadsSelected(true);
    }
  }

  const handleCheckboxChange = (id) => {
    let updatedIds;
    if (selectedLeadIds.includes(id)) {
      updatedIds = selectedLeadIds.filter((leadId) => leadId !== id);
      setAreAllLeadsSelected(false);
    } else {
      updatedIds = [...selectedLeadIds, id];
      if (updatedIds.length === leads.length) {
        setAreAllLeadsSelected(true);
      }
    }
    setSelectedLeadIds(updatedIds);
  };

  async function deleteSelectedLeads(payload) {
    try {
      if (selectedLeadIds.length === 0) {
        setShouldBackdropCloseOnClickOfOutside(false);
        setBackdropMessage("Leads Not Selected !");
        setResponseType("FAILED");
        setOpenBackdrop(true);
        setShouldBackdropCloseOnClickOfOutside(true);
        return;
      }
      setShouldBackdropCloseOnClickOfOutside(false);
      setBackdropMessage("Deleting Invalid Leads...");
      setResponseType("");
      setOpenBackdrop(true);
      const response = await deleteInvalidLeadsByLeadIds(payload);
      setResponseType("SUCCESS");
      setBackdropMessage("Invalid Leads Deleted Successfully");
      setShouldBackdropCloseOnClickOfOutside(true);
      setSelectedLeadIds([]);
      setAreAllLeadsSelected(false);
    } catch (error) {
      console.log(error);
      setResponseType("FAILED");
      setBackdropMessage("Error Deleting Invalid Leads");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  async function fetchUniqueInvalidLeadsReasons(){
    try {
      const response = await getDistinctInvalidLeadReasons()
      setReasons(response.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  function handleFilterChange(e){
    const { name, value } = e.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    fetchInvalidLeads({...updatedFilters, page: 1, pageSize: rowsPerPage})
  }

  return (
    <>
      <AdminSidebar />
      <Container className="global-container">
        {leads.length > 0 ? (
          <>
            <div className="container">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="my-2"
              >
                <h4>Invalid Leads</h4>

                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Form.Control
                      as="select"
                      name="reason"
                      className="form-control"
                      value={filters.reason}
                      onChange={(e) => handleFilterChange(e)}
                      style={{ cursor: "pointer", minWidth:'50%' }}
                    >
                      <option value="">Filter by Reason</option>
                      {
                        reasons.map((reason)=>(
                          <option value={reason}>{reason}</option>
                        ))
                      }
                    </Form.Control>
                  </div>
                  <Button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={() =>
                      deleteSelectedLeads({ leadIds: selectedLeadIds })
                    }
                  >
                    Delete Leads
                  </Button>
                  <Button
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={() => exportToExcel()}
                  >
                    Export As Excel
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={areAllLeadsSelected}
                          onChange={() => selectAllLeads()}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                      <th>Lead Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Reason</th>
                      <th>Lead Source</th>
                      <th>Imported On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads.map((lead, index) => (
                      <tr key={lead.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedLeadIds.includes(lead.id)}
                            onChange={() => handleCheckboxChange(lead.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                        <td>{lead.id}</td>
                        <td>{lead.name}</td>
                        <td>{lead.email}</td>
                        <td>{lead.phone}</td>
                        <td>{lead.reason}</td>
                        <td>{lead.lead_source}</td>
                        <td>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {moment(lead.createdAt)
                              .utcOffset(330)
                              .format("DD MMM, YYYY")}
                          </span>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {moment(lead.createdAt)
                              .utcOffset(330)
                              .format("hh:mm:ss A")}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <TablePagination
                component="div"
                count={pagination.total || 0}
                page={pagination.page || 0}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[rowsPerPage, 5, 10, 25, 50, 100].sort(
                  (a, b) => a - b
                )}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="container"
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              No Invalid Leads
            </div>
          </>
        )}
      </Container>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
    </>
  );
});

export default InvalidLeadsPage;
