import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  activityStatuses,
  applicationStatusesForWalkInsPage,
  leadStatusesForWalkInsPage,
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  verificationStatuses,
} from "../constants/AppConstants";
import { debounce, exportLeadsHandler } from "../utilities/helper-functions";
import { getAllLeads } from "../services/admin/leads-services";
import { getUserByUserId, getUsers } from "../services/admin/users-services";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import EmployeeSidebar from "../components/Sidebar/EmployeeSidebar";
import ManagerSidebar from "../components/Sidebar/ManagerSidebar";
import { Form, Container, Table, Button } from "react-bootstrap";
import { TablePagination } from "@mui/material";
import AddActivityDialogue from "../components/employee/leads/AddActivityDialogue";
import CommonSnackbar from "../components/common/CommonSnackbar";
import CommonBackdrop from "../components/common/CommonBackdrop";
import ConfirmationDialogue from "../components/common/ConfirmationDialogue";

const WalkInsPage = React.memo(function WalkInsPage() {
  const location = useLocation()
  const navigate = useNavigate();
  const calculateRowsPerPage = () => Math.floor(window.innerHeight / 40);
  const [leads, setLeads] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { user, users } = useAuth();
  const [filters, setFilters] = useState({
    // verification_status: "Approved for Walk-In",
    verification_status: "Scheduled For Walk-In",
    lead_status: "",
  });
  const [isFiltersUpdated, setIsFiltersUpdated] = useState(false);
  const [pagination, setPagination] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(calculateRowsPerPage());
  const [
    payloadForUpdateApplicationStatus,
    setPayloadForUpdateApplicationStatus,
  ] = useState(null);
  const [showConfirmationDialogue, setShowConfirmationDialogue] =
    useState(false);
  const [apiNameForConfirmationDialogue, setApiNameForConfirmationDialogue] =
    useState(null);
  const [titleForConfirmationDialogue, setTitleForConfirmationDialogue] =
    useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showActivityDialogue, setShowActivityDialogue] = useState(false);
  const [isClickingDropdown, setIsClickingDropdown] = useState(false);
  // const [payload, setPayload] = useState({
  //   page: 1,
  //   pageSize: Math.floor(window.innerHeight / 40),
  // })

  function handleLeadStatusChange() {}
  let role = "";

  // backdrop states
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const fieldsToExport = ["id", "name", "email","phone","lead_status", "application_status","status", "createdAt", "updatedAt", "LeadAssignments"];
  const [
    shouldBackdropCloseOnClickOfOutside,
    setShouldBackdropCloseOnClickOfOutside,
  ] = useState(false);
  function backdropCloseHandler() {
    setOpenBackdrop(false);
    setShouldBackdropCloseOnClickOfOutside(false);
  }

  // snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  // states for application status
  const [showRejectionReasonInput, setShowRejectionReasonInput] =
    useState(false);

  useEffect(() => {
      let areFiltersExists = location.state && Object.keys(location?.state).includes('filters')
      let arePaginationExists = location.state && Object.keys(location?.state).includes('pagination')
      if (user?.user.id) {
        if(areFiltersExists || arePaginationExists){
          setFilters((prevFilters) => {
            const updatedFilters = {...prevFilters}
            if (user.user.role === ROLE_EMPLOYEE) {
              updatedFilters.assigned_to = user.user.id;
            } else {
              delete updatedFilters.assigned_to;
            }
            Object.assign(updatedFilters, location.state.filters)
            return updatedFilters
          })
          if (location.state.pagination) {
            setPagination((prevPagination) => ({
              ...prevPagination,
              ...location.state.pagination,
              page: location.state.pagination.page + 1, // Increment page
            }));
          }
          navigate(location.pathname, { replace: true });
        }else{
          setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            if (user.user.role === ROLE_EMPLOYEE) {
              updatedFilters.assigned_to = user.user.id;
            } else {
              delete updatedFilters.assigned_to;
            }
            return updatedFilters;
          });
        }
        // Set the flag to true after filters are updated
        setIsFiltersUpdated(true);
      }
    }, [user]); // This effect runs when `user` changes

  useEffect(() => {
    const fetchData = async () => {
      setBackdropMessage("Loading...");
      setOpenBackdrop(true); // Show the backdrop before starting API calls
      try {
        // Replace with your API calls in Promise.all
        await Promise.all([
          fetchAllLeads({ ...filters, pageSize: rowsPerPage }, false), // First API call
          fetchEmployees(),
        ]);

        setBackdropMessage(""); // Clear the backdrop message
        setOpenBackdrop(false); // Close the backdrop
      } catch (error) {
        console.error("Error fetching data:", error);
        setBackdropMessage(error.message); // Show error in backdrop message
        setResponseType("FAILED"); // Update the response type for errors
        setShouldBackdropCloseOnClickOfOutside(true); // Allow backdrop close on outside click
      }
    };

    if (isFiltersUpdated) {
      console.log("Verification 1 page mounted with filters", filters);
      fetchData(); // Fetch data when filters are updated
      setIsFiltersUpdated(false); // Reset the flag to avoid duplicate API calls
    }
  }, [filters, isFiltersUpdated]);

  function handleMouseDown(e) {
    // Prevent triggering the row double-click event when interacting with the dropdown
    setIsClickingDropdown(true);
    setTimeout(() => {
      setIsClickingDropdown(false);
    }, 2000); // Reset after 1000ms (1 second)
  }

  function updateLeadStatus(id, newStatus) {
    console.log("id = ", id, " new status = ", newStatus);

    try {
      setLeads(
        (prevLeads) =>
          prevLeads
            .map((lead) => {
              if (lead.id === id) {
                let updatedActivities;

                if (lead.Activities && lead.Activities.length > 0) {
                  // If activities exist, update the status of the first activity
                  updatedActivities = lead.Activities.map((activity, index) =>
                    index === 0
                      ? { ...activity, activity_status: newStatus }
                      : activity
                  );
                } else {
                  // If activities array is empty, create the first activity
                  updatedActivities = [
                    {
                      activity_status: newStatus,
                      description: "",
                      docs_collected: false,
                      follow_up: null,
                      lead_status: null,
                      status: null,
                    },
                  ];
                }

                // Return the updated lead with updated activities and lead_status
                return {
                  ...lead,
                  Activities: updatedActivities,
                  lead_status: newStatus,
                };
              }
              return lead;
            })
            .filter((lead) => lead.lead_status === "Verification 1") // Filter leads with the desired lead_status
      );
    } catch (error) {
      alert("Failed to update lead status: " + error.message);
    }
  }

  function handleLeadStatusChange(e, selectedLead) {
    setSelectedLead(selectedLead);
    console.log("role = ", user.user.role);
    e.stopPropagation();
    if (user.user.role !== ROLE_EMPLOYEE) {
      setSnackbarMessage("Manager has no access to update lead status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1000);
      return;
    } else {
      let payload = {};
      payload["lead_id"] = selectedLead.id;
      payload["lead_status"] = e.target.value;
      payload["role"] = user.user.role;
      console.log("payload for update lead status = ", payload);
      setPayloadForUpdateApplicationStatus(payload);
      setTitleForConfirmationDialogue("Update Lead Status");
      setApiNameForConfirmationDialogue("updateLeadStatus");
      setShowConfirmationDialogue(true);
    }
  }

  function updateLeadState(updatedLead) {
    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id === updatedLead.id ? { ...lead, ...updatedLead } : lead
      )
    );
  }

  function applicationStatusChangeHandler(e, lead_id, lead_status) {
    e.stopPropagation();
    if (user.user.role === ROLE_EMPLOYEE) {
      setSnackbarMessage("No access to update application status !");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setTimeout(() => setOpenSnackbar(false), 1500);
      return;
    } else {
      if (lead_status !== "12 documents collected") {
        setSnackbarMessage(
          "Application Status Cannot change due to Invalid Lead Status !"
        );
        setSnackbarType("error");
        setOpenSnackbar(true);
        setTimeout(() => setOpenSnackbar(false), 1500);
      } else {
        let payload = {};
        payload["lead_id"] = lead_id;
        payload["application_status"] = e.target.value;
        payload["lead_status"] = lead_status;
        payload["role"] = user.user.role;
        console.log("payload for update application status = ", payload);
        if (e.target.value === "Rejected") {
          setShowRejectionReasonInput(true);
        }
        setPayloadForUpdateApplicationStatus(payload);
        setTitleForConfirmationDialogue("Update Application Status");
        setApiNameForConfirmationDialogue("updateApplicationStatus");
        setShowConfirmationDialogue(true);
      }
    }
  }

  function handleFilterChange(e) {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    // setFilters((prevFilters)=>({...prevFilters, [e.target.name]:e.target.value}))
    console.log(filters);
    // Fetch leads with updated filters
    debouncedFetchLeads({
      ...updatedFilters,
      page: 1,
      pageSize: rowsPerPage,
    });
  }

  // Debounced fetch function
  const debouncedFetchLeads = useCallback(
    debounce((params) => fetchAllLeads(params), 700),
    []
  );

  function handleChangePage(event, newPage) {
    console.log("newPage = ", newPage);
    // setPage(newPage);
    fetchAllLeads({ page: newPage + 1, pageSize: rowsPerPage, ...filters });
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    // Fetch data for the first page with new rows per page
    fetchAllLeads({ page: 1, pageSize: newRowsPerPage, ...filters });
  };

  async function fetchAllLeads(params, withFilters) {
    try {
      setResponseType("");
      if (withFilters) {
        setBackdropMessage("Applying Filters...");
      } else {
        setBackdropMessage("Fetching Leads...");
      }
      setShouldBackdropCloseOnClickOfOutside(false);
      setOpenBackdrop(true);
      const response = await getAllLeads(params);
      console.log("response = ", response.data);
      setLeads(response.data.data);
      setPagination({
        ...response.data.pagination,
        page: response.data.pagination.page - 1,
      });
      setRowsPerPage(response.data.pagination.pageSize);
      setResponseType("SUCCESS");
      setBackdropMessage(response.data.message);
      setShouldBackdropCloseOnClickOfOutside(true);
      setOpenBackdrop(false);
    } catch (error) {
      console.log(error);
      setResponseType("FAILED");
      setBackdropMessage("Failed to fetch leads !");
      setShouldBackdropCloseOnClickOfOutside(true);
    }
  }

  async function fetchEmployees() {
    try {
      const response = await getUsers();
      setEmployees(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  function resetFiltersHandler(){
    let newFilters = {
      verification_status: "Scheduled For Walk-In",
      lead_status: "",
    }
    setFilters(newFilters)
    debouncedFetchLeads({
      ...newFilters,
      page: 1,
      pageSize: rowsPerPage,
    });
  }

  return (
    <>
      {user?.user?.role === ROLE_ADMIN ? (
        <AdminSidebar />
      ) : user?.user?.role === ROLE_MANAGER ? (
        <ManagerSidebar />
      ) : (
        <EmployeeSidebar />
      )}
      <Container className="global-container">
        <div className="container">
          <h1 className="text-left">{employees?.name}</h1>
          {/* filters section */}
          <div className="col-md-12 mb-3">
            <div className="filter-form row">
              {/* lead id filter */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="leadId"
                  className="form-control"
                  placeholder="Filter by Lead ID"
                  value={filters.leadId || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>
              {/* lead name filter */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Filter by Lead Name"
                  value={filters.name || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>
              {/* lead email filter */}
              <div className="col-md-3">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Filter by Lead Email"
                  value={filters.email || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>
              {/* lead phone filter */}
              <div className="col-md-3">
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  placeholder="Filter by Lead Phone"
                  value={filters.phone || ""}
                  onChange={(e) => handleFilterChange(e)}
                />
              </div>
              <p>&nbsp;</p>
              {/* lead owner i.e. filter by employee name */}
              {user.user.role !== ROLE_EMPLOYEE && (
                <div className="col-md-3">
                  <Form.Control
                    as="select"
                    name="assigned_to"
                    className="form-control"
                    placeholder="Filter by Employee Name"
                    value={filters.assigned_to || ""}
                    onChange={(e) => handleFilterChange(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <option value="">Filter by Employee Name</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              )}

              {/* lead status filter i.e. verification_status */}
              <div className="col-md-3">
                <Form.Control
                  as="select"
                  name="lead_status"
                  className="form-control"
                  placeholder="Filter by Lead Status"
                  value={filters.lead_status || ""}
                  onChange={(e) => handleFilterChange(e)}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Filter by Lead Status</option>
                  {leadStatusesForWalkInsPage.map((activityStatus) => (
                    <option value={activityStatus.name} key={activityStatus.id}>
                      {activityStatus.name}
                    </option>
                  ))}
                </Form.Control>
              </div>

              {/* application status filter */}
              <div className="col-md-3">
                <Form.Control
                  as="select"
                  name="application_status"
                  className="form-control"
                  placeholder="Filter by Lead Status"
                  value={filters.application_status || ""}
                  onChange={(e) => handleFilterChange(e)}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Filter by Application Status</option>
                  {applicationStatusesForWalkInsPage.map(
                    (verificationStatus) => (
                      <option
                        value={verificationStatus.name}
                        key={verificationStatus.id}
                      >
                        {verificationStatus.name}
                      </option>
                    )
                  )}
                </Form.Control>
              </div>
              <div className="col-md-3 mb-3 position-relative" style={{placeContent:'center', display:'flex', justifyContent:'flex-start'}}>
                {
                    user.user.role !== ROLE_EMPLOYEE &&
                    <Button style={{backgroundColor:'green', color:'white'}} onClick={()=>exportLeadsHandler(filters,[], leads, users, fieldsToExport)}>Export Leads</Button>
                }
                <Button style={{backgroundColor:'red', color:'white', marginLeft:user.user.role !== ROLE_EMPLOYEE && '1rem'}} onClick={()=>resetFiltersHandler()}>Reset Filters</Button>
              </div>
            </div>
          </div>
          <br />

          <div className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th>Lead Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  {user.user.role !== ROLE_EMPLOYEE && <th>Lead Owner</th>}
                  <th>Lead (Call) Status</th>
                  <th>Application Status</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr
                    onDoubleClick={() => {
                      if (!isClickingDropdown) {
                        navigate(`/lead-details-emp/${lead.id}`, {
                          state: {
                            showLeadStatusChangeSection : user.user.role === ROLE_EMPLOYEE,
                            showApplicationStatusChangeSection : user.user.role !== ROLE_EMPLOYEE,
                            are12DocumentsCollected:
                              lead.lead_status === "12 documents collected",
                            filters,
                            pagination,
                            fromPage:'walk-ins'
                          },
                        });
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{lead?.id}</td>
                    <td>{lead?.name}</td>
                    <td>{lead?.email}</td>
                    <td>{lead?.phone}</td>
                    {/* lead owner column */}
                    {user.user.role !== ROLE_EMPLOYEE && (
                      <td>{lead?.LeadAssignments[0].AssignedTo.name}</td>
                    )}
                    {/* lead (call) status column */}
                    <td>
                      <Form.Control
                        as="select"
                        name="leadStatus"
                        className="form-control"
                        value={lead.lead_status || ""}
                        onChange={(e) => handleLeadStatusChange(e, lead)}
                        onMouseDown={(e) => handleMouseDown(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        {leadStatusesForWalkInsPage.map((activityStatus) => (
                          <option
                            value={activityStatus.name}
                            key={activityStatus.id}
                          >
                            {activityStatus.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>

                    {/* Application Status column */}
                    <td>
                      <Form.Control
                        as="select"
                        name="application_status"
                        className="form-control"
                        value={lead?.application_status || ""}
                        // value={""}
                        onChange={(e) =>
                          applicationStatusChangeHandler(
                            e,
                            lead.id,
                            lead.lead_status
                          )
                        }
                        onMouseDown={(e) => handleMouseDown(e)}
                        style={{ cursor: "pointer" }}
                        disabled={user.user.role === ROLE_EMPLOYEE}
                      >
                        <option value="" disabled>
                          Select Appliication Status
                        </option>
                        {applicationStatusesForWalkInsPage.map(
                          (applicationStatus) => (
                            <option
                              value={applicationStatus.name}
                              key={applicationStatus.id}
                            >
                              {applicationStatus.name}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={pagination.total || 0}
            page={pagination.page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[rowsPerPage, 5, 10, 25, 50, 100].sort(
              (a, b) => a - b
            )}
          />
        </div>
      </Container>
      {openBackdrop && (
        <CommonBackdrop
          open={openBackdrop}
          onClose={() => backdropCloseHandler()}
          message={backdropMessage}
          type={responseType}
          shouldBackdropCloseOnClickOfOutside={
            shouldBackdropCloseOnClickOfOutside
          }
        />
      )}
      {openSnackbar && (
        <CommonSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          type={snackbarType}
        />
      )}
      {showConfirmationDialogue && (
        <ConfirmationDialogue
          open={showConfirmationDialogue}
          handleClose={() => setShowConfirmationDialogue(false)}
          payload={payloadForUpdateApplicationStatus}
          updateLeadState={(lead) => updateLeadState(lead)}
          title={titleForConfirmationDialogue}
          apiName={apiNameForConfirmationDialogue}
          showRejectionReasonInput={showRejectionReasonInput}
          setPayloadForUpdateApplicationStatus={
            setPayloadForUpdateApplicationStatus
          }
        />
      )}
      {showActivityDialogue && (
        <AddActivityDialogue
          open={showActivityDialogue}
          handleClose={() => setShowActivityDialogue(false)}
          lead={selectedLead}
          updateLeadStatus={(id, newStatus) => updateLeadStatus(id, newStatus)}
          statusToBeUpdated={selectedStatus}
        />
      )}
    </>
  );
});

export default WalkInsPage;
