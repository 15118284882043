import moment from "moment";
import React from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import DateTimeRangeFilter from "./common/DateTimeRangeFilter";
import {
  exportActivities,
  exportLeadsHandler,
} from "../utilities/helper-functions";

const RecentLeadActivities = React.memo(function RecentLeadActivities({
  activities,
  showMoreClickHandler,
  disableShowMoreButtonForLeadActivities,
  error,
  handleLeadActivitiesFilterChange,
  payloadForRecentActivity,
  setPayloadForRecentActivity,
  debouncedFetchLeadActivities,
}) {
  const { users } = useAuth();

  function handleDateRangeChange(
    startDateTime,
    endDateTime,
    filterName,
    shouldRemoveFilter
  ) {
    let updatedFilters = { ...payloadForRecentActivity };

    if (filterName) {
      if (shouldRemoveFilter) {
        console.log("Removing filter:", filterName);
        delete updatedFilters[filterName]; // Use `updatedFilters` instead of `payloadForRecentActivity`
      } else {
        updatedFilters = {
          ...payloadForRecentActivity,
          [filterName]: `${startDateTime},${endDateTime}`,
        };
      }
    }

    // Remove the `filterName` key completely if `shouldRemoveFilter` is true
    const finalPayload = shouldRemoveFilter
      ? { ...updatedFilters, page: 1 }
      : {
          ...updatedFilters,
          page: 1,
          [filterName]: `${startDateTime},${endDateTime}`,
        };

    setPayloadForRecentActivity(finalPayload);

    debouncedFetchLeadActivities(finalPayload);
  }

  return (
    <>
      <Row
        style={{
          marginTop: "1rem",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <Col md={12}>
          <Card.Body>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={2}>
                <h1>Recent Lead Activities</h1>
              </Col>
              <Col md={2}>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Filter by Phone"
                  value={payloadForRecentActivity.phone || ""}
                  onChange={(e) => handleLeadActivitiesFilterChange(e)}
                />
              </Col>
              <Col
                md={3}
                style={{ position: "relative", marginBottom: "1.5rem" }}
              >
                <label
                  htmlFor="createdAt"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Created On
                </label>
                <DateTimeRangeFilter
                  onDateRangeChange={handleDateRangeChange}
                  name="createdAt"
                  filters={payloadForRecentActivity}
                />
              </Col>

              <Col md={2}>
                <select
                  name="assigned_to"
                  className="form-control me-2"
                  onChange={(e) => handleLeadActivitiesFilterChange(e)}
                  defaultValue=""
                  value={payloadForRecentActivity.assigned_to || ""}
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Filter by Employee Name</option>
                  {users.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </Col>
              {error && <div className="error-message">{error}</div>}
              <div
                className="col-md-3 position-relative"
                style={{
                  placeContent: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={() => exportActivities(payloadForRecentActivity)}
                >
                  Export Records
                </Button>
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "1rem",
                  }}
                  onClick={() =>{
                    let payload = {
                        page: 1,
                        pageSize: Math.floor(window.innerHeight / 40),
                      }
                    if(Object.keys(payloadForRecentActivity).includes("createdAt")){
                        delete payloadForRecentActivity["createdAt"]
                    }
                    setPayloadForRecentActivity(payload)
                    debouncedFetchLeadActivities({...payloadForRecentActivity})
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Lead Id</th>
                  <th>Lead Name</th>
                  <th>Lead Status</th>
                  <th>Phone</th>
                  <th>Assigned To</th>
                  <th>Assigned Date</th>
                  <th>Activity Status</th>
                  <th>Created On</th>
                  <th>Description</th>
                  <th>Follow Up</th>
                  <th>Verification Status</th>
                  {/* <th>Follow Up</th> */}
                </tr>
              </thead>
              <tbody>
                {activities.map((activity, index) => (
                  <tr key={index}>
                    <td>{activity.Lead.id}</td>
                    <td>{activity.Lead.name}</td>
                    <td>{activity.Lead.lead_status}</td>
                    <td>{activity.Lead.phone || "Not Available"}</td>
                    <td>
                      {activity.Lead?.LeadAssignments[0]?.AssignedTo?.name}
                    </td>

                    <td>
                      {moment(activity.Lead.LeadAssignments[0]?.createdAt)
                        .utcOffset(330)
                        .format("DD MMM, YYYY")}
                    </td>
                    <td>{activity.activity_status}</td>
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(activity.createdAt)
                          .utcOffset(330)
                          .format("DD MMM, YYYY")}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {moment(activity.createdAt)
                          .utcOffset(330)
                          .format("hh:mm:ss A")}
                      </span>
                    </td>
                    <td>{activity.description || "Not Available"}</td>
                    <td>
                      {activity.follow_up
                        ? moment(activity.follow_up)
                            .utcOffset(330) // Set timezone offset for IST
                            .format("DD MMM, YYYY hh:mm A") // Use hh for 12-hour format and A for AM/PM
                        : "Not Available"}
                    </td>
                    <td>{activity.Lead.verification_status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              onClick={() => showMoreClickHandler("lead_activities")}
              disabled={disableShowMoreButtonForLeadActivities}
            >
              Show More...
            </Button>
          </Card.Body>
        </Col>
      </Row>
    </>
  );
});

export default RecentLeadActivities;
