import axios from "axios";

export async function getUsers(){
    return axios.get('/api/users/get-all-users')
}

export async function getUserByUserId(userId){
    return axios.get(`/api/users/${userId}`)
}

export async function updateUserByUserId(userId,payload){
    return axios.put(`/api/users/${userId}`,{...payload})
}

export async function deleteUserByUserId(userId){
    return axios.delete(`/api/users/${userId}`)
}

export async function addOrCreateUser(payload){
    return axios.post('/api/users',{...payload})
}

