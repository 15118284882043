import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormGroup, FormControl, FormLabel, InputGroup } from "react-bootstrap";

const DateTimeRangeFilter = ({ onDateRangeChange, name, filters }) => {
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  // Refs for detecting click outside and managing picker visibility
  const pickerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Close the picker when the Escape key is pressed
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPicker(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Close picker if clicked outside the input or picker
    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!filters[name]) {
      setStartDateTime("");
      setEndDateTime("");
    }
  }, [filters, name]);

  const handleStartDateTimeChange = (e) => {
    const value = e.target.value;
    const [date, time] = value.split("T");
  
    // Set default time only if time is missing
    if (!time) {
      setStartDateTime(`${date}T00:00`);
    } else {
      setStartDateTime(value);
    }
  };
  
  const handleEndDateTimeChange = (e) => {
    const value = e.target.value;
    const [date, time] = value.split("T");
  
    // Set default time only if time is missing
    if (!time) {
      setEndDateTime(`${date}T23:59`);
    } else {
      setEndDateTime(value);
    }
  };

  const handleInputClick = () => setShowPicker(!showPicker);

  const handleApply = () => {
    // If End Date Time is not provided, treat the Start Date Time as date-only
    if (!endDateTime) {
      onDateRangeChange(startDateTime.split("T")[0], "", name, false);
    } else {
      onDateRangeChange(startDateTime, endDateTime, name, false);
    }
    setShowPicker(false);
  };

  const handleClear = () => {
    setStartDateTime("");
    setEndDateTime("");
    onDateRangeChange("", "", name, true);
  };

  return (
    <FormGroup>
      <InputGroup>
        <FormControl
          ref={inputRef}
          type="text"
          placeholder="Select Date & Time Range"
          value={
            startDateTime && endDateTime
              ? `${startDateTime} to ${endDateTime}`
              : startDateTime
              ? `${startDateTime.split("T")[0]}`
              : ""
          }
          readOnly
          onClick={handleInputClick}
        />
      </InputGroup>

      {showPicker && (
        <div
          ref={pickerRef}
          className="border p-3 mt-2"
          style={{
            position: "absolute",
            top: "3.8rem",
            left: "0.5rem",
            zIndex: "1050",
            width: "100%",
            boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.4)",
            backgroundColor: "white",
          }}
        >
          <FormGroup>
            <FormLabel>Start Date & Time</FormLabel>
            <FormControl
              type="datetime-local"
              name={name}
              value={startDateTime}
              onChange={handleStartDateTimeChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>End Date & Time</FormLabel>
            <FormControl
              type="datetime-local"
              name={name}
              value={endDateTime}
              onChange={handleEndDateTimeChange}
            />
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-around", marginTop: "0.5rem" }}>
            <button
              className="btn btn-primary mt-2"
              onClick={handleApply}
              disabled={!startDateTime}
            >
              Apply
            </button>
            <button className="btn btn-secondary mt-2 ml-10" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      )}
    </FormGroup>
  );
};

export default DateTimeRangeFilter;
