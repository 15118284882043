import axios from 'axios';

// axios.defaults.baseURL= 'https://backend.crm.creditmitra.in';
// axios.defaults.baseURL = 'http://192.168.24.122:3000';
// axios.defaults.baseURL = 'http://localhost:3000'; // backend url for local or development
// axios.defaults.baseURL = 'https://api.crm.creditmitra.in'; // backend url for production
axios.defaults.baseURL = 'http://api.uat.crm.creditmitra.in'; // backend url for uat
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default axios;
